import type { TAuthorModel } from '@/domain/Book/contracts/TAuthorModel'
import type { TAuthorReferenceModel } from '@/domain/Book/contracts/TAuthorReferenceModel'

export const toAuthor = (authorData: {
  author: {
    id: string
    model: string
    value: {
      data: {
        name: string
        slug: string
        role: string
        description: string
        image: string
        references: {
          type: string
          name: string
          url: string
        }[]
      }
    }
  }
}): TAuthorModel | undefined => {
  if (
    !authorData ||
    !authorData?.author ||
    !authorData?.author?.id ||
    !authorData?.author?.model ||
    !authorData?.author?.value?.data?.name ||
    !authorData?.author?.value?.data?.slug
  ) {
    return undefined
  }

  return {
    id: authorData.author.id,
    model: authorData.author.model,
    name: authorData.author.value.data.name,
    slug: authorData.author.value.data.slug,
    role: authorData.author?.value?.data?.role,
    description: authorData.author?.value?.data?.description,
    image: authorData.author?.value?.data?.image,
    references: ((authorData.author.value.data?.references ?? []).length > 0
      ? authorData.author.value.data?.references
      : []) as TAuthorReferenceModel[],
  }
}
