import { useConfig } from '@/app/services/useConfig'
import { EEnvironment } from '@/app/contracts/EEnvironment'
import { isArray } from 'lodash-es'

export class Environment {
  static is(subject: EEnvironment | EEnvironment[]): boolean {
    if (isArray(subject)) {
      return subject.includes(Environment.current())
    }

    return Environment.current() === subject
  }

  static current(): EEnvironment {
    return (
      EEnvironment[useConfig().get().environment.toUpperCase()] ?? EEnvironment.UNDEFINED
    )
  }
}
