import { CreateGlobalStateReturn } from '@vueuse/shared'
import { useGlobalStore } from '@/app/support/useGlobalStore'

export const defineGlobalStore = <T>(key: string, store: CreateGlobalStateReturn<T>) => {
  const { addStore } = useGlobalStore()

  addStore(key, store)

  return store
}
