<template>
  <div
    class="flex justify-center gap-4 items-center text-base text-center text-base-400 whitespace-nowrap"
  >
    <div class="flex-1 shrink-0 self-stretch my-auto h-px bg-base-300"></div>
    <span class="self-stretch">{{ props.orText }}</span>
    <div class="flex-1 shrink-0 self-stretch my-auto h-px bg-base-300"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const componentName = 'SignInProviderDivider'
export default defineComponent({
  name: componentName,
})
</script>

<script lang="ts" setup>
const props = withDefaults(defineProps<{ orText?: string }>(), { orText: 'or' })
</script>

<style scoped></style>
