import { onMounted, readonly, ref, Ref, watch } from 'vue'

import {
  fetchOneEntry,
  getBuilderSearchParams,
  isEditing,
  isPreviewing,
  RegisteredComponent,
} from '@builder.io/sdk-vue'
import { EFetchOutcome } from '@/app/contracts/EFetchOutcome'
import type { GetContentOptions } from '@builder.io/sdk-vue/lib/node/functions/get-content/types'
import { mergeDeep } from '@tiptap/core'
import { useConfig } from '@/app/services/useConfig'
import type { ActiveHeadEntry, MergeHead, UseHeadInput } from '@unhead/vue'
import type { BuilderContent } from '@builder.io/sdk-vue/lib/browser/types/builder-content'
import { Environment } from '@/app/support/Environment'
import { EEnvironment } from '@/app/contracts/EEnvironment'

export type TUseOnePageEntryOptions = {
  useAutoFetch?: boolean
  supportedComponents?: RegisteredComponent[]
  customHead?: void | ActiveHeadEntry<UseHeadInput<MergeHead>>
  customHeadPatcher?: (content: BuilderContent) => UseHeadInput<MergeHead>
  useEnvironment?: EEnvironment
}

export const useOnePageEntryWithOptions = (
  model: string,
  options: TUseOnePageEntryOptions,
) => {
  return useOnePageEntry(
    model,
    options.supportedComponents ?? [],
    options?.customHead ?? undefined,
    options?.customHeadPatcher ?? undefined,
    options?.useAutoFetch ?? true,
    options?.useEnvironment ?? undefined,
  )
}

export const useOnePageEntry = (
  modelName: string,
  supportedComponents: RegisteredComponent[] = [],
  customHead?: void | ActiveHeadEntry<UseHeadInput<MergeHead>>,
  customHeadPatcher?: (content: BuilderContent) => UseHeadInput<MergeHead>,
  useAutoFetch = true,
  useEnvironment?: EEnvironment,
): {
  content: Readonly<Ref<BuilderContent | null | undefined>>
  config: Readonly<{
    modelName: string
    apiKey: string
    getComponents: () => RegisteredComponent[]
  }>
  fetchOutcome: Readonly<Ref<EFetchOutcome>>
  doFetch: (options: Partial<GetContentOptions>) => Promise<void>
} => {
  const _content = ref<BuilderContent | null | undefined>(undefined)
  const _fetchOutcome = ref<EFetchOutcome>(EFetchOutcome.UNDEFINED)
  const _config = {
    modelName: modelName,
    apiKey: useConfig().get().builderIO.publicApiKey,
    getComponents: () => supportedComponents,
  }

  const _doFetch = async (options: Partial<GetContentOptions> = {}) => {
    const resolvedOptions: GetContentOptions = mergeDeep(
      {
        model: _config.modelName as string,
        apiKey: _config.apiKey as string,
        options: getBuilderSearchParams(new URL(location.href).searchParams),
        userAttributes: {
          urlPath: window.location.pathname,
        },
        cacheSeconds: 1,
        cacheBust: true,
      },
      options,
    ) as GetContentOptions

    if (useEnvironment) {
      resolvedOptions['query'] = { 'data.environment.$eq': useEnvironment.toLowerCase() }
    }

    _content.value = await fetchOneEntry(resolvedOptions)
  }

  watch(_content, (newContent) => {
    if (newContent === undefined) {
      _fetchOutcome.value = EFetchOutcome.UNDEFINED
      return
    }

    _fetchOutcome.value =
      isEditing() || isPreviewing() || newContent !== null
        ? EFetchOutcome.SHOW_CONTENT
        : EFetchOutcome.SHOW_FALLBACK

    if (
      newContent &&
      _fetchOutcome.value === EFetchOutcome.SHOW_CONTENT &&
      customHead &&
      customHeadPatcher
    ) {
      customHead?.patch(customHeadPatcher(newContent))
    }
  })

  onMounted(async () => {
    if (useAutoFetch) {
      await _doFetch()
    }
  })

  return {
    content: readonly(_content) as Readonly<Ref<BuilderContent | null | undefined>>,
    config: readonly(_config),
    fetchOutcome: readonly(_fetchOutcome),
    doFetch: _doFetch,
  }
}
