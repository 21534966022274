<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.1959 4.10264L19.1954 4.10309L4.34543 18.7054C4.24833 18.8009 4.12013 18.85 3.989 18.85C3.85787 18.85 3.72967 18.8009 3.63257 18.7054L0.804568 15.9246C0.61281 15.736 0.612811 15.4357 0.804567 15.2472L15.6546 0.644873C15.851 0.451709 16.171 0.451709 16.3674 0.644873L19.1954 3.4257C19.3874 3.61451 19.3868 3.91541 19.1959 4.10264ZM3.63843 17.3328L3.989 17.6775L4.33957 17.3328L14.3186 7.52025L14.6811 7.16373L14.3186 6.80722L12.9046 5.41681L12.554 5.07209L12.2034 5.41681L2.22443 15.2294L1.86187 15.5859L2.22443 15.9424L3.63843 17.3328ZM15.0324 6.12885L15.383 6.47357L15.7336 6.12885L17.7756 4.12091L18.1381 3.7644L17.7756 3.40788L16.3616 2.01747L16.011 1.67275L15.6604 2.01747L13.6184 4.02541L13.2559 4.38192L13.6184 4.73844L15.0324 6.12885ZM14.5 16.5667H15H16C16.2848 16.5667 16.5 16.7903 16.5 17.05C16.5 17.3098 16.2848 17.5334 16 17.5334H15H14.5V18.0334V19.0167C14.5 19.2764 14.2848 19.5 14 19.5C13.7152 19.5 13.5 19.2764 13.5 19.0167V18.0334V17.5334H13H12C11.7152 17.5334 11.5 17.3098 11.5 17.05C11.5 16.7903 11.7152 16.5667 12 16.5667H13H13.5V16.0667V15.0834C13.5 14.8237 13.7152 14.6001 14 14.6001C14.2848 14.6001 14.5 14.8237 14.5 15.0834V16.0667V16.5667ZM2.5 8.20015V7.70015H2H1C0.71518 7.70015 0.5 7.47657 0.5 7.21683C0.5 6.9571 0.71518 6.73351 1 6.73351H2H2.5V6.23351V5.25019C2.5 4.99046 2.71518 4.76687 3 4.76687C3.28482 4.76687 3.5 4.99046 3.5 5.25019V6.23351V6.73351H4H5C5.28482 6.73351 5.5 6.9571 5.5 7.21683C5.5 7.47657 5.28482 7.70015 5 7.70015H4H3.5V8.20015V9.18347C3.5 9.44321 3.28482 9.66679 3 9.66679C2.71518 9.66679 2.5 9.44321 2.5 9.18347V8.20015ZM15.5 11.6501H16.5V12.6168H15.5V11.6501ZM9.5 1.80023V0.833591H10.5V1.80023H9.5ZM6.5 3.76687V2.80023H7.5V3.76687H6.5ZM3.5 0.833591H4.5V1.80023H3.5V0.833591ZM18.5 9.66679V8.70015H19.5V9.66679H18.5ZM18.5 14.5834V13.6168H19.5V14.5834H18.5Z"
      fill="currentColor"
      stroke="currentColor"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const componentName = 'WandMagicSparklesOutlineIcon'
export default defineComponent({
  name: componentName,
})
</script>

<script lang="ts" setup></script>

<style scoped></style>
