<template>
  <div :class="props.containerClasses">
    <svg
      v-if="props.type === 'lightning-bolt'"
      :class="props.elementClasses"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      stroke-width="2"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M13 10V3L4 14h7v7l9-11h-7z"
      />
    </svg>

    <svg
      v-if="props.type === 'document-duplicate'"
      :class="props.elementClasses"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      stroke-width="2"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"
      />
    </svg>

    <svg
      v-if="props.type === 'user-group'"
      :class="props.elementClasses"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      stroke-width="2"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
      />
    </svg>

    <svg
      v-if="props.type === 'question-mark-circle'"
      :class="props.elementClasses"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      stroke-width="2"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>

    <svg
      v-if="props.type === 'pencil-alt'"
      :class="props.elementClasses"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      stroke-width="2"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
      />
    </svg>

    <svg
      v-if="props.type === 'share'"
      :class="props.elementClasses"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      stroke-width="2"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"
      />
    </svg>

    <svg
      v-if="props.type === 'light-bulb'"
      :class="props.elementClasses"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      stroke-width="2"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
      />
    </svg>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const componentName = 'StepIcon'
export default defineComponent({
  name: componentName,
})
</script>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'

const props = withDefaults(
  defineProps<{
    type:
      | 'lightning-bolt'
      | 'document-duplicate'
      | 'user-group'
      | 'question-mark-circle'
      | 'pencil-alt'
      | 'share'
      | 'light-bulb'
    elementClasses?: string
    mergeElementClasses?: boolean
    containerClasses?: string
    mergeContainerClasses?: boolean
  }>(),
  {
    elementClasses: undefined,
    mergeElementClasses: true,
    containerClasses: undefined,
    mergeContainerClasses: true,
  },
)

const defaultContainerClasses = 'w-6 h-6'
const containerClasses = ref<string>()
const defaultElementClasses = 'w-6 h-6'
const elementClasses = ref<string>()

onMounted(() => {
  containerClasses.value = props.mergeContainerClasses
    ? `${defaultContainerClasses} ${props.containerClasses}`
    : props.containerClasses
  elementClasses.value = props.mergeElementClasses
    ? `${defaultElementClasses} ${props.elementClasses}`
    : props.elementClasses
})
</script>

<style scoped></style>
