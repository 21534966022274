import miljnApiBaseClientFactory from '@/app/services/miljnApiBaseClientFactory'
import { users } from '@/app/services/miljnApiEndpointsWeb2'
import { v4 as uuid } from 'uuid'
import { setPreferHeader } from '@/app/services/preferHeaderResolver'

export const getMyUser = async () => {
  const instance = miljnApiBaseClientFactory()
  const requestConfig = setPreferHeader({}, 'users')

  return await instance.get(users.me, requestConfig)
}

export const updateMyUserName = async (userName) => {
  const instance = miljnApiBaseClientFactory()

  const requestConfig = setPreferHeader({}, 'users')

  const requestBody = {
    data: {
      attributes: {
        name: userName,
      },
      id: uuid(),
      type: 'users',
    },
  }

  return await instance.put(users.me, requestBody, requestConfig)
}

export const updateMyEmail = async (email) => {
  const instance = miljnApiBaseClientFactory()

  const requestConfig = setPreferHeader({}, 'users')

  const requestBody = {
    data: {
      attributes: {
        email: email,
      },
      id: uuid(),
      type: 'users',
    },
  }

  return await instance.put(users.me, requestBody, requestConfig)
}

export const getUserById = async (userId) => {
  const instance = miljnApiBaseClientFactory()
  const requestConfig = setPreferHeader({}, 'users')

  return await instance.get(users.byUserId(userId), requestConfig)
}

export default {
  getMyUser,
  updateMyUserName,
  getUserById,
}
