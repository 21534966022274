<template>
  <div
    v-if="notifications.length > 0 || exposedNotification"
    class="absolute md:fixed inset-x-1 bottom-2 md:left-auto md:right-4 md:top-[72px] md:bottom-auto z-[100] max-h-[80vh] w-full md:max-w-[364px]"
  >
    <Transition
      enter-active-class="duration-300 ease-out"
      enter-from-class="transform opacity-0 translate-x-12"
      enter-to-class="opacity-100 translate-x-0"
      leave-active-class="duration-200 ease-in"
      leave-from-class="opacity-100 translate-x-0"
      leave-to-class="transform opacity-0 translate-x-12"
    >
      <div v-if="exposedNotification">
        <Notification :notification="exposedNotification" @closed="onNotificationClose" />
      </div>
    </Transition>
    <UndoNotification />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { storeToRefs } from 'pinia'
import { useNotificationsStore } from '@/domain/notifications/services/useNotificationsStore'
import Notification from './Notification.vue'
import UndoNotification from './UndoNotification.vue'

export const componentName = 'ExposedNotificationDialog'

export default defineComponent({
  name: componentName,
  components: { Notification, UndoNotification },
  setup() {
    const notificationStore = useNotificationsStore()
    const { exposedNotification, notifications } = storeToRefs(notificationStore)
    const { removeExposedNotification } = notificationStore

    const onNotificationClose = () => {
      removeExposedNotification()
    }

    return {
      notifications,
      exposedNotification,
      onNotificationClose,
    }
  },
})
</script>
