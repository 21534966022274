import type { RegisteredComponent } from '@builder.io/sdk-vue'
import component from '@/domain/Content/components/AuthorSection.vue'
import { toKeyValue } from '@/domain/Content/support/toKeyValue'
import { EAuthorReferenceType } from '@/domain/Book/contracts/EAuthorReferenceType'
import { CBookModel } from '@/domain/Book/contracts/CBookModel'

export const AuthorSectionComponentRegistration = (
  shouldWrap = true,
): RegisteredComponent => ({
  component: component,
  name: 'AuthorSection',
  friendlyName: 'AuthorSection',
  noWrap: !shouldWrap,
  inputs: [
    {
      friendlyName: 'Use page content',
      name: 'usePageContent',
      type: 'boolean',
      helperText: 'Derive content from enclosing page instead of manually adding.',
      defaultValue: false,
    },
    {
      friendlyName: 'Define page content model',
      name: 'pageContentModel',
      type: 'string',
      enum: ['page', CBookModel.NAME],
      defaultValue: CBookModel.NAME,
      showIf(options) {
        return options.get('usePageContent') === true
      },
    },
    {
      name: 'classes',
      type: 'object',
      advanced: true,
      subFields: [
        {
          name: 'container',
          type: 'text',
          defaultValue: 'mt-12 mb-6',
        },
        {
          name: 'contentContainer',
          type: 'text',
        },
        {
          name: 'titleContainer',
          type: 'text',
        },
        {
          name: 'title',
          type: 'text',
        },
        {
          name: 'subline',
          type: 'text',
        },
        {
          name: 'divider',
          type: 'text',
        },
        {
          name: 'authorsContainer',
          type: 'text',
        },
        {
          name: 'authorName',
          type: 'text',
        },
        {
          name: 'avatar',
          type: 'text',
        },
        {
          name: 'referenceIcon',
          type: 'text',
        },
        {
          name: 'role',
          type: 'text',
        },
        {
          name: 'description',
          type: 'text',
        },
      ],
    },
    {
      name: 'title',
      type: 'longText',
      defaultValue: 'About the Author',
    },
    {
      name: 'subline',
      type: 'longText',
      defaultValue:
        'Non-Affiliated<span class="w-1 h-1 rounded-full bg-base-content"></span>Miljn Curated',
    },
    {
      name: 'authors',
      type: 'list',
      showIf(options) {
        return options.get('usePageContent') !== true
      },
      subFields: [
        {
          name: 'name',
          type: 'text',
        },
        {
          name: 'slug',
          type: 'string',
        },
        {
          name: 'role',
          type: 'text',
        },
        {
          name: 'description',
          type: 'richText',
        },
        {
          name: 'image',
          friendlyName: 'Profile Image',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png'],
        },
        {
          name: 'references',
          type: 'list',
          copyOnAdd: false,
          subFields: [
            {
              name: 'type',
              type: 'string',
              enum: toKeyValue(EAuthorReferenceType).filter(
                (item) => item.label !== 'Email',
              ),
            },
            {
              name: 'name',
              type: 'text',
            },
            {
              name: 'url',
              type: 'text',
            },
          ],
        },
      ],
    },
  ],
})
