import { type RegisteredComponent } from '@builder.io/sdk-vue'
import component from '@/domain/Content/components/PricingTableSection.vue'

export const PricingTableSectionRegistration = (
  shouldWrap = true,
): RegisteredComponent => ({
  component: component,
  name: 'PricingTableSection',
  noWrap: !shouldWrap,
  canHaveChildren: true,
  childRequirements: {
    message: `Children of PricingTableSection must be PricingTableCard's`,
    component: 'PricingTableCard',
  },
  inputs: [
    {
      name: 'title',
      type: 'longText',
    },
    {
      name: 'sublines',
      type: 'list',
      defaultValue: [],
      subFields: [
        {
          name: 'text',
          type: 'longText',
        },
      ],
    },
    {
      name: 'containerClasses',
      type: 'string',
      defaultValue: 'py-3 lg:py-4',
    },
  ],
})
