import { type RegisteredComponent } from '@builder.io/sdk-vue'
import component from '@/domain/Content/components/TitleWithSubline.vue'

export const TitleWithSublineRegistration = (shouldWrap = true): RegisteredComponent => ({
  component: component,
  name: 'Title with subline',
  noWrap: !shouldWrap,
  inputs: [
    {
      name: 'title',
      type: 'string',
    },
    {
      name: 'subline',
      type: 'string',
    },
    {
      name: 'showSubline',
      type: 'boolean',
      defaultValue: false,
    },
  ],
})
