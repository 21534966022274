import { RouteRecordRaw } from 'vue-router'
import { EEnvironment } from '@/app/contracts/EEnvironment'

export const blockListRoutes: RouteRecordRaw[] = [
  {
    path: '/pblocklist',
    name: 'pblocklist',
    meta: {
      excludedEnvironments: [EEnvironment.PRODUCTION],
    },
    component: () => import('@/domain/blockList/components/PBlocks.vue'),
  } as RouteRecordRaw,
]
