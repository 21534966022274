import Ybug from 'ybug-vue'
import { useFeedbackWidget as useFeedbackWidgetComposable } from './composables/useFeedbackWidget'
import { yBugSettingsFactory } from '@/domain/FeedbackWidget/services/yBugSettingsFactory'
import FeedbackButtonComponent from '@/domain/FeedbackWidget/compontents/FeedbackButton.vue'

export const widget = Ybug
export const useFeedbackWidget = useFeedbackWidgetComposable
export const settingsFactory = yBugSettingsFactory
export const FeedbackButton = FeedbackButtonComponent

export default {
  widget: Ybug,
  useFeedbackWidget: useFeedbackWidgetComposable,
  initializeSettings: yBugSettingsFactory,
}
