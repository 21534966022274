export const CRouteNames = {
  home: 'page.home',
  contentPages: 'page.content', // @todo to be removed
  domainPages: 'page.domains',
  dashboard: 'app.dashboard',
  topic: 'app.topic',
  topicWithContext: 'app.topic.with-context',
  shareTopic: 'app.sharing',
  saas: 'app.saas',
  join: 'app.join',
  expert: 'app.expert',
  documents: {
    create: 'app.documents.create',
  },
}
