<template>
  <svg
    viewBox="0 0 1200 1200"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linejoin: round;
      stroke-miterlimit: 2;
    "
  >
    <g>
      <path
        d="M714.163,519.284L1160.89,0L1055.03,0L667.137,450.887L357.328,0L0,0L468.492,681.821L0,1226.37L105.866,1226.37L515.491,750.218L842.672,1226.37L1200,1226.37L714.137,519.284L714.163,519.284ZM569.165,687.828L521.697,619.934L144.011,79.694L306.615,79.694L611.412,515.685L658.88,583.579L1055.08,1150.3L892.476,1150.3L569.165,687.854L569.165,687.828Z"
        fill="currentColor"
        fill-rule="nonzero"
      />
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const componentName = 'XLogo'
export default defineComponent({
  name: componentName,
})
</script>

<script lang="ts" setup></script>

<style scoped></style>
