import { type RegisteredComponent } from '@builder.io/sdk-vue'
import component from '@/domain/Content/components/TeamSection.vue'

export const TeamSectionRegistration = (shouldWrap = true): RegisteredComponent => ({
  component: component,
  name: 'Team Section',
  noWrap: !shouldWrap,
  canHaveChildren: true,
  inputs: [
    {
      name: 'people',
      type: 'list',
      defaultValue: [],
      subFields: [
        {
          name: 'name',
          type: 'string',
          required: true,
        },
        {
          name: 'role',
          type: 'string',
        },
        {
          name: 'founderType',
          type: 'string',
        },
        {
          name: 'imageUrl',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png'],
          required: true,
        },
        {
          name: 'xUrl',
          type: 'string',
        },
        {
          name: 'linkedinUrl',
          type: 'string',
        },
      ],
    },
  ],
})
