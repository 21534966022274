import { ITheBlock } from '@/domain/blockList/contracts/ITheBlock'
import { TIdentifiedBlockPayloadSchemaGuardAction } from '@/domain/blockList/contracts/TIdentifiedBlockPayloadSchemaGuardAction'
import { EBlockType } from '@/domain/blockList/contracts/EBlockType'
import { TBlockPayloadSchemaGuardAction } from '@/domain/blockList/contracts/TBlockPayloadSchemaGuardAction'

const guardAction: TBlockPayloadSchemaGuardAction = (
  block: ITheBlock,
): Promise<ITheBlock> => {
  const validatedPayload: { value: string; level?: number } = {
    value: block.attributes.payload?.value ?? '',
    level: undefined,
  }

  switch (block.attributes.typeId) {
    case EBlockType.H1:
      validatedPayload.level = 1
      break
    case EBlockType.H2:
      validatedPayload.level = 2
      break
    case EBlockType.H3:
      validatedPayload.level = 3
      break
    default:
      validatedPayload.level = 1
      break
  }

  block.attributes.payload = validatedPayload

  return Promise.resolve(block)
}

export const headlineBlockPayloadSchemaGuardAction: TIdentifiedBlockPayloadSchemaGuardAction[] =
  [
    [EBlockType.H1, guardAction],
    [EBlockType.H2, guardAction],
    [EBlockType.H3, guardAction],
  ]
