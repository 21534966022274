import type { AxiosError } from 'axios'
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import type { NotificationProps } from '@/domain/notifications/services/useNotificationsStore'
import { createNotification } from '@/domain/notifications/services/notificationFactory'
import { captureWithUser } from '@/app/support/usePosthog'
import { EUserEvents } from '@/app/contracts/EUserEvents'
import { CRouteNames } from '@/app/contracts/CRouteNames'

export const handleCaseNotFound = (
  error: AxiosError,
  to: RouteLocationNormalized,
  next: NavigationGuardNext,
  exposeOneNotification: (
    notification: NotificationProps,
    exposureDuration?: number,
  ) => void,
  shareId: string,
): void => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { status } = error?.response ?? {}

  const notification = createNotification(
    `The case you're trying to access is not found`,
    'error',
  )
  exposeOneNotification(notification, 10)

  captureWithUser(EUserEvents.visited, {
    route: to.name,
    route_query: { ...to.query },
    route_params: { ...to.params },
    guide_share_link: to.fullPath,
    guide_share_link_id: shareId,
    guard: 'shareTopicNavigationGuard',
    error: `Case could not be found redirect to '${CRouteNames.home}'`,
    status: status,
  })

  next({
    name: CRouteNames.home,
    replace: true,
  })
}
