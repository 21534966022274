<template>
  <button
    v-show="isFeedbackButtonEnabled"
    class="text-base absolute right-4 -bottom-0.5 bg-state-warning rounded-t-md drop-shadow-lg border border-base-300 px-3 pt-0.5 pb-1 text-state-warning-content hover:bg-state-warning-focus hover:scale-[1.03] transition-transform duration-300 ease-in-out z-50"
    :class="[componentName]"
    @click.stop="activateFeedbackWidget(EFeedbackWidgetActivationSource.widgetButton)"
  >
    Feedback
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const componentName = 'FeedbackButton'
export default defineComponent({
  name: componentName,
})
</script>

<script lang="ts" setup>
import { useFeedbackWidget } from '@/domain/FeedbackWidget/composables/useFeedbackWidget'
import { EFeedbackWidgetActivationSource } from '@/domain/FeedbackWidget/contracts/EFeedbackWidgetActivationSource'
const { activateFeedbackWidget, isFeedbackButtonEnabled } = useFeedbackWidget()
</script>

<style scoped></style>
