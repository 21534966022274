<template>
  <svg
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    :class="[componentName]"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M12 8v4l3 3m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
    />
  </svg>
</template>

<script lang="ts">
export const componentName = 'FlowBiteClockOutlineIcon'
</script>

<script lang="ts" setup>
import { defineOptions } from 'vue'

defineOptions({
  name: componentName,
})
</script>

<style scoped></style>
