import { type RegisteredComponent } from '@builder.io/sdk-vue'
import component from '@/domain/Content/components/StepIcon.vue'
export const StepIconComponentRegistration = (
  shouldWrap = true,
): RegisteredComponent => ({
  component: component,
  name: 'Step Icon',
  noWrap: !shouldWrap,
  canHaveChildren: true,
  inputs: [
    {
      friendlyName: 'Icon type',
      name: 'type',
      type: 'string',
      enum: [
        'lightning-bolt',
        'document-duplicate',
        'user-group',
        'question-mark-circle',
        'pencil-alt',
        'share',
        'light-bulb',
      ],
      required: true,
    },
    {
      friendlyName: 'Element Classes',
      name: 'elementClasses',
      type: 'string',
    },
    {
      friendlyName: 'Merge ElementClasses',
      name: 'mergeElementClasses',
      type: 'boolean',
      defaultValue: true,
    },
    {
      friendlyName: 'Container Classes',
      name: 'containerClasses',
      type: 'string',
    },
    {
      friendlyName: 'Merge Container Classes',
      name: 'mergeContainerClasses',
      type: 'boolean',
      defaultValue: true,
    },
  ],
})
