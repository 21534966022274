<template>
  <div class="p-4 bg-base-200/50 rounded-lg text-base-500">
    <h4 class="text-base md:text-lg font-medium">An email is on its way</h4>

    <p v-html="authenticationMessages.loginInfo" />
    <p class="mt-2 text-base-400" v-html="authenticationMessages.linkExpiringHint" />
    <span
      class="block italic text-state-error-focus hover:underline hover:text-state-error mt-2 cursor-pointer"
      @click="tryAgainAction"
    >
      try again
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const componentName = 'EmailSuccess'

export default defineComponent({
  name: componentName,
})
</script>
<script lang="ts" setup>
import authenticationMessages from '@/authentication-messages.json'
import { useAuthentication } from '@/domain/Authentication/composables/useAuthentication'
import { EAuthenticationEvents } from '@/domain/Authentication/contracts/EAuthenticationEvents'
import { captureWithUser } from '@/app/support/usePosthog'

const { reset, status, signInContext, selectedProvider } = useAuthentication()

const tryAgainAction = () => {
  captureWithUser(EAuthenticationEvents.tryAnotherEmail, {
    status: status.value,
    provider: selectedProvider.value,
    signInContext: { ...signInContext.value },
  })
  reset()
}
</script>
