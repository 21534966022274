import { type RegisteredComponent } from '@builder.io/sdk-vue'
import component from '@/domain/Content/components/SectionLayout.vue'

export const SectionLayoutComponentRegistration = (
  shouldWrap = true,
): RegisteredComponent => ({
  component: component,
  name: 'Section Layout',
  noWrap: !shouldWrap,
  canHaveChildren: true,
  inputs: [
    {
      friendlyName: 'ID',
      helperText: 'ID attribute of section',
      name: 'idAttribute',
      type: 'string',
    },
    {
      name: 'tag',
      type: 'string',
      enum: ['div', 'section'],
      defaultValue: 'section',
    },
    {
      name: 'width',
      type: 'string',
      enum: ['wide', 'narrow'],
      defaultValue: 'wide',
    },
    {
      name: 'visualizeWidth',
      type: 'boolean',
      defaultValue: false,
    },
    {
      friendlyName: 'Container CSS Classes',
      name: 'containerClasses',
      type: 'string',
      defaultValue: '',
    },
    {
      friendlyName: 'Content Container CSS Classes',
      name: 'contentContainerClasses',
      type: 'string',
      defaultValue: '',
    },
  ],
})
