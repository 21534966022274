import type { BuilderContentVariation } from '@builder.io/sdk-vue/lib/browser/types/builder-content'

export const titleAndMetaCustomHeadPatcherFactory =
  (prefix = 'Miljn', separator = ' – ') =>
  (content: BuilderContentVariation) => ({
    title: [content?.data?.title, prefix].filter((item) => item).join(separator),
    meta: [
      {
        name: 'description',
        content: content.data?.description,
      },
    ],
  })
