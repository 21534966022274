import { applyShareLink } from '@/domain/cards/services/cardClient'
import { createGlobalState, useStorage } from '@vueuse/core'
import { useUserStore } from './useUserStore'

// @refactor useSharedTopicsStore is only used for applying copies through magic sign in that is really fragile
// remove this completely and apply new copy as sending email side effect
export const useSharedTopicsStore = createGlobalState(() => {
  const sharedTopicId = useStorage<string | null>('sharedTopicId', null)

  const shareCopyToUser = async () => {
    if (sharedTopicId.value === null) {
      return
    }
    await applyShareLink(sharedTopicId.value)
    reset()
  }

  const initialize = async () => {
    const { isLoggedIn } = useUserStore()
    if (!isLoggedIn.value) {
      return
    }
    await shareCopyToUser()
  }

  const reset = () => {
    sharedTopicId.value = null
  }

  const storeTopic = (id: string) => {
    if (id.length === 0) {
      return
    }
    sharedTopicId.value = id
  }

  return {
    sharedTopicId,
    initialize,
    reset,
    storeTopic,
  }
})
