import { Fragment, VNode } from 'vue'

export const isVnodeEmpty = (vnodes: Array<VNode>) => {
  return vnodes.every((node: VNode) => {
    if (node.type === Comment) {
      return true
    }

    if (
      node.type === Text &&
      typeof node.children === 'string' &&
      !node.children.trim()
    ) {
      return true
    }

    return node.type === Fragment && isVnodeEmpty(node.children as Array<VNode>)
  })
}
