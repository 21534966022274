const isDevelopmentMode = import.meta.env.DEV ?? false

export const routeLogger = (payload) => {
  if (!isDevelopmentMode) {
    return
  }
  // eslint-disable-next-line no-console
  console.log(
    '🧭',
    {
      from: payload?.from?.fullPath ?? null,
      to: payload?.to?.fullPath ?? null,
    },
    payload,
  )
}
