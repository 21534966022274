import { useUserStore } from '@/app/services/useUserStore'
import { routeLogger } from '@/app/services/routeLogger'
import { CAuthenticationRouteNames } from '@/domain/Authentication/contracts/CAuthenticationRouteNames'
import { useAuthentication } from '@/domain/Authentication/composables/useAuthentication'

export const logoutRouteGuard = async (to, from, next) => {
  if (!useUserStore().isLoggedIn) {
    routeLogger({
      msg: `already logged out redirect to ${CAuthenticationRouteNames.signIn}`,
      nav: { from, to },
    })
    next({
      name: CAuthenticationRouteNames.signIn,
      replace: true,
    })
    return
  }

  await useAuthentication().logout({
    userCleanup: useUserStore().reset,
  })

  routeLogger({
    msg: `redirect to ${CAuthenticationRouteNames.signIn}`,
    nav: { from, to },
  })
  next({
    name: CAuthenticationRouteNames.signIn,
    replace: true,
  })
  return
}
