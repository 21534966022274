import { onMounted, readonly, type Ref, ref } from 'vue'
import type { RouteLocationNormalizedLoaded } from 'vue-router'
import type { TSignInContent } from '@/domain/Authentication/contracts/TSignInContent'
import { ESignInContentTypes } from '@/domain/Authentication/contracts/ESignInContentTypes'
import { resolveSignInContentType } from '@/domain/Authentication/support/resolveSignInContentType'
import { staticSignInContents } from '@/domain/Authentication/data/staticSignInContents'
import { getSharingInformation } from '@/domain/cards/services/cardClient'
import type { TSharingInformation } from '@/domain/cards/contracts/TSharingInformation'
import { useUrlSearchParams } from '@vueuse/core'

const _content = ref<TSignInContent & { caseTitle?: string }>({
  type: ESignInContentTypes.default,
  title: undefined,
  signInTitle: undefined,
  caseTitle: undefined,
  showPreview: false,
  items: [],
})

const _caseInformation = ref<TSharingInformation | null>(null)

export const useSignInPageContent = (
  currentRoute: Ref<RouteLocationNormalizedLoaded>,
) => {
  const _fetch = async () => {
    const resolvedContentType = resolveSignInContentType(currentRoute.value)

    _content.value = (staticSignInContents[resolvedContentType] ??
      staticSignInContents[ESignInContentTypes.default]) as TSignInContent & {
      caseTitle?: string
    }

    switch (resolvedContentType) {
      case ESignInContentTypes.case:
        _caseInformation.value = await getSharingInformation(
          currentRoute.value.query.share_id as string,
        )
        if (_caseInformation.value) {
          _content.value.caseTitle = _caseInformation.value?.data.attributes
            ?.documentTitle as string
        }
        break
      case ESignInContentTypes.caseFromDirectory:
        _content.value.caseTitle = currentRoute.value.query.case_title as string
        break
      case ESignInContentTypes.prompt:
        _content.value.caseTitle = currentRoute.value.query.prompt as string
        break
      case ESignInContentTypes.promptFromDirectory:
        _content.value.caseTitle = currentRoute.value.query.case_title as string
        break
      default:
        break
    }
  }

  onMounted(async () => {
    await _fetch()
    if (_content.value.type === ESignInContentTypes.case && _caseInformation.value) {
      Object.assign(useUrlSearchParams('history'), {
        case_title: _caseInformation.value?.data.attributes?.documentTitle as string,
        case_snapshot_id: _caseInformation.value.data.attributes
          ?.documentSnapshotId as string,
      })
    }
  })

  return {
    content: readonly(_content),
    fetch: _fetch,
  }
}
