import { computed } from 'vue'
import { useStorage } from '@vueuse/core'

const _tryCount = useStorage<number>('x_authentication_retry_count', 0)
export const useXAuthenticationRetry = () => {
  return {
    hasTriedExactlyOnce: computed(() => _tryCount.value === 1),
    increment: () => (_tryCount.value = _tryCount.value + 1),
    reset: () => (_tryCount.value = 0),
  }
}
