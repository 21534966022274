import type { TDocumentCategoryData } from '@/domain/directory/contracts/TDocumentCategoryData'
import type { TDocumentCategory } from '@/domain/directory/contracts/TDocumentCategory'

export const categoryDataTransformer = (
  categoryData?: TDocumentCategoryData,
): TDocumentCategory | undefined => {
  if (!categoryData?.id && !categoryData?.data) {
    return undefined
  }
  return {
    id: categoryData.id,
    label: categoryData.data.label,
    slug: categoryData.data.slug,
  }
}
