import { ITheBlock } from '@/domain/blockList/contracts/ITheBlock'
import { flare } from '@flareapp/flare-client'
import { textBlockPayloadSchemaGuardAction } from '@/domain/blockList/components/blocks/guard.actions/textBlockPayloadSchemaGuardAction'
import { TBlockPayloadSchemaGuardActionStore } from '@/domain/blockList/contracts/TBlockPayloadSchemaGuardActionStore'
import { TIdentifiedBlockPayloadSchemaGuardAction } from '@/domain/blockList/contracts/TIdentifiedBlockPayloadSchemaGuardAction'
import { headlineBlockPayloadSchemaGuardAction } from '@/domain/blockList/components/blocks/guard.actions/headlineBlockPayloadSchemaGuardAction'
import { checkboxBlockPayloadSchemaGuardAction } from '@/domain/blockList/components/blocks/guard.actions/checkboxBlockPayloadSchemaGuardAction'
import { linkBlockPayloadSchemaGuardAction } from '@/domain/blockList/components/blocks/guard.actions/linkBlockPayloadSchemaGuardAction'
import { multiProviderVideoBlockPayloadSchemaGuardAction } from '@/domain/blockList/components/blocks/guard.actions/multiProviderVideoBlockPayloadSchemaGuardAction'
import { imageBlockPayloadSchemaGuardAction } from '@/domain/blockList/components/blocks/guard.actions/imageBlockPayloadSchemaGuardAction'

class BlockPayloadSchemaGuard {
  private readonly guards: TBlockPayloadSchemaGuardActionStore = {}

  constructor(guardActions: TIdentifiedBlockPayloadSchemaGuardAction[]) {
    for (const [blockTypeId, guardAction] of guardActions) {
      this.guards[blockTypeId] = guardAction
    }
  }

  async apply(block: ITheBlock): Promise<ITheBlock> {
    if (!this.guards[block.attributes.typeId]) {
      flare.reportMessage('No guard action found for block type', {
        currentBlock: block,
        guards: this.guards,
      })
      return block
    }

    return await this.guards[block.attributes.typeId](block)
  }
}

export const useBlockPayloadSchemaGuard = (
  additionalGuardActions: TIdentifiedBlockPayloadSchemaGuardAction[] = [],
): BlockPayloadSchemaGuard => {
  const defaultGuardActions: TIdentifiedBlockPayloadSchemaGuardAction[] = [
    textBlockPayloadSchemaGuardAction,
    ...headlineBlockPayloadSchemaGuardAction,
    checkboxBlockPayloadSchemaGuardAction,
    linkBlockPayloadSchemaGuardAction,
    multiProviderVideoBlockPayloadSchemaGuardAction,
    imageBlockPayloadSchemaGuardAction,
  ]

  return new BlockPayloadSchemaGuard([...defaultGuardActions, ...additionalGuardActions])
}
