<template>
  <component
    :is="props.tag"
    class="flex flex-col"
    :class="[containerClasses, componentName]"
  >
    <component :is="props.titleTag" :class="titleClasses" v-html="props.title" />
    <p :class="sublineClasses" v-html="props.subline" />
    <slot />
  </component>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const componentName = 'ActionableTextHero'
export default defineComponent({
  name: componentName,
})
</script>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'

const props = withDefaults(
  defineProps<
    {
      tag?: string
      containerClasses?: string
      mergeContainerClasses?: boolean
      title: string
      titleClasses?: string
      mergeTitleClasses?: boolean
      titleTag?: string
      subline: string
      sublineClasses?: string
      mergeSublineClasses?: boolean
    } & {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      attributes?: any
    }
  >(),
  {
    tag: 'div',
    titleTag: 'h1',
    mergeContainerClasses: true,
    mergeTitleClasses: true,
    mergeSublineClasses: true,
  },
)

const defaultContainerClasses = ''
const containerClasses = ref<string>()
const defaultTitleClasses = 'text-base-content text-2xl font-extrabold'
const titleClasses = ref<string>()
const sublineClasses = ref<string>()
const defaultSublineClasses = 'text-base-content text-base'

onMounted(() => {
  containerClasses.value = props.mergeContainerClasses
    ? `${defaultContainerClasses} ${props.containerClasses}`
    : props.containerClasses
  titleClasses.value = props.mergeTitleClasses
    ? `${defaultTitleClasses} ${props.titleClasses}`
    : props.titleClasses
  sublineClasses.value = props.mergeSublineClasses
    ? `${defaultSublineClasses} ${props.sublineClasses}`
    : props.sublineClasses
})
</script>

<style scoped></style>
