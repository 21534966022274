import { type RegisteredComponent } from '@builder.io/sdk-vue'
import component from '@/domain/Content/components/VideoSection.vue'
import { EBuilderIoModelTypes } from '@/app/contracts/EBuilderIoModelTypes'

export const VideoSectionRegistration = (shouldWrap = true): RegisteredComponent => ({
  component: component,
  name: 'VideoSection',
  noWrap: !shouldWrap,
  inputs: [
    {
      friendlyName: 'Section ID',
      helperText: 'required to be able to express in-page anchor marks, e.g. `how-to`',
      name: 'sectionId',
      type: 'string',
    },
    {
      name: 'title',
      type: 'longText',
    },
    {
      name: 'subline',
      type: 'longText',
    },
    {
      friendlyName: 'Container CSS Classes',
      name: 'containerClasses',
      type: 'string',
      defaultValue: 'py-3 lg:py-4',
    },
    {
      friendlyName: 'Video IFrame CSS Classes',
      name: 'iframeClasses',
      type: 'string',
      defaultValue: 'w-full md:max-w-full-md lg:max-w-full-lg',
    },
    {
      friendlyName: 'Vimeo Video Url',
      name: 'url',
      type: 'url',
      required: true,
    },
    {
      friendlyName: 'Model Type',
      helperText: 'defines from where to get the data',
      name: 'modelType',
      type: 'string',
      enum: Object.values(EBuilderIoModelTypes),
      defaultValue: EBuilderIoModelTypes.props,
    },
  ],
})
