<template>
  <div :id="props.scrollTargetId" class="w-full h-[1px]" :class="componentName" />
</template>
<script lang="ts">
import { defineComponent, defineProps } from 'vue'

export const componentName = 'ScrollTarget'
export default defineComponent({
  name: componentName,
})
</script>

<script lang="ts" setup>
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const props = defineProps<{ scrollTargetId: string } & { attributes?: any }>()
// @todo register component for builder.io
</script>

<style scoped></style>
