<template>
  <FlexibleSectionLayout
    tag="section"
    :visualize-width="false"
    width="narrow"
    :class="componentName"
    :container-classes="containerClassesComputed"
    :content-container-classes="contentContainerClassesComputed"
  >
    <TextHeading
      :title-tag="props.titleTag ?? EHeadline.H2"
      :content="{ title: props.content.title, subline: props.content.subline }"
      :classes="{
        container: titleContainerClassesComputed,
        title: titleClassesComputed,
        subline: sublineClassesComputed,
      }"
    />
    <div class="w-full flex flex-col gap-0.5 bg-base-100">
      <ComparisonTableRow
        :type="ETableItem.HEAD"
        container-classes=""
        :title="tableHead.title"
        :title-classes="tableHead.titleClasses"
        :images="tableHead.images"
      />
      <ComparisonTableRow
        v-for="(row, index) in rowsComputed"
        :key="index"
        :type="ETableItem.ROW"
        container-classes=""
        :title="row.title"
        :title-classes="row.titleClasses"
        :images="row.images"
      />
    </div>
  </FlexibleSectionLayout>
</template>

<script lang="ts">
export const componentName = 'ComparisonTableSection'
</script>

<script lang="ts" setup>
import { computed, defineOptions } from 'vue'
import FlexibleSectionLayout from '@/domain/Content/components/FlexibleSectionLayout.vue'
import { cva } from 'class-variance-authority'
import TextHeading from '@/domain/Content/components/TextHeading.vue'
import { EHeadline } from '@/domain/Content/contracts/EHeadline'
import { twMerge } from 'tailwind-merge'
import ComparisonTableRow from '@/domain/Content/components/ComparisonTableRow.vue'
import { ETableItem } from '@/domain/Content/contracts/ETableItem'
import checkSupportedIcon from '@/app/assets/icons/check-supported.svg'
import checkUnsupportedIcon from '@/app/assets/icons/check-unsupported.svg'

defineOptions({
  name: componentName,
})

const props = withDefaults(
  defineProps<
    {
      titleTag?: EHeadline
      content?: {
        title?: string
        subline?: string
      }
      classes?: {
        titleContainer?: string
        title?: string
        subline?: string
        container?: string
        contentContainer?: string
      }
      tableHead?: {
        title?: string
        titleClasses?: string
        images?: {
          src?: string
          alt?: string
          classes?: string
          containerClasses?: string
        }[]
      }
      tableRows?: {
        title?: string
        titleClasses?: string
        containerClasses?: string
        features: { supports: boolean }[]
      }[]
    } & {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      attributes?: any
    }
  >(),
  {
    titleTag: EHeadline.H2,
    content: () => ({
      title: undefined,
      subline: undefined,
    }),
    classes: () => ({
      titleContainer: undefined,
      title: undefined,
      subline: undefined,
      container: undefined,
      contentContainer: undefined,
    }),
    tableHead: () => ({
      title: 'Compare',
      titleClasses: undefined,
      images: [],
    }),
    tableRows: () => [
      {
        title: 'Book Summaries',
        titleClasses: undefined,
        features: [],
      },
      {
        title: 'Actionable step-by-step Guides',
        titleClasses: undefined,
        containerClasses: undefined,
        features: [],
      },
      {
        title: 'Create a book summary + actionable steps for any book via AI',
        titleClasses: undefined,
        containerClasses: undefined,
        features: [],
      },
    ],
  },
)

const containerClassesComputed = computed(() =>
  twMerge(cva()({ class: props.classes.container })),
)
const contentContainerClassesComputed = computed(() =>
  twMerge(cva('flex-col gap-4 md:gap-8')({ class: props.classes.contentContainer })),
)

const titleContainerClassesComputed = computed(() =>
  twMerge(cva([])({ class: props.classes.titleContainer })),
)

const titleClassesComputed = computed(() =>
  twMerge(cva('text-2xl text-center')({ class: props.classes.title })),
)

const sublineClassesComputed = computed(() =>
  twMerge(cva('text-base font-normal text-center')({ class: props.classes.subline })),
)

const rowsComputed = computed(() =>
  props.tableRows.map((row) => {
    const item: {
      title?: string
      titleClasses?: string
      features: { supports: boolean }[]
    } = { ...row }

    if ((item?.features ?? []).length > (props.tableHead?.images ?? []).length) {
      item.features = (item?.features ?? []).slice(
        0,
        (props.tableHead?.images ?? []).length,
      )
    }

    const result = {
      title: item.title,
      titleClasses: item.titleClasses,
      images: item.features.map((feature) => {
        return {
          src: feature.supports ? checkSupportedIcon : checkUnsupportedIcon,
          alt: feature.supports ? 'check icon' : 'missing icon',
          classes: 'w-5',
          containerClasses: undefined,
        }
      }),
    }

    return result
  }),
)
</script>

<style scoped></style>
