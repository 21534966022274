import { type RegisteredComponent } from '@builder.io/sdk-vue'
import component from '@/domain/Content/components/TextHeading.vue'
import { toKeyValue } from '@/domain/Content/support/toKeyValue'
import { EHeadline } from '@/domain/Content/contracts/EHeadline'

export const TextHeadingComponentRegistration = (
  shouldWrap = true,
): RegisteredComponent => ({
  component: component,
  name: 'Text Heading',
  noWrap: !shouldWrap,
  inputs: [
    {
      name: 'titleTag',
      type: 'string',
      defaultValue: EHeadline.H2,
      enum: toKeyValue(EHeadline),
    },
    {
      name: 'content',
      type: 'object',
      defaultValue: {
        category: undefined,
        title: undefined,
        subline: undefined,
        claim: undefined,
      },
      subFields: [
        {
          name: 'category',
          type: 'longText',
        },
        {
          name: 'title',
          type: 'longText',
        },
        {
          name: 'subline',
          type: 'longText',
        },
        {
          name: 'claim',
          type: 'longText',
        },
      ],
    },
    {
      name: 'classes',
      type: 'object',
      defaultValue: {
        container: undefined,
        category: undefined,
        title: undefined,
        subline: undefined,
        claim: undefined,
      },
      subFields: [
        {
          name: 'container',
          type: 'string',
        },
        {
          name: 'category',
          type: 'string',
        },
        {
          name: 'title',
          type: 'string',
        },
        {
          name: 'subline',
          type: 'string',
        },
        {
          name: 'claim',
          type: 'string',
        },
      ],
    },
  ],
})
