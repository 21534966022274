import {
  type RouteLocation,
  type RouteLocationNormalizedLoaded,
  type RouteLocationRaw,
} from 'vue-router'

export const useRouteLocation = (
  resolve: (
    to: RouteLocationRaw,
    currentLocation?: RouteLocationNormalizedLoaded,
  ) => RouteLocation & {
    href: string
  },
) => {
  const _fromUrlString = (url: string, withQuery?: unknown, defaultRoute = '/') => {
    let routeLocation: RouteLocation & {
      href: string
    }

    try {
      const urlInstance = new URL(url)
      routeLocation = resolve(
        urlInstance.pathname + urlInstance.search + urlInstance.hash,
      )
    } catch {
      routeLocation = resolve(defaultRoute)
    }

    if (withQuery && typeof withQuery === 'object') {
      routeLocation.query = {
        ...routeLocation.query,
        ...withQuery,
      }
    }

    return routeLocation
  }

  return {
    fromUrlString: _fromUrlString,
  }
}
