import type { RouteLocationNormalizedLoaded } from 'vue-router'
import { ESignInContentTypes } from '@/domain/Authentication/contracts/ESignInContentTypes'

export const resolveSignInContentType = (currentRoute: RouteLocationNormalizedLoaded) => {
  if (
    currentRoute.query.directory_entry_id &&
    currentRoute.query.case_title &&
    currentRoute.query.case_type === 'directory-prompt' &&
    currentRoute.query.prompt
  ) {
    return ESignInContentTypes.promptFromDirectory
  }

  if (currentRoute.query.prompt) {
    return ESignInContentTypes.prompt
  }

  if (
    currentRoute.query.directory_entry_id &&
    currentRoute.query.case_title &&
    currentRoute.query.case_type === 'directory-copy'
  ) {
    return ESignInContentTypes.caseFromDirectory
  }

  if (currentRoute.query.share_id) {
    return ESignInContentTypes.case
  }

  return ESignInContentTypes.default
}
