import { TShallowReactiveItemList } from '@/domain/LinkedList/contracts/TShallowReactiveItemList'
import { TSortedItemList } from '@/domain/LinkedList/contracts/TSortedItemList'
import { ShallowRef } from '@vue/reactivity'
import { computed, unref } from 'vue'
import { IIdentifiable } from '@/app/contracts/IIdentifiable'

export const useComputedSortedLinkedItemList = <T extends IIdentifiable>(
  linkedItemList: TShallowReactiveItemList<T>,
  sortList: (source: TShallowReactiveItemList<T>) => TSortedItemList<T>,
  applyOrder: (
    state: TShallowReactiveItemList<T>,
    idList: string[],
    mocks?: { triggerRef?: () => void },
  ) => ShallowRef<T>[],
  doRemoteUpdate: (changedItemsWithNewLinks: T[]) => void,
  debug?: {
    getterLogger?: (values: {
      linkedItemList: TShallowReactiveItemList<T>
      sortedLinkedItemList: TSortedItemList<T>
    }) => void
    setterLogger?: (values: {
      linkedItemList: TShallowReactiveItemList<T>
      sortedLinkedItemList: TSortedItemList<T>
      itemIdListWithNewOrder: string[]
      changedItemsWithNewLinks: ShallowRef<T>[]
    }) => void
  },
) => {
  return computed({
    get() {
      const sortedLinkedItemList = sortList(linkedItemList)
      if (debug?.getterLogger) {
        debug.getterLogger({
          linkedItemList: linkedItemList,
          sortedLinkedItemList: sortedLinkedItemList,
        })
      }
      return sortedLinkedItemList
    },
    set(sortedLinkedItemList: TSortedItemList<T>) {
      const itemIdListWithNewOrder = sortedLinkedItemList.map((item) => item.value.id)
      const changedItemsWithNewLinks = applyOrder(
        linkedItemList,
        itemIdListWithNewOrder,
        undefined,
      )

      doRemoteUpdate(changedItemsWithNewLinks.map((theBlock) => unref(theBlock)))

      if (debug?.setterLogger) {
        debug.setterLogger({
          linkedItemList,
          sortedLinkedItemList,
          itemIdListWithNewOrder,
          changedItemsWithNewLinks,
        })
      }
    },
  })
}
