<template>
  <div
    ref="undoNotificationRef"
    class="fixed bottom-10 left-1/2 z-[100] -translate-x-1/2"
  >
    <Transition
      enter-active-class="duration-300 ease-out"
      enter-from-class="transform opacity-0 translate-y-6"
      enter-to-class="opacity-100 translate-x-0"
      leave-active-class="duration-200 ease-in"
      leave-from-class="opacity-100 translate-x-0"
      leave-to-class="transform opacity-0 translate-y-6"
    >
      <div
        v-if="showNotification"
        aria-live="assertive"
        aria-label="Undo Notification"
        class="pointer-events-none flex gap-3 rounded-lg bg-black px-4 py-2 text-sm text-base-100"
      >
        <span>
          <span class="mr-1 capitalize">{{ lastDeletedTypeName }}</span>
          <span>deleted</span>
        </span>
        <button class="pointer-events-auto uppercase" @click="undoLastAction">
          Undo
        </button>
      </div>
    </Transition>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const componentName = 'UndoNotification'
export default defineComponent({
  name: componentName,
})
</script>

<script setup lang="ts">
import { watch, Ref } from 'vue'
import { MaybeElement, templateRef } from '@vueuse/core'
import { useRestoreHistory } from '@/app/services/useRestoreHistory'
import { useNotificationRefsStore } from '@/domain/notifications/services/useNotificationRefsStore'

defineEmits(['closed'])

const { showNotification, lastDeletedTypeName, undoLastAction } = useRestoreHistory()

const undoNotificationRef = templateRef('undoNotificationRef') as Ref<MaybeElement>
watch(showNotification, (shouldShowNotification) => {
  shouldShowNotification &&
    useNotificationRefsStore().storeRef('notificationRef', undoNotificationRef)
})
</script>
