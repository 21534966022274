<template>
  <FlexibleSectionLayout
    tag="section"
    :visualize-width="false"
    width="narrow"
    :class="componentName"
    :container-classes="containerClasses"
    :content-container-classes="contentContainer"
  >
    <div :class="headingContainer">
      <TextHeading
        :title-tag="EHeadline.H3"
        :content="{
          title: props.title,
        }"
        :classes="{
          container: titleContainer,
          title: title,
        }"
      />

      <MultiSelect
        v-if="showCategorySelector"
        v-model="selectedCategoryIds"
        :options="categoryOptions"
        filter
        option-label="attributes.label"
        option-value="id"
        :placeholder="props.categoryPlaceholder"
        :max-selected-labels="3"
        :class="multiSelect"
      />
    </div>

    <div :class="entriesContainer">
      <BookDirectoryCard
        v-for="entry in entriesList"
        :key="entry.id"
        :entry="entry"
        :classes="{
          container: props?.classes?.singleEntryContainer,
          imageBadge: props?.classes?.singleContainerImageBadge,
          title: props?.classes?.singleContainerTitle,
        }"
        :image-badge-text="props.imageBadgeText"
        :max-visible-authors="props.maxVisibleAuthors"
        :max-visible-categories="props.maxVisibleCategories"
        :find-authors="findAuthors"
        :find-categories="findCategories"
      />
    </div>
  </FlexibleSectionLayout>
</template>

<script lang="ts">
export const componentName = 'BooksDirectory'
</script>

<script lang="ts" setup>
import { computed, defineOptions } from 'vue'
import FlexibleSectionLayout from '@/domain/Content/components/FlexibleSectionLayout.vue'
import MultiSelect from 'primevue/multiselect'
import { twMerge } from 'tailwind-merge'
import { cva } from 'class-variance-authority'
import { EHeadline } from '@/domain/Content/contracts/EHeadline'
import TextHeading from '@/domain/Content/components/TextHeading.vue'
import { useDirectoryEntries } from '@/domain/Book/composables/useDirectoryEntries'
import BookDirectoryCard from '@/domain/Book/components/BookDirectoryCard.vue'
import { Environment } from '@/app/support/Environment'

defineOptions({
  name: componentName,
})

const props = withDefaults(
  defineProps<
    {
      classes?: {
        container?: string
        contentContainer?: string
        headingContainer?: string
        titleContainer?: string
        title?: string
        multiSelect?: string
        entriesContainer?: string
        singleEntryContainer?: string
        singleContainerImageBadge?: string
        singleContainerTitle?: string
      }
      title?: string
      showCategorySelector?: boolean
      categoryPlaceholder?: string
      imageBadgeText?: string
      maxVisibleAuthors?: number
      maxVisibleCategories?: number
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } & { attributes?: any }
  >(),
  {
    classes: () => ({
      container: undefined,
      contentContainer: undefined,
      headingContainer: undefined,
      titleContainer: undefined,
      title: undefined,
      multiSelect: undefined,
      entriesContainer: undefined,
      singleEntryContainer: undefined,
      singleContainerImageBadge: undefined,
      singleContainerTitle: undefined,
    }),
    showCategorySelector: true,
    categoryPlaceholder: 'Select a Category',
    imageBadgeText: 'Curated',
    maxVisibleAuthors: 3,
    maxVisibleCategories: 1,
  },
)

const containerClasses = computed(() =>
  twMerge(cva('w-full my-6')({ class: props.classes.container })),
)

const contentContainer = computed(() =>
  twMerge(cva('flex-col')({ class: props.classes.contentContainer })),
)

const headingContainer = computed(() =>
  twMerge(
    cva(
      'w-full flex flex-col sm:flex-row space-y-4 sm:space-y-0 justify-between items-start sm:items-center w-full sm:grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 sm:gap-x-4 sm:gap-y-8 mb-4',
    )({ class: props.classes.headingContainer }),
  ),
)

const titleContainer = computed(() =>
  twMerge(
    cva(
      'w-full flex flex-col sm:flex-row justify-between items-start sm:items-end lg:col-span-2 xl:col-span-3 mt-0',
    )({
      class: props.classes.titleContainer,
    }),
  ),
)

const title = computed(() =>
  twMerge(cva('text-2xl mt-0')({ class: props.classes.title })),
)

const multiSelect = computed(() =>
  twMerge(cva('w-full bg-base-100')({ class: props.classes.multiSelect })),
)

const entriesContainer = computed(() =>
  twMerge(
    cva('grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-4 gap-y-8')({
      class: props.classes.entriesContainer,
    }),
  ),
)

const {
  entries: entriesList,
  selectedCategoryIds,
  categories,
  findAuthors,
  findCategories,
} = useDirectoryEntries({
  useAutoFetch: true,
  filterByEnvironment: true,
  currentEnvironment: Environment.current(),
})

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const categoryOptions = computed<any[]>(() => (categories?.value ?? []) as any[])
</script>

<style scoped lang="postcss"></style>
