import { TIdentifiedBlockPayloadSchemaGuardAction } from '@/domain/blockList/contracts/TIdentifiedBlockPayloadSchemaGuardAction'
import { EBlockType } from '@/domain/blockList/contracts/EBlockType'
import { ITheBlock } from '@/domain/blockList/contracts/ITheBlock'
import { TTheBlockImagePayload } from '@/domain/blockList/contracts/TTheBlockImagePayload'

// @todo we have to figure out what values of the payload can be

export const imageBlockPayloadSchemaGuardAction: TIdentifiedBlockPayloadSchemaGuardAction =
  [
    EBlockType.IMAGE,
    (block: ITheBlock): Promise<ITheBlock> => {
      const payload: TTheBlockImagePayload = block.attributes
        .payload as TTheBlockImagePayload

      block.attributes.payload = {
        value: payload?.value ?? '',
        prefix: payload?.prefix ?? '',
        baseUrl: payload?.baseUrl ?? '',
        version: payload?.version ?? '',
      } as TTheBlockImagePayload

      return Promise.resolve(block)
    },
  ]
