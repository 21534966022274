import { apiPrefix } from '@/app/services/miljnApiEndpointsWeb2'

export const theBlockApiEndpoints = {
  viewAny: (nodeId: string) => `${apiPrefix}/nodes/${nodeId}/the-blocks`,
  create: (nodeId: string) => `${apiPrefix}/nodes/${nodeId}/the-blocks`,
  reorder: (nodeId: string) => `${apiPrefix}/nodes/${nodeId}/the-blocks/reorder`,
  view: (theBlockId: string) => `${apiPrefix}/the-blocks/${theBlockId}`,
  update: (theBlockId: string) => `${apiPrefix}/the-blocks/${theBlockId}`,
  remove: (theBlockId: string) => `${apiPrefix}/the-blocks/${theBlockId}`,
  restore: (theBlockId: string) => `${apiPrefix}/the-blocks/${theBlockId}/restore`,
}
