import miljnApiBaseClientFactory from '@/app/services/miljnApiBaseClientFactory'
import { setPreferHeader } from '@/app/services/preferHeaderResolver'
import { documents } from '@/app/services/miljnApiEndpointsWeb2'
import type { TCreateDocumentCopyRequest } from '@/domain/documents/contracts/TCreateDocumentCopyRequest'

export const createDocument = async (title: string) => {
  const instance = miljnApiBaseClientFactory()
  const requestBody = {
    data: {
      type: 'documents',
      attributes: {
        title: title,
        status: 'null',
      },
    },
  }

  const requestConfig = setPreferHeader({}, 'documents')

  const response = await instance.post(documents.index, requestBody, requestConfig)

  return response?.data?.data ?? null
}

export const getDocumentStatus = async (id: string) => {
  const instance = miljnApiBaseClientFactory()
  const requestConfig = setPreferHeader({}, 'documents')
  const response = await instance.get(
    documents.documentStatusByDocumentId(id),
    requestConfig,
  )
  return response?.data
}

export enum EDocumentCopyTypes {
  COPY = 'copy',
  ONE_TIME_COPY = 'one-time-copy',
}

export type TDocumentCopy = {
  id: string
  type: 'documentCopies'
  attributes: {
    title: string
    type: EDocumentCopyTypes
    sourceId: string
    templateId: string
  }
}

export const createDocumentCopy = async (copyIdentifier: {
  sourceDocumentId: string
  copyId: string
}): Promise<TDocumentCopy | null> => {
  const instance = miljnApiBaseClientFactory()
  const requestBody: { data: TCreateDocumentCopyRequest } = {
    data: {
      id: copyIdentifier.copyId,
      type: 'createDocumentCopiesRequest',
    },
  }

  const requestConfig = setPreferHeader({}, 'documents')
  const requestUrl = documents.documentCopy(
    copyIdentifier.sourceDocumentId,
    copyIdentifier.copyId,
  )
  const response = await instance.post<{ data: TDocumentCopy }>(
    requestUrl,
    requestBody,
    requestConfig,
  )
  return response?.data?.data ?? null
}
