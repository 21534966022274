import miljnApiBaseClientFactory from '@/app/services/miljnApiBaseClientFactory'
import { applyShareLinks, cards } from '@/app/services/miljnApiEndpointsWeb2'
import { metaDataTypes, updateMetaData } from '@/app/services/metaDataClient'
import { v4 as uuid } from 'uuid'
import { setPreferHeader } from '@/app/services/preferHeaderResolver'
import {
  mergeMetaDataWithCardData,
  resolveChildrenCardsDataByParentIdAsKey,
} from '@/domain/cards/support/helper'
import type { TSharingInformation } from '@/domain/cards/contracts/TSharingInformation'

export const getUserTopics = async () => {
  const instance = miljnApiBaseClientFactory()

  const requestConfig = setPreferHeader({}, 'cards')

  const topicsWithMetaData = await instance.get(
    cards.index + '?include=metadata&filter[cardType]=topic',
    requestConfig,
  )

  const result = mergeMetaDataWithCardData(
    topicsWithMetaData.data.included,
    topicsWithMetaData.data.data,
  )

  return result
}

export const getCardData = async (id: string) => {
  const instance = miljnApiBaseClientFactory()

  const topicWithMetaData = await instance.get(
    cards.index + `?include=metadata&filter[id]=${id}`,
  )

  const result = mergeMetaDataWithCardData(
    topicWithMetaData.data.included,
    topicWithMetaData.data.data,
  )

  return result.data[0]
}

export const createTopic = async (
  topicTitle: string,
  enableAiSupport = false,
  topicId?: string,
) => {
  const instance = miljnApiBaseClientFactory()
  const requestBody = {
    data: {
      id: topicId ?? uuid(),
      type: 'cards',
      attributes: {
        cardType: 'topic',
      },
    },
  }

  const requestConfig = setPreferHeader({}, 'cards')

  const createTopicResponse = await instance.post(
    cards.index + '?include=metadata',
    requestBody,
    requestConfig,
  )

  const metaDataIdOfTopicTitle = createTopicResponse.data.included.find(
    (metaDataItem) => {
      if (metaDataItem.relationships.metadataType.data.id === metaDataTypes.titleId) {
        return metaDataItem
      }
    },
  )?.id

  await updateMetaData(metaDataIdOfTopicTitle, topicTitle)

  if (enableAiSupport) {
    const extendDocumentWithAiMetadata = createTopicResponse.data.included.find(
      (metaDataItem) => {
        if (
          metaDataItem.relationships.metadataType.data.id ===
          metaDataTypes.extendDocumentWithAiId
        ) {
          return metaDataItem
        }
      },
    )

    extendDocumentWithAiMetadata.attributes.payload = Object.assign(
      extendDocumentWithAiMetadata.attributes.payload,
      { status: 'new' },
    )

    await updateMetaData(
      extendDocumentWithAiMetadata.id,
      extendDocumentWithAiMetadata.attributes.payload,
      false,
    )
  }

  return createTopicResponse?.data ?? null
}

export const deleteCardById = async (cardId: string) => {
  const instance = miljnApiBaseClientFactory()

  const requestConfig = setPreferHeader({}, 'cards')

  return (await instance.delete(cards.byCardId(cardId), requestConfig))?.data ?? null
}

export const restoreCardById = async (cardId: string) => {
  const instance = miljnApiBaseClientFactory()

  return (await instance.post(cards.restoreByCardId(cardId)))?.data ?? null
}

export const moveCardBetweenColumns = async (nodeId: string, targetParentId: string) => {
  const instance = miljnApiBaseClientFactory()

  return (
    (await instance.put(cards.assignParent(nodeId, targetParentId), null))?.data ?? null
  )
}

export const getChildrenData = async (card: any) => {
  const instance = miljnApiBaseClientFactory()
  const children = card.relationships.children.data
  if (!children.length) return

  const childrenCardIds = children.map((child) => child.id)
  const fetchInstanced = await instance.get(
    cards.index + `?include=metadata&filter[id]=${childrenCardIds}`,
  )
  const childrenData = childrenCardIds.map(
    (id) => fetchInstanced.data.data.filter((parent) => parent.id === id)[0],
  )
  const childrenWithMetaData = mergeMetaDataWithCardData(
    fetchInstanced.data.included,
    childrenData,
  )

  return childrenWithMetaData.data
}

export const openTopicById = async (topicId: string) => {
  const instance = miljnApiBaseClientFactory()

  const requestConfig = setPreferHeader({}, 'cards')

  const topicWithMetaData = await instance.get(
    cards.byCardId(topicId) + '?include=metadata',
    requestConfig,
  )

  const parentCardIds = topicWithMetaData.data.data.relationships.children.data.map(
    (parent) => parent.id,
  )

  const parentsWithMetaData = await instance.get(
    cards.index + `?include=metadata&filter[id]=${parentCardIds}`,
    requestConfig,
  )

  parentsWithMetaData.data.data = parentCardIds.map(
    (id) => parentsWithMetaData.data.data.filter((parent) => parent.id === id)[0],
  )

  const childrenRequestPromises = parentsWithMetaData.data.data.map((parent) => {
    const childrenCardIds = parent.relationships.children.data.map((child) => child.id)

    return (async () => {
      const fetchInstanced = await instance.get(
        cards.index + `?include=metadata&filter[id]=${childrenCardIds}`,
        requestConfig,
      )
      fetchInstanced.data.data = childrenCardIds.map(
        (id) => fetchInstanced.data.data.filter((parent) => parent.id === id)[0],
      )

      return fetchInstanced
    })()
  })

  const childrenWithMetaData: any = await Promise.all(childrenRequestPromises)

  const result = {
    data: {
      topic: mergeMetaDataWithCardData(topicWithMetaData.data.included, [
        topicWithMetaData.data.data,
      ]).data[0],
      parents: mergeMetaDataWithCardData(
        parentsWithMetaData.data.included,
        parentsWithMetaData.data.data,
      ).data,
      children: resolveChildrenCardsDataByParentIdAsKey(
        childrenWithMetaData.map((childWithMetaData) =>
          mergeMetaDataWithCardData(
            childWithMetaData.data.included,
            childWithMetaData.data.data,
          ),
        ),
      ),
    },
  }
  return result
}

export const createCard = async (parentCardId: string, cardId: string) => {
  const instance = miljnApiBaseClientFactory()

  const requestBody = {
    data: {
      id: cardId,
      type: 'cards',
      attributes: {
        cardType: 'card',
      },
      relationships: {
        parent: {
          data: {
            id: parentCardId,
            type: 'cards',
          },
        },
      },
    },
  }

  const requestConfig = setPreferHeader({}, 'cards')

  return (
    (await instance.post(cards.index + '?include=metadata', requestBody, requestConfig))
      ?.data ?? null
  )
}

export const updateCardOrder = async (
  parentCardId: string,
  parentCardType: string,
  children: Array<object>,
) => {
  const instance = miljnApiBaseClientFactory()

  const requestBody = {
    data: {
      id: parentCardId,
      type: 'cards',
      attributes: {
        cardType: parentCardType,
      },
      relationships: {
        children: {
          data: children,
        },
      },
    },
  }

  const requestConfig = setPreferHeader({}, 'cards')

  return (
    (await instance.patch(cards.byCardId(parentCardId), requestBody, requestConfig))
      ?.data ?? null
  )
}

export const updateBlockOrder = async (cardId: string, blockList: Array<object>) => {
  const instance = miljnApiBaseClientFactory()
  const requestBody = {
    data: {
      id: cardId,
      type: 'cards',
      attributes: {
        cardType: 'card',
      },
      relationships: {
        blocks: {
          data: blockList,
        },
      },
    },
  }

  const requestConfig = setPreferHeader({}, 'cards')

  return (
    (await instance.patch(cards.byCardId(cardId), requestBody, requestConfig))?.data ??
    null
  )
}

export const requestCollaborationUrl = async (topicId: string) => {
  const instance = miljnApiBaseClientFactory()

  return (await instance.put(cards.shareByTopicId(topicId), null))?.data ?? null
}

export const requestCopyATopicUrl = async (topicId: string, isOneTimeCopy = false) => {
  const instance = miljnApiBaseClientFactory()
  const params = isOneTimeCopy ? { is_one_time: true } : {}

  return (
    (await instance.put(cards.shareCopyByTopicId(topicId), null, { params }))?.data ??
    null
  )
}

export const applyShareLink = async (shareId: string) => {
  const instance = miljnApiBaseClientFactory()

  return (
    (await instance.put(applyShareLinks.applySharedLinkByShareId(shareId)))?.data ?? null
  )
}

export const getSharingInformation = async (
  shareId: string,
): Promise<TSharingInformation | null> => {
  const instance = miljnApiBaseClientFactory(false)
  return (
    (
      await instance.get<TSharingInformation | null>(
        applyShareLinks.sharingInformation(shareId),
      )
    )?.data ?? null
  )
}

export default {
  getUserTopics,
  createTopic,
  deleteCardById,
  openTopicById,
  createCard,
  updateCardOrder,
  updateBlockOrder,
}
