<template>
  <component
    :is="props.tag"
    :id="idAttribute"
    class="flex w-full justify-center text-center"
    :class="[
      ...(props.visualizeWidth ? ['border-2', 'border-blue-400'] : []),
      props.containerClasses,
      componentName,
    ]"
  >
    <div
      class="flex flex-col w-full px-4"
      :class="[
        ...(props.width === 'narrow' ? ['min-w-xs', 'max-w-7xl'] : []),
        ...(props.visualizeWidth ? ['border-2', 'border-pink-500'] : []),
        props.contentContainerClasses,
      ]"
    >
      <slot />
    </div>
  </component>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

// @deprecated use FlexibleSectionLayout
export const componentName = 'SectionLayout'
export default defineComponent({
  name: componentName,
})
</script>

<script lang="ts" setup>
// @todo apply merge classes pattern
const props = withDefaults(
  defineProps<{
    idAttribute?: string
    tag?: string
    width: 'wide' | 'narrow'
    visualizeWidth?: boolean
    containerClasses?: string
    contentContainerClasses?: string
    // eslint-disable-next-line
    attributes?: any
  }>(),
  {
    idAttribute: undefined,
    tag: 'section',
    width: 'wide',
    containerClasses: undefined,
    contentContainerClasses: undefined,
    visualizeWidth: false,
    attributes: undefined,
  },
)
</script>

<style scoped></style>
