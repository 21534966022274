import type { TSignInWithProviderAction } from '@/domain/Authentication/contracts/TSignInWithProviderAction'
import { Ref, watch } from 'vue'
import { EAuthenticationProviderSignInFlowStates } from '@/domain/Authentication/contracts/EAuthenticationProviderSignInFlowStates'
import { EAuthenticationProviders } from '@/domain/Authentication/contracts/EAuthenticationProviders'
import type { TSignInContext } from '@/domain/Authentication/contracts/TSignInContext'
import type { TRouteResolver } from '@/domain/Authentication/contracts/TRouteResolver'
import type { TCurrentAuthenticationState } from '@/domain/Authentication/contracts/TCurrentAuthenticationState'
import { useConfig } from '@/app/services/useConfig'
import { useLinkedInAuthCodeStore } from '@/domain/Authentication/composables/useLinkedInAuthCodeStore'
import { useLinkedInPopupWindow } from '@/domain/Authentication/composables/useLinkedInPopupWindow'
import { captureWithUser } from '@/app/support/usePosthog'
import { EAuthenticationEvents } from '@/domain/Authentication/contracts/EAuthenticationEvents'
import { requestAuthenticationDocumentBySecretType } from '@/domain/Authentication/services/requestAuthenticationDocumentBySecretType'
import { EAuthenticationSecretTypes } from '@/domain/Authentication/contracts/EAuthenticationSecretTypes'
import { useAuthentication } from '@/domain/Authentication/composables/useAuthentication'
import { useUserStore } from '@/app/services/useUserStore'
import { resolveAfterSignInRedirectTarget } from '@/domain/Authentication/support/resolveAfterSignInRedirectTarget'
import { useNavigator } from '@/app/composables/useNavigator'
import { CAuthenticationRouteNames } from '@/domain/Authentication/contracts/CAuthenticationRouteNames'

const doSignIn = async (
  code: string,
  status: Ref<EAuthenticationProviderSignInFlowStates>,
  selectedProvider: Ref<EAuthenticationProviders>,
  signInContext: Readonly<Ref<TSignInContext>>,
  routeResolver: TRouteResolver,
  reset: () => TCurrentAuthenticationState,
) => {
  try {
    const authenticationDocument = await requestAuthenticationDocumentBySecretType(
      EAuthenticationSecretTypes.LINKEDIN,
      code,
    )
    useAuthentication().storeJwt(authenticationDocument?.attributes.payload as string)
    await useUserStore().refresh()

    const nonRefSignInContext = signInContext.value
    status.value = EAuthenticationProviderSignInFlowStates.SUCCEEDED

    const navigationTarget = resolveAfterSignInRedirectTarget(
      routeResolver,
      nonRefSignInContext,
      false,
    )

    const afterNavigationCallback = async (): Promise<void> => {
      captureWithUser(EAuthenticationEvents.signedInSuccessfully, {
        status: status.value,
        provider: selectedProvider.value,
        signInContext: { ...nonRefSignInContext },
      })
      reset()
      await useLinkedInAuthCodeStore().reset()
      return Promise.resolve()
    }

    await useNavigator().push(navigationTarget, afterNavigationCallback)
  } catch (error) {
    const errorMessage = (error as Partial<{ message?: string }>).message ?? undefined
    const nonRefSignInContext = signInContext.value
    status.value = EAuthenticationProviderSignInFlowStates.FAILED

    const _status = status.value
    const _provider = selectedProvider.value

    const afterNavigationCallback = (): Promise<void> => {
      captureWithUser(EAuthenticationEvents.signInFailed, {
        status: _status,
        provider: _provider,
        signInContext: { ...nonRefSignInContext },
        errorMessage,
      })

      // @todo what should we do here? triggering an user notification?
      // @hint UserNotification

      reset()
      return Promise.resolve()
    }

    await useNavigator().push(
      {
        name: CAuthenticationRouteNames.signIn,
      },
      afterNavigationCallback,
    )
  }
}

export const signInWithLinkedIn: TSignInWithProviderAction = async (
  status: Ref<EAuthenticationProviderSignInFlowStates>,
  selectedProvider: Ref<EAuthenticationProviders>,
  signInContext: Readonly<Ref<TSignInContext>>,
  routeResolver: TRouteResolver,
  reset: () => TCurrentAuthenticationState,
) => {
  if (selectedProvider.value !== EAuthenticationProviders.LINKEDIN) {
    // eslint-disable-next-line
    console.error(
      `Provider '${selectedProvider}' not support, '${EAuthenticationProviders.LINKEDIN}' is required`,
    )
    return
  }

  // const {state, reset: linkedInCodeStoreReset} = useLinkedInAuthCodeStore()
  watch(
    () => useLinkedInAuthCodeStore().state.value,
    async (newSate) => {
      if (!newSate.code) {
        return
      }

      await doSignIn(
        newSate.code,
        status,
        selectedProvider,
        signInContext,
        routeResolver,
        reset,
      )
    },
  )

  await useLinkedInAuthCodeStore().reset()

  useLinkedInPopupWindow(
    useConfig().get().authentication.linkedIn.clientId as string,
    useConfig().get().authentication.linkedIn.redirect as string,
    'profile email openid',
    () => {
      useLinkedInAuthCodeStore().reset()
      status.value = EAuthenticationProviderSignInFlowStates.FAILED
      captureWithUser(EAuthenticationEvents.aborted, {
        status: EAuthenticationProviderSignInFlowStates.FAILED,
        provider: selectedProvider.value,
        signInContext: { ...signInContext.value },
        errorMessage: `User closed '${selectedProvider.value}' sign in popup manually`,
      })
    },
  ).open()
}
