import type { RegisteredComponent } from '@builder.io/sdk-vue'
import component from '@/domain/Content/components/ComparisonTableSection.vue'
import { EHeadline } from '@/domain/Content/contracts/EHeadline'
import { toKeyValue } from '@/domain/Content/support/toKeyValue'

export const ComparisonTableSectionComponentRegistration = (
  shouldWrap = true,
): RegisteredComponent => ({
  component: component,
  name: 'ComparisonTableSection',
  friendlyName: 'Comparison Table Section',
  noWrap: !shouldWrap,
  inputs: [
    {
      name: 'titleTag',
      type: 'string',
      defaultValue: EHeadline.H2,
      enum: toKeyValue(EHeadline),
    },
    {
      name: 'content',
      type: 'object',
      defaultValue: {
        category: undefined,
        title: undefined,
        subline: undefined,
        claim: undefined,
      },
      subFields: [
        {
          name: 'title',
          type: 'longText',
        },
        {
          name: 'subline',
          type: 'longText',
        },
      ],
    },
    {
      name: 'classes',
      type: 'object',
      defaultValue: {
        title: undefined,
        subline: undefined,
      },
      subFields: [
        {
          name: 'container',
          type: 'string',
        },
        {
          name: 'title',
          type: 'string',
        },
        {
          name: 'subline',
          type: 'string',
        },
      ],
    },
    {
      name: 'tableHead',
      type: 'object',
      subFields: [
        {
          name: 'title',
          type: 'text',
        },
        {
          name: 'titleClasses',
          type: 'text',
        },
        {
          friendlyName: 'Logos',
          name: 'images',
          type: 'list',
          subFields: [
            {
              friendlyName: 'Logo',
              name: 'src',
              type: 'file',
              allowedFileTypes: ['jpeg', 'jpg', 'png'],
            },
            {
              name: 'alt',
              type: 'text',
            },
            {
              name: 'classes',
              type: 'text',
            },
            {
              name: 'containerClasses',
              type: 'text',
            },
          ],
        },
      ],
    },
    {
      name: 'tableRows',
      type: 'list',
      subFields: [
        {
          name: 'title',
          type: 'text',
        },
        {
          name: 'titleClasses',
          type: 'text',
        },
        {
          name: 'features',
          type: 'list',
          subFields: [
            {
              name: 'supports',
              type: 'boolean',
            },
          ],
        },
      ],
    },
  ],
})
