import { ITheBlock } from '@/domain/blockList/contracts/ITheBlock'
import { TIdentifiedBlockPayloadSchemaGuardAction } from '@/domain/blockList/contracts/TIdentifiedBlockPayloadSchemaGuardAction'
import { EBlockType } from '@/domain/blockList/contracts/EBlockType'
import { TTheBlockCheckboxPayload } from '@/domain/blockList/contracts/TTheBlockCheckboxPayload'

export const checkboxBlockPayloadSchemaGuardAction: TIdentifiedBlockPayloadSchemaGuardAction =
  [
    EBlockType.CHECKBOX,
    (block: ITheBlock): Promise<ITheBlock> => {
      block.attributes.payload = {
        value: block.attributes.payload?.value ?? '',
        status: (block.attributes.payload as TTheBlockCheckboxPayload)?.status ?? false,
      } as TTheBlockCheckboxPayload

      return Promise.resolve(block)
    },
  ]
