import type { RouteRecordRaw } from 'vue-router'
import { CContentRouteNames } from '@/domain/Content/contracts/CContentRouteNames'
import { EEnvironment } from '@/app/contracts/EEnvironment'

export const bookRoutes: RouteRecordRaw[] = [
  {
    path: '/books',
    name: CContentRouteNames.books.index,
    component: () => import('@/domain/Content/components/PContent.vue'),
  },
  {
    path: '/books/:slug',
    name: CContentRouteNames.books.show,
    component: () => import('@/domain/Book/components/PBook.vue'),
    props: {
      contentModel: 'book-page',
    },
  },
  {
    path: '/_demo/books/:slug',
    name: CContentRouteNames.demo.books.show,
    meta: {
      excludedEnvironments: [EEnvironment.PRODUCTION],
    },
    component: () => import('@/domain/Book/components/PBook.vue'),
    props: {
      contentModel: 'book-page',
    },
  },
]
