<template>
  <component
    :is="isLoggedIn ? 'div' : 'form'"
    :class="containerClassesComputed"
    @submit.prevent="onRequestLogin"
  >
    <div v-if="!isLoggedIn" :class="inputContainerClassesComputed">
      <input
        id="login-email"
        ref="emailInputRef"
        v-model="email"
        type="email"
        name="email"
        :placeholder="props.inputPlaceholder"
        :class="inputClassesComputed"
        :disabled="isRequestRunning"
        @invalid.prevent="() => ({})"
      />
    </div>
    <button
      v-if="!isLoggedIn"
      id="submit-email"
      ref="submitButton"
      type="submit"
      aria-label="Submit user email"
      :class="actionClassesComputed"
    >
      <span
        :class="actionTitleClassesComputed"
        v-html="hasRequestFailed ? props.actionFailedSubmissionTitle : props.actionTitle"
      />
      <span class="absolute block" :class="isRequestRunning ? 'visible' : 'invisible'">
        <span
          class="block h-5 w-5 animate-spin rounded-full border-2 border-solid border-white/70 border-t-transparent"
        />
      </span>
    </button>
    <RouterLink
      v-if="isLoggedIn"
      :to="targetRouteLocationComputed"
      :class="actionClassesComputed"
    >
      {{ props.actionTitle }}
    </RouterLink>
  </component>
</template>

<script lang="ts">
export const componentName = 'SignInWithEmailForm'
</script>

<script setup lang="ts">
import { computed, defineOptions, nextTick, onMounted, ref, watch } from 'vue'
import isEmail from 'validator/lib/isEmail'

import { useTippy } from 'vue-tippy'
import { EAuthenticationProviders } from '@/domain/Authentication/contracts/EAuthenticationProviders'
import { useAuthentication } from '@/domain/Authentication/composables/useAuthentication'
import { EMagicSignInStages } from '@/domain/Authentication/contracts/EMagicSignInStages'
import { EAuthenticationProviderSignInFlowStates } from '@/domain/Authentication/contracts/EAuthenticationProviderSignInFlowStates'
import { useUrlSearchParams } from '@vueuse/core'
import type { TAutoSignInWithEmailQueryParams } from '@/domain/Authentication/contracts/TAutoSignInWithEmailQueryParams'
import { ESignInWithMagicUriFormUsageContext } from '@/domain/Authentication/contracts/ESignInWithMagicUriFormUsageContext'
import { useRouter } from 'vue-router'
import { twMerge } from 'tailwind-merge'
import { cva } from 'class-variance-authority'
import { captureWithUser } from '@/app/support/usePosthog'
import { EUserEvents } from '@/app/contracts/EUserEvents'
import { ESignInContentTypes } from '@/domain/Authentication/contracts/ESignInContentTypes'
import { useUserStore } from '@/app/services/useUserStore'
import { useRouteLocation } from '@/app/composables/useRouteLocation'
import { useOnePageEntryWithOptions } from '@/app/composables/useOnePageEntry'
import { toTemplateUrl } from '@/domain/Book/support/toTemplateUrl'
import type { TUsePageContentProps } from '@/domain/Book/contracts/TUsePageContentProps'
import { getSharingInformation } from '@/domain/cards/services/cardClient'
import { Environment } from '@/app/support/Environment'

defineOptions({
  name: componentName,
})

const props = withDefaults(
  defineProps<
    {
      usageContext?: ESignInWithMagicUriFormUsageContext
      containerClasses?: string
      inputPlaceholder?: string
      inputContainerClasses?: string
      inputClasses?: string

      actionClasses?: string
      actionTitle?: string
      actionFailedSubmissionTitle?: string
      actionTitleClasses?: string
      actionTarget?: string
      classes?: {
        container?: string
      }
    } & TUsePageContentProps & {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        attributes?: any
      }
  >(),
  {
    usageContext: ESignInWithMagicUriFormUsageContext.SIGN_IN_PAGE,
    containerClasses: undefined,

    inputPlaceholder: 'Email: you@example.com',
    inputContainerClasses: undefined,
    inputClasses: undefined,

    actionClasses: undefined,
    actionTitle: 'Sign in with email',
    actionFailedSubmissionTitle: 'Retry',
    actionTitleClasses: undefined,
    usePageContent: false,
    pageContentModel: 'page',
    classes: () => ({
      container: undefined,
    }),
  },
)

const containerClassesComputed = computed(() =>
  twMerge(
    cva('flex flex-col space-y-2 whitespace-nowrap', {
      variants: {
        usageContext: {
          [ESignInWithMagicUriFormUsageContext.SIGN_IN_PAGE]: 'space-y-4',
          [ESignInWithMagicUriFormUsageContext.STAND_ALONE]:
            'w-full xs:max-w-[none] md:max-w-[320px] lg:max-w-[none] xs:flex-row md:flex-col lg:flex-row space-y-2 xs:space-y-[auto] xs:space-x-4 md:space-y-2 md:space-x-0 lg:space-y-[auto] lg:space-x-2  mt-6',
        },
      },
    })({
      usageContext: props.usageContext,
      class: [props.containerClasses, props?.classes?.container, componentName],
    }),
  ),
)

const inputContainerClassesComputed = computed(() =>
  twMerge(
    cva('flex space-y-2 flex-col items-start h-[40px] lg:h-full', {
      variants: {
        usageContext: {
          [ESignInWithMagicUriFormUsageContext.SIGN_IN_PAGE]: undefined,
          [ESignInWithMagicUriFormUsageContext.STAND_ALONE]: 'grow lg:max-w-[256px]',
        },
      },
    })({
      usageContext: props.usageContext,
      class: [props.inputContainerClasses],
    }),
  ),
)

const inputClassesComputed = computed(() =>
  twMerge(
    cva(
      'flex w-full h-full flex-1 border border-base-300 rounded px-3 text-base lg:text-sm placeholder-base-400 shadow-sm focus:border-state-error focus:ring-0',
      {
        variants: {
          usageContext: {
            [ESignInWithMagicUriFormUsageContext.SIGN_IN_PAGE]: undefined,
            [ESignInWithMagicUriFormUsageContext.STAND_ALONE]: 'text-center lg:text-left',
          },
          isError: {
            true: 'border-state-error',
            // false: '',
          },
        },
      },
    )({
      usageContext: props.usageContext,
      isError: Boolean(!isFresh.value && errorMessage.value?.length),
      class: [props.inputClasses],
    }),
  ),
)

const actionClassesComputed = computed(() =>
  twMerge(
    cva(
      'flex h-[40px] lg:h-full items-center justify-center rounded bg-state-error px-3.5 py-2 text-base lg:text-sm font-medium text-base-100 shadow-sm hover:bg-state-error-focus',
      {
        variants: {
          usageContext: {
            [ESignInWithMagicUriFormUsageContext.SIGN_IN_PAGE]: 'mt-6',
            [ESignInWithMagicUriFormUsageContext.STAND_ALONE]:
              'sm:min-w-[200px] md:min-w-0',
          },
          submissionFailed: {
            true: 'border text-state-error bg-state-error-content border-state-error hover:text-state-error-focus hover:border-state-error-focus hover:bg-state-error-content',
          },
          hasError: {
            true: 'cursor-not-allowed opacity-30',
          },
          inProgress: {
            true: 'cursor-progress opacity-50',
          },
        },
      },
    )({
      usageContext: props.usageContext,
      submissionFailed: hasRequestFailed.value,
      hasError: !((isFresh.value && isEmailValid.value) || isEmailValid.value),
      inProgress: isRequestRunning.value,
      class: [props.actionClasses],
    }),
  ),
)

const actionTitleClassesComputed = computed(() =>
  twMerge(
    cva('', {
      variants: {
        usageContext: {
          [ESignInWithMagicUriFormUsageContext.SIGN_IN_PAGE]: undefined,
          [ESignInWithMagicUriFormUsageContext.STAND_ALONE]: undefined,
        },
        inProgress: {
          true: 'invisible',
        },
      },
    })({
      usageContext: props.usageContext,
      inProgress: isRequestRunning.value,
      class: [props.actionTitleClasses],
    }),
  ),
)

const emailInputRef = ref()
const isFresh = ref<boolean>(true)
const errorMessage = ref<string>('')
const email = ref<string>('')
const isRequestRunning = computed(
  () =>
    useAuthentication().selectedProvider.value ===
      EAuthenticationProviders.MAGIC_SIGN_IN &&
    useAuthentication().status.value === EAuthenticationProviderSignInFlowStates.STARTED,
)
const hasRequestFailed = computed(
  () =>
    useAuthentication().selectedProvider.value ===
      EAuthenticationProviders.MAGIC_SIGN_IN &&
    useAuthentication().status.value === EAuthenticationProviderSignInFlowStates.FAILED,
)

const validateEmail = (email: string | undefined) => {
  if (!email || !email.length) {
    return false
  }

  if (!email || !isEmail(email)) {
    return false
  }

  errorMessage.value = ''
  return true
}

const isEmailValid = ref<boolean>(true)

watch(email, (newEmailValue, oldEmailValue) => {
  if (newEmailValue && newEmailValue?.length > 0 && oldEmailValue?.length === 0) {
    isFresh.value = false
  }

  if (newEmailValue?.length !== oldEmailValue?.length) {
    isEmailValid.value = validateEmail(newEmailValue)
  }
})

watch(isEmailValid, (newEmailValue) => {
  if (newEmailValue) {
    errorMessage.value = ''
    hideTippy()
    return
  }

  if (!email.value?.length) {
    errorMessage.value = 'Email is required'
  }

  if (!email.value || !isEmail(email.value)) {
    errorMessage.value = 'Please enter a valid email address.'
  }
})

const {
  show: showTippy,
  hide: hideTippy,
  setContent: setTippyContent,
} = useTippy(emailInputRef, {
  theme: 'signIn',
  trigger: 'manual',
  hideOnClick: false,
})

const { currentRoute, push: routerPush, resolve: routerResolve } = useRouter()
const { isLoggedIn } = useUserStore()

const { content: page, doFetch } = useOnePageEntryWithOptions(props.pageContentModel, {
  useAutoFetch: false,
  useEnvironment: Environment.current(),
})

if (props.usePageContent) {
  doFetch({})
}

const targetRouteLocationComputed = computed(() => {
  const actionTarget = props.usePageContent ? toTemplateUrl(page) : props.actionTarget

  return useRouteLocation(routerResolve).fromUrlString(actionTarget as string, {
    ...currentRoute.value.query,
  })
})

const onRequestLogin = async () => {
  if (!validateEmail(email.value)) {
    isEmailValid.value = false
    return
  }

  hideTippy()

  if (props.usageContext === ESignInWithMagicUriFormUsageContext.STAND_ALONE) {
    const autoSignInQueryParams: TAutoSignInWithEmailQueryParams = {
      email: email.value,
      submit: 'true',
    }

    const routeLocation = targetRouteLocationComputed.value

    routeLocation.query = {
      ...routeLocation.query,
      ...autoSignInQueryParams,
      ...currentRoute.value.query,
    }

    if (routeLocation?.params?.shareId) {
      const information = await getSharingInformation(
        routeLocation.params.shareId as string,
      )

      if (information?.data?.attributes?.documentTitle) {
        routeLocation.query['case_title'] = information?.data?.attributes?.documentTitle
      }

      if (information?.data?.attributes?.documentSnapshotId) {
        routeLocation.query['case_snapshot_id'] =
          information?.data?.attributes?.documentSnapshotId
      }
    }

    const posthogProperties = {
      user_email: routeLocation.query?.email ?? 'undefined',
      case_title: routeLocation.query?.case_title ?? 'undefined',
      case_type: ESignInContentTypes.case,
      route: currentRoute.value.name,
      route_query: {
        ...routeLocation.query,
      },
      route_params: {
        ...currentRoute.value.params,
        ...routeLocation.params,
      },
    }

    if (routeLocation.query?.email) {
      posthogProperties['$set'] = { email: routeLocation.query.email }
    }

    captureWithUser(EUserEvents.triggeredCta, posthogProperties)

    await useAuthentication().signInWith(EAuthenticationProviders.MAGIC_SIGN_IN, {
      stage: EMagicSignInStages.REQUEST_SIGN_IN_URI,
      secret: email.value as string,
      relatedCopyId: routeLocation.params?.shareId as string | undefined,
    })

    await nextTick(async () => {
      await routerPush(routeLocation)
    })

    return
  }

  await useAuthentication().signInWith(EAuthenticationProviders.MAGIC_SIGN_IN, {
    stage: EMagicSignInStages.REQUEST_SIGN_IN_URI,
    secret: email.value as string,
  })

  await nextTick(() => {
    email.value = ''
  })
}

watch(errorMessage, (newErrorMessage) => {
  if (!newErrorMessage) {
    return
  }

  setTippyContent(newErrorMessage)
  if (newErrorMessage.length === 0) {
    hideTippy()
  } else {
    showTippy()
  }
})

onMounted(() => {
  if (props.usageContext === ESignInWithMagicUriFormUsageContext.SIGN_IN_PAGE) {
    const params = useUrlSearchParams<TAutoSignInWithEmailQueryParams>('history')
    if (params.email && params?.submit === 'true') {
      email.value = params.email
      // onRequestLogin()
      params.submit = 'false'
    }
  }
})
</script>
<style lang="postcss">
.tippy-box[data-theme~='signIn'] {
  @apply relative rounded-md border border-state-error text-state-error;
  box-shadow:
    0 0 20px 4px rgba(154, 161, 177, 0.15),
    0 4px 80px -8px rgba(36, 40, 47, 0.25),
    0 4px 4px -2px rgba(91, 94, 105, 0.15);
  background-color: #fff;
}

.tippy-box[data-theme~='signIn'][data-placement^='top'] > .tippy-arrow:before {
  @apply border-t-state-error;
}

.tippy-box[data-theme~='signIn'] .tippy-arrow {
  @apply -z-10;
}

.tippy-box[data-theme~='signIn'][data-placement^='bottom'] > .tippy-arrow:before {
  @apply border-b-state-error;
}

.tippy-box[data-theme~='signIn'][data-placement^='left'] > .tippy-arrow:before {
  @apply border-l-state-error;
}

.tippy-box[data-theme~='signIn'][data-placement^='right'] > .tippy-arrow:before {
  @apply border-r-state-error;
}

.tippy-box[data-theme~='signIn'] > .tippy-backdrop {
  @apply bg-state-error-content;
}

.tippy-box[data-theme~='signIn'] > .tippy-svg-arrow {
  fill: #ff364f;
}
</style>
