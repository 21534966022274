import { storeToRefs } from 'pinia'
import { readonly, ref } from 'vue'
import { useCardsStore } from '@/domain/cards/services/useCardsStore'
import { type CardTypeProps } from '@/domain/cards/support/interfaces'
import { createGlobalState } from '@vueuse/core'
import { defineGlobalStore } from '@/app/support/defineGlobalStore'
import router from '@/app/services/router'
import { CRouteNames } from '@/app/contracts/CRouteNames'
import { useCardNavigation } from '@/domain/cardExpanded/services/useCardNavigation'
import type { Card } from '@/types'

export const useUserLocationStore = defineGlobalStore(
  'userLocationStore',
  createGlobalState(() => {
    const viewId = ref('')
    const parentCard = ref<Card>(null)
    const position = ref('')
    const type = ref<CardTypeProps>('topic')

    const cardsStore = useCardsStore()
    const { topic, parents } = storeToRefs(cardsStore)

    const reset = () => {
      viewId.value = ''
      position.value = ''
      type.value = 'topic'
      parentCard.value = null
    }

    const setView = async (id: string) => {
      if (topic.value.id === id) {
        reset()
        return
      }

      viewId.value = id
      position.value = cardsStore.findCardEnumeration(id)
      parentCard.value = cardsStore.getCardParent(id)
      type.value =
        parents.value.findIndex((data) => data.id === id) > -1 ? 'parent' : 'child'
      await cardsStore.fetchChildrenOf(parentCard.value)
      await cardsStore.fetchChildrenOfCurrentContext()
      useCardNavigation().init()
    }

    const navigateToContext = async (id: string) => {
      if (topic.value.id === id) {
        await router.push({
          name: CRouteNames.topic,
          params: {
            topicId: topic.value.id,
          },
        })
        return
      }

      await router.push({
        name: CRouteNames.topicWithContext,
        params: {
          topicId: topic.value.id,
          contextId: id,
        },
      })
    }

    return {
      viewId: readonly(viewId),
      position: readonly(position),
      type: readonly(type),
      parentCard,
      setView,
      reset,
      navigateToContext,
    }
  }),
)
