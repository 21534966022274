import { ref } from 'vue'
import { useXOAuthStore } from '@/domain/Authentication/composables/useXOAuthStore'
import { resolvePopupDimensionAndPosition } from '@/domain/Authentication/support/resolvePopupDimensionAndPosition'

export const useXPopupWindow = (redirectUri: string, errorHandler: () => void) => {
  const popupRef = ref<Window | null>()
  const popUpIntervalRef = ref<number>()

  const open = () => {
    popupRef.value?.close()
    popupRef.value = window.open(
      redirectUri,
      '_blank',
      resolvePopupDimensionAndPosition({ width: 600, height: 720 }),
    )

    if (popUpIntervalRef.value) {
      window.clearInterval(popUpIntervalRef.value)
      popUpIntervalRef.value = undefined
    }

    popUpIntervalRef.value = window.setInterval(() => {
      try {
        if (popupRef.value && popupRef.value.closed) {
          window.clearInterval(popUpIntervalRef.value)
          popUpIntervalRef.value = undefined
          if (
            !useXOAuthStore().state.value.oauthToken &&
            !useXOAuthStore().state.value.oauthVerifier
          ) {
            errorHandler()
          }
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        window.clearInterval(popUpIntervalRef.value)
        popUpIntervalRef.value = undefined
      }
    }, 1000)
  }

  return {
    open,
  }
}
