enum PreferHeaderTypes {
  auth = 'Auth',
  users = 'Users',
  blocks = 'Blocks',
  blockTypes = 'BlockTypes',
  cards = 'Cards',
  metaData = 'MetaData',
  metaDataTypes = 'MetaDataTypes',
  shareLinks = 'ShareLinks',
}

const getPreferHeaderFromLocalStorage = (headerType) => {
  return localStorage.getItem(`Prefer_Header_${PreferHeaderTypes[headerType]}`)
}

export const setPreferHeader = (requestConfig, headerType) => {
  // eslint-disable-next-line no-constant-condition
  if (import.meta.env.DEV === true) {
    const preferHeader = getPreferHeaderFromLocalStorage(headerType)

    if (preferHeader) {
      // eslint-disable-next-line no-console
      console.log(
        '👻 Merging testing "Prefer" header from local storage into request => ',
        'Prefer: ' + preferHeader + ';',
        'Header-type: ' + headerType,
      )
      requestConfig['headers'] = {
        ...requestConfig['headers'],
        Prefer: preferHeader,
      }
    }
  }

  return requestConfig
}
