<template>
  <svg
    class="h-[28px] md:h-[32px] text-state-error"
    width="55"
    height="24"
    viewBox="0 0 55 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 5.84999H4.12247L4.04555 8.54569C4.59801 6.67971 6.01064 5.54541 8.04917 5.54541C10.0877 5.54541 11.5982 6.6552 12.2556 8.52118C12.836 6.6552 14.2941 5.54541 16.3851 5.54541C19.0775 5.54541 20.8922 7.53743 20.8922 10.5622V18.5268H17.7697V11.1924C17.7697 9.22487 16.8641 8.06607 15.3011 8.06607C13.5878 8.06607 12.6087 9.40341 12.6087 11.7455V18.5268H9.48627V11.1924C9.48627 9.22487 8.55617 8.06607 6.91976 8.06607C5.13299 8.06607 4.12597 9.40341 4.12597 11.7455V18.5268H1V5.84999Z"
    />
    <path
      d="M24.9169 0C26.0743 0 26.9554 0.88223 26.9554 2.01652C26.9554 3.15082 26.0743 4.05756 24.9169 4.05756C23.7595 4.05756 22.9028 3.17533 22.9028 2.01652C22.9028 0.857723 23.784 0 24.9169 0ZM23.3574 5.85002H26.4799V18.5303H23.3574V5.85002Z"
    />
    <path
      d="M28.9448 14.5709V0.882324H32.0673V13.9407C32.0673 15.6562 32.924 16.1323 34.41 15.7297L34.4345 18.3764C31.6652 19.0836 28.9448 18.4534 28.9448 14.5709Z"
    />
    <path
      d="M36.5221 18.6318V5.85002H39.6445V18.6318C39.6445 23.3441 36.1689 24.2788 33.3262 23.3966L33.3751 20.8514C34.6094 21.254 36.5221 21.331 36.5221 18.6318ZM38.0816 0C39.2389 0 40.1201 0.882229 40.1201 2.01652C40.1201 3.15082 39.2389 4.05756 38.0816 4.05756C36.9242 4.05756 36.0675 3.17533 36.0675 2.01652C36.0675 0.857723 36.9487 0 38.0816 0Z"
    />
    <path
      d="M42.2109 5.84999H45.3334L45.2565 8.54569C45.8089 6.67971 47.2216 5.54541 49.2601 5.54541C52.0049 5.54541 53.8442 7.58644 53.8442 10.7128V18.5268H50.7217V11.1924C50.7217 9.22487 49.7916 8.06607 48.1272 8.06607C46.3404 8.06607 45.3334 9.40341 45.3334 11.7455V18.5268H42.2109V5.84999Z"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const componentName = 'MainNavigationLogo'
export default defineComponent({
  name: componentName,
})
</script>

<script lang="ts" setup></script>

<style scoped></style>
