import { Ref } from 'vue'
import type { BuilderContent } from '@builder.io/sdk-vue/lib/node/types/builder-content'
import type { TAuthorModel } from '@/domain/Book/contracts/TAuthorModel'
import { toAuthor } from '@/domain/Book/support/toAuthor'

export const toAuthors = (
  page: Readonly<Ref<BuilderContent | null | undefined>>,
): TAuthorModel[] => {
  if ((page?.value?.data?.authors ?? []).length === 0) {
    return []
  }

  return (page?.value?.data?.authors ?? [])
    .map(toAuthor)
    .filter((author: TAuthorModel | undefined) => author)
}
