export const getInitials = (content?: string) => {
  if (!content) {
    return undefined
  }

  return content
    .replace(/\s+/g, ' ')
    .split(' ')
    .map((n) => n[0])
    .filter((a, b, c) => {
      if (c[0] === a) {
        return true // first
      }

      const lastCharacterIndex = c.length - 1
      return lastCharacterIndex > 0 && c[lastCharacterIndex] === a
    })
    .join('')
}
