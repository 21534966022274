<template>
  <SingleNotificationStack />
  <RouterView :key="$route.path"></RouterView>
  <FeedbackButton />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'App',
})
</script>

<script setup lang="ts">
import { type RouteLocationNormalizedLoaded, RouterView } from 'vue-router'
import SingleNotificationStack from '@/domain/notifications/components/ExposedNotificationDialog.vue'
import { FeedbackButton } from '@/domain/FeedbackWidget'
import { useFeedbackWidget } from '@/domain/FeedbackWidget/composables/useFeedbackWidget'
import type { TActivityTrackerWithCurrentRoutePayload } from '@/domain/FeedbackWidget/contracts/TActivityTrackerWithCurrentRoutePayload'
import { Ref } from 'vue'
import { EFeedbackWidgetActivationSource } from '@/domain/FeedbackWidget/contracts/EFeedbackWidgetActivationSource'
import { captureWithUser } from '@/app/support/usePosthog'
import { EFeedbackWidgetEvents } from '@/domain/FeedbackWidget/contracts/EFeedbackWidgetEvents'
import { useConfig } from '@/app/services/useConfig'

const { init } = useFeedbackWidget()

const trackActivity: TActivityTrackerWithCurrentRoutePayload = (
  currentRoute: Ref<RouteLocationNormalizedLoaded>,
  activationSource: EFeedbackWidgetActivationSource = EFeedbackWidgetActivationSource.unknown,
) => {
  captureWithUser(EFeedbackWidgetEvents.widgetActivated, {
    activation_source: activationSource,
    route: currentRoute.value.name,
  })
}
init(useConfig().get().yBug, trackActivity)
</script>
