import { onMounted, onUnmounted, readonly, ref, toRaw } from 'vue'
import { EFetchState } from '@/domain/Book/contracts/EFetchState'
import { builderIoClientFactory } from '@/domain/Book/support/builderIoClientFactory'
import type { WretchError } from 'wretch'
import type { TRequestError } from '@/domain/Book/contracts/TRequestError'
import { toRequestErrors } from '@/domain/Book/support/toRequestErrors'
import type { TCategory } from '@/domain/Book/contracts/TCategory'

export const MODEL = 'document-categories'
export const MODEL_ID = '5ee88727ab2e41f39b23d6f1f5ff7d72'

const _categories = ref<TCategory[]>([])
const _fetchState = ref<EFetchState>(EFetchState.IDLE)
const _errors = ref<{ errors: TRequestError[] }>()

export const useCategories = (options = { useAutoFetch: true }) => {
  const client = builderIoClientFactory().query({
    fields: ['id', 'modelId', 'data.label', 'data.slug'].join(','),
  })

  const _doFetch = async (only?: string[]) => {
    try {
      _fetchState.value = EFetchState.FETCHING

      _categories.value = (
        await client
          .query(
            only
              ? {
                  'query.id.$in': ['', ...only], // hint `''` is required when missing client does not use array syntax
                }
              : {},
          )
          .get(`/${MODEL}`)
      ).map((item: TCategory) => ({
        ...item,
        type: item.type === MODEL_ID ? MODEL : MODEL_ID,
      }))

      _fetchState.value = EFetchState.SUCCEEDED
    } catch (error: WretchError | unknown) {
      _fetchState.value = EFetchState.FAILED
      _errors.value = toRequestErrors(error as WretchError)
      // eslint-disable-next-line no-console
      console.error(toRaw(_errors.value))
    }
  }

  onMounted(async () => {
    if (!options?.useAutoFetch) {
      return Promise.resolve()
    }

    await _doFetch()
  })

  onUnmounted(() => {
    _fetchState.value = EFetchState.IDLE
    _categories.value = []
    _errors.value = undefined
  })

  return {
    fetchState: readonly(_fetchState),
    errors: readonly(_errors),
    categories: readonly(_categories),
    fetch: _doFetch,
  }
}
