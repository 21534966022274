// eslint-disable-next-line @typescript-eslint/no-explicit-any
import type { Slot } from 'vue'
import { isVnodeEmpty } from '@/app/support/isVnodeEmpty'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const hasSlotContent = (slot: Slot<any> | undefined) => {
  if (!slot) {
    return false
  }

  return !isVnodeEmpty(slot())
}
