<template>
  <section class="flex justify-center text-center" :class="componentName">
    <div
      class="flex flex-col items-center min-w-xs w-full max-w-7xl px-4 pt-[16vh] md:pt-[20vh]"
    >
      <header class="flex flex-col font-extrabold text-center">
        <h1 class="text-7xl lg:text-8xl 2xl:text-9xl text-state-error leading-none">
          {{ props.code }}
        </h1>
        <p
          v-if="props.title"
          class="mt-4 text-2xl md:text-4xl font-extrabold leading-9 text-base-content"
        >
          {{ props.title }}
        </p>
      </header>
      <div class="flex flex-col mt-4">
        <p
          v-if="props.description"
          class="text-base md:text-lg leading-6 text-center text-base-500"
          v-html="props.description"
        />
        <RouterLink
          v-if="props?.action?.title && props?.action?.target"
          :to="action?.target ?? '/'"
          :replace="props.replace"
          class="justify-center self-center px-5 py-2.5 mt-6 text-sm font-medium leading-5 text-state-error-content bg-state-error hover:bg-state-error-focus rounded-lg"
        >
          {{ props?.action.title }}
        </RouterLink>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const componentName = 'THttpErrorComponent'
export default defineComponent({
  name: componentName,
})
</script>

<script lang="ts" setup>
import type { THttpErrorComponentProps } from '@/app/contracts/THttpErrorComponentProps'

const props = withDefaults(defineProps<THttpErrorComponentProps>(), {
  title: `Something's missing.`,
  code: '404',
  description: `Sorry, we can't find that page. You'll find lots to explore on the home page.`,
  replace: true,
})
</script>

<style scoped></style>
