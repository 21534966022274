<template>
  <component
    :is="props.tag"
    :id="idAttribute"
    :class="container({ visualizeWidth, class: props.containerClasses })"
  >
    <div
      :class="content({ visualizeWidth, width, class: props.contentContainerClasses })"
    >
      <slot />
    </div>
  </component>
</template>

<script lang="ts">
export const componentName = 'FlexibleSectionLayout'
</script>

<script lang="ts" setup>
import { defineOptions } from 'vue'
import { cva, type VariantProps } from 'class-variance-authority'

defineOptions({
  name: componentName,
})

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type TContainerProps = VariantProps<typeof container>
const container = cva(['flex', 'w-full', componentName], {
  variants: {
    intent: {
      default: 'justify-center',
    },
    visualizeWidth: {
      true: ['border-2', 'border-blue-400'],
    },
  },
  defaultVariants: {
    intent: 'default',
  },
})

type TContentProps = VariantProps<typeof content>
const content = cva(['flex', 'w-full', 'px-4'], {
  variants: {
    intent: {
      default: 'justify-center',
    },
    width: {
      narrow: ['min-w-xs', 'max-w-7xl'],
      xNarrow: ['min-w-xs', 'max-w-5xl'],
      wide: [],
    },
    visualizeWidth: {
      true: ['border-2', 'border-pink-400'],
    },
  },
  defaultVariants: {
    intent: 'default',
    width: 'wide',
  },
})

const props = withDefaults(
  defineProps<
    {
      idAttribute?: string
      tag?: string
      width: TContentProps['width']
      visualizeWidth?: TContentProps['visualizeWidth']
      containerClasses?: string
      contentContainerClasses?: string
      // eslint-disable-next-line
    } & { attributes?: any }
  >(),
  {
    idAttribute: undefined,
    tag: 'section',
    width: 'wide',
  },
)
</script>

<style scoped></style>
