<template>
  <section class="flex justify-center text-center mt-4 lg:mt-8" :class="componentName">
    <div class="flex flex-col items-center min-w-xs w-full max-w-7xl">
      <h1
        class="w-full text-3xl !mb-4 lg:text-4xl font-extrabold text-base-content max-w-2xl lg:max-w-3xl leading-none"
      >
        {{ props.title }}
      </h1>
      <p
        v-if="props.showSubline && props.subline"
        class="mt-0 mb-3 md:mb-4 text-base lg:text-xl text-base-400 max-md:max-w-full max-w-2xl lg:max-w-3xl"
        v-html="props.subline"
      />
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const componentName = 'TitleWithSubline'

export default defineComponent({
  name: componentName,
})
</script>

<script lang="ts" setup>
import type { TTitleWithSublineProps } from '@/app/contracts/TTitleWithSublineProps'

const props = withDefaults(
  defineProps<
    TTitleWithSublineProps & {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      attributes?: any
    }
  >(),
  {
    subline: undefined,
    showSubline: false,
    attributes: undefined,
  },
)
</script>

<style scoped></style>
