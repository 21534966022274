<template>
  <div
    v-if="!isEmpty(props.title)"
    class="flex flex-col p-6 mx-auto max-w-sm text-center text-base-content bg-base-100 rounded-lg border border-base-200 shadow-xl xl:p-8"
    :class="[componentName]"
  >
    <div class="flex flex-col items-center justify-start min-h-[108px]">
      <h3 class="mb-4 text-3xl grow-0 font-semibold" v-html="props.title" />
      <p
        v-if="!isEmpty(props.shortDescription)"
        class="font-light text-lg grow text-base-400"
        v-html="props.shortDescription"
      />
    </div>

    <hr class="my-6" />

    <ul role="list" class="mb-8 space-y-4 text-left grow">
      <li
        v-for="benefit in props?.benefits ?? []"
        :key="benefit.text"
        class="flex items-center space-x-3"
      >
        <span v-html="benefit.text" />
      </li>
    </ul>

    <div
      v-if="!isEmpty(props.actionTarget) && !isEmpty(props.actionTitle)"
      class="flex flex-col mt-6"
    >
      <a
        :href="props.actionTarget"
        class="font-medium rounded-3xl text px-5 py-2.5 text-center"
        :class="actionClasses"
      >
        {{ props.actionTitle }}
      </a>
      <div class="flex justify-center items-baseline mt-4 mb-2">
        <span class="mr-2 text-xl text-base-500 font-semibold">
          {{ props.pricing }}
        </span>
        <span v-if="props.pricingInterval" class="text-base-500">/</span>
        <span v-if="props.pricingInterval" class="text-base-500">
          {{ props.pricingInterval }}
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const componentName = 'PricingTableCard'
export default defineComponent({
  name: componentName,
})
</script>

<script lang="ts" setup>
import { isEmpty } from 'lodash-es'
import type { TPricingTableCard } from '@/domain/Content/contracts/TPricingTableCard'
import { computed } from 'vue'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const props = withDefaults(defineProps<TPricingTableCard & { attributes?: any }>(), {
  shortDescription: undefined,
  pricing: undefined,
  pricingInterval: undefined,
  benefits: () => [],
  actionTitle: undefined,
  actionTarget: undefined,
  actionPriority: 'secondary',
  posthogEvent: undefined,
})

const actionClasses = computed(() => {
  return props.actionPriority === 'primary'
    ? ['text-state-error-content', 'bg-state-error', 'hover:bg-state-error-focus']
    : [
        'text-state-error',
        'border',
        'border-state-error',
        'hover:text-state-error-content',
        'hover:bg-state-error-focus',
        'hover:border-state-error-focus',
      ]
})
</script>

<style scoped></style>
