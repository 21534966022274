import { onMounted, onUnmounted, readonly, ref, toRaw } from 'vue'
import { EFetchState } from '@/domain/Book/contracts/EFetchState'
import { builderIoClientFactory } from '@/domain/Book/support/builderIoClientFactory'
import type { WretchError } from 'wretch'
import type { TRequestError } from '@/domain/Book/contracts/TRequestError'
import { toRequestErrors } from '@/domain/Book/support/toRequestErrors'
import type { TAuthorReferenceModel } from '@/domain/Book/contracts/TAuthorReferenceModel'
import type { TAuthor } from '@/domain/Book/contracts/TAuthor'

export const MODEL = 'author'
export const MODEL_ID = '8ab018fcc5d74d5cb29c5ac47336ba1f'

const _authors = ref<TAuthor[]>([])
const _fetchState = ref<EFetchState>(EFetchState.IDLE)
const _errors = ref<{ errors: TRequestError[] }>()

export const useAuthors = (options = { useAutoFetch: true }) => {
  const client = builderIoClientFactory().query({
    fields: [
      'id',
      'modelId',
      'data.name',
      'data.slug',
      'data.role',
      'data.description',
      'data.image',
      'data.references',
    ].join(','),
  })

  const _doFetch = async (only?: string[]) => {
    try {
      _fetchState.value = EFetchState.FETCHING

      _authors.value = (
        await client
          .query(
            only
              ? {
                  'query.id.$in': ['', ...only], // hint `''` is required when missing client does not use array syntax
                }
              : {},
          )
          .get(`/${MODEL}`)
      ).map((item: TAuthor) => ({
        id: item.id,
        type: item.type === MODEL_ID ? MODEL : MODEL_ID,
        attributes: {
          name: item?.attributes?.name ?? undefined,
          slug: item?.attributes?.slug ?? undefined,
          role: item.attributes?.role ?? undefined,
          description: item?.attributes?.description ?? undefined,
          image: item?.attributes?.image ?? undefined,
          references: item?.attributes?.references ?? ([] as TAuthorReferenceModel[]),
        },
      }))

      _fetchState.value = EFetchState.SUCCEEDED
    } catch (error: WretchError | unknown) {
      _fetchState.value = EFetchState.FAILED
      _errors.value = toRequestErrors(error as WretchError)
      // eslint-disable-next-line no-console
      console.error(toRaw(_errors.value))
    }
  }

  onMounted(async () => {
    if (options?.useAutoFetch) {
      await _doFetch()
    }
  })

  onUnmounted(() => {
    _fetchState.value = EFetchState.IDLE
    _authors.value = []
    _errors.value = undefined
  })

  return {
    fetchState: readonly(_fetchState),
    errors: readonly(_errors),
    authors: readonly(_authors),
    fetch: _doFetch,
  }
}
