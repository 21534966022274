<template>
  <div
    v-if="true"
    class="flex min-w-[288px] gap-y-8 w-full max-w-[480px] flex-col items-start text-left grow"
    :class="[componentName]"
  >
    <h1
      v-if="props.title"
      class="w-full text-2xl md:text-3xl font-medium leading-10 text-base-content"
      v-html="props?.title"
    />

    <section
      v-if="props.showPreview && props.caseTitle"
      class="flex gap-2 w-full px-6 py-4 text-base-content bg-base-200 rounded-xl text-left"
    >
      <p class="text-xl md:text-2xl font-medium">{{ props.caseTitle }}</p>
    </section>

    <section
      v-for="(item, index) in props?.items ?? []"
      :key="index"
      class="flex items-center gap-4"
    >
      <component :is="item?.icon ?? ''" v-if="item?.icon" class="w-5 h-5" />
      <p
        v-if="item.description"
        class="w-full text-lg font-semibold leading-6 text-base-500"
        v-html="item.description"
      />
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import EditOutlineIcon from '@/domain/Authentication/components/EditOutlineIcon.vue'
import WandMagicSparklesOutlineIcon from '@/domain/Authentication/components/WandMagicSparklesOutlineIcon.vue'
import UsersGroupOutlineIcon from '@/domain/Authentication/components/UsersGroupOutlineIcon.vue'
import RocketOutlineIcon from '@/domain/Authentication/components/RocketOutlineIcon.vue'

export const componentName = 'SignInContent'
export default defineComponent({
  name: componentName,
  components: {
    EditOutlineIcon,
    WandMagicSparklesOutlineIcon,
    UsersGroupOutlineIcon,
    RocketOutlineIcon,
  },
})
</script>

<script lang="ts" setup>
import { ESignInContentTypes } from '@/domain/Authentication/contracts/ESignInContentTypes'
import type { TSignInContent } from '@/domain/Authentication/contracts/TSignInContent'

const props = withDefaults(defineProps<TSignInContent & { caseTitle?: string }>(), {
  type: ESignInContentTypes.default,
  title: undefined,
  signInTitle: undefined,
  caseTitle: undefined,
  showPreview: false,
  items: () => [],
})
</script>

<style scoped></style>
