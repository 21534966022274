import type { RegisteredComponent } from '@builder.io/sdk-vue'
import component from '@/domain/Content/components/IFrameBasedVideoPlayer.vue'

export const IFrameBasedVideoPlayerComponentRegistrations = (
  shouldWrap = true,
): RegisteredComponent => ({
  component: component,
  name: 'IFrameBasedVideoPlayerComponent',
  friendlyName: 'VideoPlayer',
  noWrap: !shouldWrap,
  canHaveChildren: false,
  inputs: [
    {
      friendlyName: 'Video URL',
      name: 'url',
      type: 'url',
      required: true,
    },
    {
      name: 'tag',
      type: 'tag',
      helperText: `The Html tag that should be used to encapsulate the IFrame, defaults to 'div'`,
      defaultValue: 'div',
    },
    {
      friendlyName: 'Container CSS Classes',
      name: 'containerClasses',
      type: 'string',
      defaultValue: '',
    },
    {
      friendlyName: 'Video IFrame CSS Classes',
      name: 'iframeClasses',
      type: 'string',
      defaultValue: 'w-full',
    },
  ],
})
