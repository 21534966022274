import type { RouteLocationNormalized } from 'vue-router'
import type { TExcludedEnvironmentRouteMeta } from '@/app/contracts/TExcludedEnvironmentRouteMeta'
import type { EEnvironment } from '@/app/contracts/EEnvironment'
import { isArray } from 'lodash-es'
import { captureWithUser } from '@/app/support/usePosthog'
import { EUserEvents } from '@/app/contracts/EUserEvents'

export const handleUnsupportedRouteEnvironmentGuard = (
  to: RouteLocationNormalized & { meta?: TExcludedEnvironmentRouteMeta },
  currentEnvironment: EEnvironment,
  newTo: RouteLocationNormalized,
) => {
  if (!to?.meta?.excludedEnvironments) {
    return false
  }

  if (!isArray(to.meta.excludedEnvironments)) {
    return false
  }

  if (!to.meta.excludedEnvironments.includes(currentEnvironment)) {
    return false
  }

  captureWithUser(EUserEvents.triedToVisit, {
    route: to.name,
    route_query: { ...to.query },
    route_params: { ...to.params },
    route_meta: { ...to?.meta },
  })

  return newTo
}
