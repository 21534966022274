import { Card, CardMeta } from '@/types'
import { metaDataTypes } from '@/app/services/metaDataClient'
import { isReactive, isRef, unref } from 'vue'

export const mergeMetaDataWithCardData = (metaData: CardMeta, cardData: Array<Card>) => {
  cardData.every((card) => {
    if (!card.relationships) {
      return false
    }
    card.relationships.metadata.data.forEach((metaDataRelation, index, array) => {
      const metaDatumToMerge = metaData.find(
        (metaDatum) => metaDatum.id === metaDataRelation.id,
      )

      if (metaDatumToMerge) {
        array.splice(index, 1, metaDatumToMerge)
      }
    })

    return true
  })

  return {
    data: cardData,
  }
}

export const resolveMetaDataFromCard = (
  card: Card,
  metaDataType: metaDataTypes,
): CardMeta => {
  const metaDataList = unref(card)?.relationships?.metadata?.data ?? []

  return metaDataList.find((metaData) => {
    return (
      metaData?.relationships?.metadataType?.data?.id &&
      metaData.relationships.metadataType.data.id === metaDataType
    )
  })
}

export const resolveCardStateId = (card: Card) =>
  resolveMetaDataFromCard(card, metaDataTypes.stateId)?.attributes.payload.value

export const getMetaDataValue = (cardMeta: CardMeta): unknown => {
  return unref(cardMeta)?.attributes?.payload.value ?? null
}

export const setMetaDataValue = (cardMeta: CardMeta, value: unknown): CardMeta => {
  if (!cardMeta.attributes?.payload) {
    throw new Error('provided cardMeta does not conform to the structure of a CardMeta')
  }

  if (isRef(cardMeta.attributes.payload)) {
    cardMeta.attributes.payload.value = { value: value }
  }

  if (isReactive(cardMeta.attributes.payload)) {
    cardMeta.attributes.payload = { value: value }
  }

  return cardMeta
}

export const resolveMetaDataByTypeId = (typeId: string, metaData: CardMeta) => {
  return metaData.find(
    (metaDatum) => metaDatum.relationships.metadataType.data.id === typeId,
  )
}

export const resolveChildrenCardsDataByParentIdAsKey = (
  childrenCardsData: Array<Card>,
) => {
  const childrenResolvedByParentId = {}

  childrenCardsData.forEach((parent) =>
    parent.data.forEach((child) => {
      const parentId = child.relationships.parent.data.id

      if (childrenResolvedByParentId[parentId]) {
        childrenResolvedByParentId[parentId].push(child)
        return
      }

      childrenResolvedByParentId[parentId] = [child]
    }),
  )

  return childrenResolvedByParentId
}

export default {
  mergeMetaDataWithCardData,
  resolveMetaDataByTypeId,
  resolveChildrenCardsDataByParentIdAsKey,
}
