<template>
  <component :is="props.tag" :class="container">
    <ProfileAvatarWithFallback
      :name="props.name"
      :image="props.image"
      :class="avatarComputed"
    />

    <div :class="contentContainer">
      <div :class="header">
        <TextHeading
          :title-tag="EHeadline.H3"
          :content="{ title: `${props.name}` }"
          :classes="{
            container: 'mt-0',
            title: authorName,
          }"
        />
        <div :class="referencesContainer">
          <a
            v-for="reference in referencesComputed"
            :key="reference.url"
            :href="reference.url"
            :class="referenceIconContainer"
            :title="reference.name"
          >
            <SocialIcons :size="referenceIcon" :type="reference.type" />
          </a>
        </div>
      </div>

      <p v-if="!isEmpty(props.role)" :class="role" v-html="props.role" />

      <div
        v-if="!isEmpty(props.description)"
        :class="description"
        v-html="props.description"
      />
    </div>
  </component>
</template>

<script lang="ts">
export const componentName = 'AuthorInformation'
</script>

<script lang="ts" setup>
import { computed, defineOptions } from 'vue'
import { defineProps, withDefaults } from 'vue'
import { isEmpty } from 'lodash-es'
import SocialIcons from '@/app/components/SocialIcons.vue'
import ProfileAvatarWithFallback from '@/domain/Content/components/ProfileAvatarWithFallback.vue'
import { EHeadline } from '@/domain/Content/contracts/EHeadline'
import TextHeading from '@/domain/Content/components/TextHeading.vue'
import { twMerge } from 'tailwind-merge'
import { cva } from 'class-variance-authority'
import type { TAuthorReferenceModel } from '@/domain/Book/contracts/TAuthorReferenceModel'
import { EAuthorReferenceType } from '@/domain/Book/contracts/EAuthorReferenceType'

defineOptions({
  name: componentName,
})

const props = withDefaults(
  defineProps<{
    classes?: {
      container?: string
      contentContainer?: string
      avatar?: string
      header?: string
      authorName?: string
      referencesContainer?: string
      referenceIconContainer?: string
      referenceIcon?: string
      role?: string
      description?: string
    }
    tag?: 'div' | 'li'
    name: string
    image?: string
    role?: string
    description?: string
    isTheOnlyAuthor?: boolean
    references?: TAuthorReferenceModel[]
  }>(),
  {
    classes: () => ({
      container: undefined,
      contentContainer: undefined,
      avatar: undefined,
      header: undefined,
      authorName: undefined,
      referencesContainer: undefined,
      referenceIconContainer: undefined,
      referenceIcon: undefined,
      role: undefined,
      description: undefined,
    }),
    tag: 'div',
    image: undefined,
    role: undefined,
    isTheOnlyAuthor: true,
    description: undefined,
    references: () => [],
  },
)

const referencesComputed = computed(() => {
  return props.references.filter(
    (reference: TAuthorReferenceModel) =>
      reference.type !== EAuthorReferenceType.EMAIL && !isEmpty(reference.url),
  )
})

const container = computed(() =>
  twMerge(
    cva('flex flex-row gap-6')({
      class: props.classes?.container,
    }),
  ),
)

const avatarComputed = computed(() =>
  twMerge(
    cva('w-16 h-16 sm:w-24 sm:h-24 border border-base-150')({
      class: props.classes?.avatar,
    }),
  ),
)

const contentContainer = computed(() =>
  twMerge(
    cva('flex flex-col items-start')({
      class: props.classes?.contentContainer,
    }),
  ),
)

const header = computed(() =>
  twMerge(
    cva(
      'w-full flex flex-col sm:flex-row justify-start items-start sm:items-center gap-1 my-2 sm:gap-4 sm:mb-0',
    )({
      class: props.classes?.header,
    }),
  ),
)

const authorName = computed(() =>
  twMerge(
    cva('text-xl font-bold mt-0')({
      class: props.classes?.authorName,
    }),
  ),
)

const referencesContainer = computed(() =>
  twMerge(
    cva('flex items-start gap-2')({
      class: props.classes?.referencesContainer,
    }),
  ),
)

const referenceIconContainer = computed(() =>
  twMerge(
    cva('hover:scale-125 transition-transform duration-300 ease-in-out')({
      class: props.classes.referenceIconContainer,
    }),
  ),
)

const referenceIcon = computed(() =>
  twMerge(cva('w-5 h-5')({ class: props.classes?.referenceIcon })),
)

const role = computed(() =>
  twMerge(cva('w-full text-left')({ class: props.classes?.role })),
)

const description = computed(() =>
  twMerge(
    cva('w-full py-2 text-base text-muted-foreground prose')({
      class: props.classes?.description,
    }),
  ),
)
</script>

<style scoped lang="postcss"></style>
