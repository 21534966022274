import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'

export type NotificationType = 'info' | 'success' | 'error' | 'warning'
export type NotificationAction = {
  text: string
  doAct: () => void
}

export type NotificationProps = {
  id: string
  message: string
  type: NotificationType
  isRead: boolean
  timestamp: string
  action?: NotificationAction
}

type NotificationStoreState = {
  notifications: NotificationProps[]
  exposedNotification: NotificationProps | null
}

type NotificationOptions = {
  isExposedNotification: boolean
  exposureDuration?: number | undefined
}

const notificationStorage = useStorage<NotificationProps[]>('notifications', [])

let timedNotificationExposureRemoval: ReturnType<typeof setTimeout>

export const useNotificationsStore = defineStore('notificationsStore', {
  state: () =>
    ({
      notifications: [],
      exposedNotification: null,
    }) as NotificationStoreState,
  actions: {
    refresh() {
      this.notifications = notificationStorage.value
    },
    add(notification: NotificationProps, options?: NotificationOptions) {
      notificationStorage.value.push(notification)

      if (options?.isExposedNotification) {
        this.exposeOneNotification(notification, options.exposureDuration)
      }
    },
    remove(notification: NotificationProps) {
      const notificationIndex = notificationStorage.value.findIndex(
        (item) => item.id === notification.id,
      )
      if (notificationIndex < 0) {
        return
      }
      notificationStorage.value.splice(notificationIndex, 1)
      this.refresh()
    },
    removeExposedNotification() {
      this.exposedNotification = null
    },
    exposeOneNotification(notification: NotificationProps, exposureDuration?: number) {
      this.exposedNotification = notification
      const removeExposedNotification = this.removeExposedNotification
      clearTimeout(timedNotificationExposureRemoval)
      timedNotificationExposureRemoval = setTimeout(
        () => {
          removeExposedNotification()
        },
        (exposureDuration ?? 10) * 1000,
      )
    },
  },
})
