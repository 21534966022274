import { defineStore } from 'pinia'
import { MaybeElement } from '@vueuse/core'
import { Ref } from 'vue'

type RefStoreProps = {
  notificationRef: Ref<MaybeElement> | null
}

export const useNotificationRefsStore = defineStore('notificationRefsStore', {
  state: () =>
    ({
      notificationRef: null,
    }) as RefStoreProps,

  actions: {
    storeRef(key: keyof RefStoreProps, value: Ref<MaybeElement>) {
      this[key] = value.value
    },
  },
})
