import { createGlobalState, CreateGlobalStateReturn } from '@vueuse/shared'
import { readonly, ref } from 'vue'

export const useGlobalStore = createGlobalState(() => {
  const stores = ref<{
    [key: string]: CreateGlobalStateReturn<any>
  }>({})

  const addStore = (key: string, store: CreateGlobalStateReturn<any>) => {
    stores.value[key] = store
  }

  const detachStore = (key: string): CreateGlobalStateReturn<any> => {
    const storeToBeDetached = stores.value[key]

    delete stores.value[key]

    return storeToBeDetached
  }

  const reset = () => {
    Object.keys(stores.value).forEach((key: string) => {
      delete stores.value[key]
    })
  }

  return {
    stores: readonly(stores),
    addStore,
    detachStore,
    reset,
  }
})
