<template>
  <component :is="tag" v-if="props.url" :class="[containerClasses, componentName]">
    <iframe
      class="aspect-16-9 max-h-screen"
      :class="[props.iframeClasses]"
      :src="url"
      frameborder="0"
      height="100%"
      width="100%"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
    ></iframe>
  </component>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const componentName = 'IFrameBasedVideoPlayer'
export default defineComponent({
  name: componentName,
})
</script>

<script lang="ts" setup>
// @todo apply merge styles pattern
const props = withDefaults(
  defineProps<
    {
      containerClasses?: string
      tag?: string
      iframeClasses?: string
      url: string
    } & {
      // eslint-disable-next-line vue/require-default-prop,@typescript-eslint/no-explicit-any
      attributes?: any
    }
  >(),
  {
    tag: 'div',
    containerClasses: undefined,
    iframeClasses: 'w-full',
  },
)
</script>

<style scoped></style>
