import { TIdentifiedBlockPayloadSchemaGuardAction } from '@/domain/blockList/contracts/TIdentifiedBlockPayloadSchemaGuardAction'
import { EBlockType } from '@/domain/blockList/contracts/EBlockType'
import { ITheBlock } from '@/domain/blockList/contracts/ITheBlock'
import { TTheBlockVideoPayload } from '@/domain/blockList/contracts/TTheBlockVideoPayload'

export const multiProviderVideoBlockPayloadSchemaGuardAction: TIdentifiedBlockPayloadSchemaGuardAction =
  [
    EBlockType.VIDEO,
    (block: ITheBlock): Promise<ITheBlock> => {
      block.attributes.payload = {
        value: block.attributes.payload?.value ?? '',
      } as TTheBlockVideoPayload

      return Promise.resolve(block)
    },
  ]
