import { ref, onMounted, onUnmounted } from 'vue'

export function useIsTouchDevice() {
  const isTouchDevice = ref(false)

  onMounted(() => {
    isTouchDevice.value =
      'ontouchstart' in window ||
      navigator.maxTouchPoints > 0 ||
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      navigator.msMaxTouchPoints > 0
  })

  onUnmounted(() => {
    isTouchDevice.value = false
  })

  return { isTouchDevice }
}
