import { CContentRouteNames } from '@/domain/Content/contracts/CContentRouteNames'
import type { RouteRecordRaw } from 'vue-router'
import { EEnvironment } from '@/app/contracts/EEnvironment'

export const contentRoutes: RouteRecordRaw[] = [
  {
    path: '/:slug',
    name: CContentRouteNames.default,
    component: () => import('@/domain/Content/components/PContent.vue'),
  },
  {
    path: '/_demo/:slug',
    name: CContentRouteNames.demo.default,
    meta: {
      excludedEnvironments: [EEnvironment.PRODUCTION],
    },
    component: () => import('@/domain/Content/components/PContent.vue'),
  },
  {
    path: '/cases/:slug',
    name: CContentRouteNames.cases,
    component: () => import('@/domain/Content/components/PCase.vue'),
  },
  {
    path: '/_demo/cases/:slug',
    name: CContentRouteNames.demo.cases,
    meta: {
      excludedEnvironments: [EEnvironment.PRODUCTION],
    },
    component: () => import('@/domain/Content/components/PCase.vue'),
  },
]
