import type { RegisteredComponent } from '@builder.io/sdk-vue'
import component from '@/domain/Content/components/CaseContentSection.vue'
import { EMediaType } from '@/domain/Content/contracts/EMediaType'

export const CaseContentSectionComponentRegistration = (
  shouldWrap = true,
): RegisteredComponent => ({
  component: component,
  name: 'CaseContentSection',
  friendlyName: 'CaseContentSection',
  noWrap: !shouldWrap,
  inputs: [
    {
      name: 'title',
      type: 'longText',
    },
    {
      name: 'category',
      type: 'longText',
    },
    {
      name: 'description',
      type: 'richText',
    },
    {
      friendlyName: 'Container Classes',
      name: 'containerClasses',
      defaultValue: 'mt-12 mb-6',
      type: 'string',
    },
    {
      name: 'media',
      type: 'object',
      subFields: [
        {
          name: 'type',
          type: 'string',
          helperText: 'Type decides whether Image or Video is selected',
          defaultValue: EMediaType.image,
          enum: Object.values(EMediaType),
        },
        {
          name: 'sourceFile',
          friendlyName: 'Image',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png'],
        },
        {
          name: 'alt',
          friendlyName: 'Image `alt` description',
          type: 'string',
        },
        {
          name: 'sourceUrl',
          friendlyName: 'Video',
          type: 'url,',
        },
        {
          name: 'containerClasses',
          friendlyName: 'Container Classes',
          type: 'longText,',
        },
        {
          name: 'itemClasses',
          friendlyName: 'Item Classes',
          type: 'longText',
        },
      ],
    },
  ],
})
