<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="path-1-inside-1_2935_2770" fill="white">
      <path
        d="M14.5 7.38061e-05C13.7815 0.0022703 13.077 0.198574 12.4609 0.568229C11.8448 0.937883 11.34 1.46715 11 2.10007C11.6592 2.23616 12.2839 2.50495 12.836 2.89006C13.0183 2.61588 13.2656 2.39096 13.5557 2.23528C13.8459 2.07959 14.17 1.99795 14.4992 1.9976C14.8285 1.99726 15.1528 2.07822 15.4433 2.2333C15.7337 2.38838 15.9814 2.61278 16.1644 2.88657C16.3473 3.16037 16.4598 3.4751 16.4918 3.80281C16.5239 4.13053 16.4746 4.46109 16.3482 4.76515C16.2218 5.06922 16.0223 5.33738 15.7674 5.54582C15.5125 5.75427 15.2101 5.89656 14.887 5.96005C15.0314 6.61838 15.0365 7.29962 14.902 7.96004C15.9247 7.86276 16.8704 7.37467 17.5422 6.59745C18.214 5.82022 18.56 4.81379 18.5082 3.78779C18.4564 2.76179 18.0107 1.79534 17.264 1.08975C16.5174 0.384155 15.5273 -0.00617158 14.5 7.38061e-05Z"
      />
      <path
        d="M10 11C9.20887 11 8.43552 10.7654 7.77772 10.3259C7.11992 9.88639 6.60723 9.26168 6.30448 8.53078C6.00173 7.79988 5.92252 6.99561 6.07686 6.21969C6.2312 5.44377 6.61216 4.73104 7.17157 4.17163C7.73098 3.61222 8.44371 3.23126 9.21964 3.07692C9.99556 2.92258 10.7998 3.00179 11.5307 3.30454C12.2616 3.60729 12.8864 4.11998 13.3259 4.77778C13.7654 5.43557 14 6.20893 14 7.00005C14 8.06091 13.5786 9.07832 12.8284 9.82847C12.0783 10.5786 11.0609 11 10 11ZM10 5.00006C9.60444 5.00006 9.21776 5.11735 8.88886 5.33712C8.55996 5.55688 8.30362 5.86923 8.15224 6.23468C8.00087 6.60014 7.96126 7.00227 8.03843 7.39023C8.1156 7.77819 8.30608 8.13455 8.58579 8.41426C8.86549 8.69396 9.22186 8.88444 9.60982 8.96161C9.99778 9.03878 10.3999 8.99918 10.7654 8.8478C11.1308 8.69643 11.4432 8.44008 11.6629 8.11118C11.8827 7.78229 12 7.39561 12 7.00005C12 6.46962 11.7893 5.96091 11.4142 5.58584C11.0391 5.21077 10.5304 5.00006 10 5.00006Z"
      />
      <path
        d="M15 20H5C4.73478 20 4.48043 19.8946 4.29289 19.7071C4.10536 19.5196 4 19.2652 4 19V17C4.00159 15.6744 4.52888 14.4036 5.46622 13.4662C6.40356 12.5289 7.67441 12.0016 9 12H11C12.3256 12.0016 13.5964 12.5289 14.5338 13.4662C15.4711 14.4036 15.9984 15.6744 16 17V19C16 19.2652 15.8946 19.5196 15.7071 19.7071C15.5196 19.8946 15.2652 20 15 20ZM6 18H14V17C14 16.2044 13.6839 15.4413 13.1213 14.8787C12.5587 14.3161 11.7956 14 11 14H9C8.20435 14 7.44129 14.3161 6.87868 14.8787C6.31607 15.4413 6 16.2044 6 17V18Z"
      />
      <path
        d="M5 7.00005C5.00102 6.65076 5.03856 6.30253 5.112 5.96105C4.7889 5.89756 4.48647 5.75527 4.23157 5.54682C3.97667 5.33838 3.77718 5.07022 3.65081 4.76615C3.52444 4.46209 3.4751 4.13153 3.50716 3.80381C3.53923 3.4761 3.65172 3.16137 3.83464 2.88757C4.01756 2.61378 4.26525 2.38938 4.55573 2.2343C4.8462 2.07922 5.17047 1.99826 5.49975 1.9986C5.82903 1.99895 6.15314 2.08059 6.44328 2.23628C6.73343 2.39196 6.98066 2.61688 7.163 2.89106C7.71531 2.50547 8.34033 2.23634 9 2.10007C8.67366 1.4937 8.19587 0.982159 7.61315 0.615256C7.03043 0.248353 6.36263 0.0385879 5.67478 0.00638339C4.98692 -0.0258212 4.30245 0.120632 3.688 0.431485C3.07354 0.742338 2.55005 1.207 2.16849 1.78022C1.78692 2.35345 1.56028 3.01571 1.51065 3.70252C1.46101 4.38934 1.59005 5.07731 1.88522 5.69944C2.18039 6.32158 2.63163 6.85669 3.19499 7.25267C3.75836 7.64864 4.41466 7.892 5.1 7.95904C5.03569 7.64337 5.0022 7.3222 5 7.00005Z"
      />
      <path
        d="M15 9.00004H14.571C14.162 9.92031 13.4875 10.6975 12.634 11.232C12.676 11.244 12.714 11.265 12.757 11.278C13.1462 11.0962 13.5704 11.0013 14 11H15C15.7956 11 16.5587 11.3161 17.1213 11.8787C17.6839 12.4413 18 13.2044 18 14V15H16.653C16.882 15.642 16.9994 16.3184 17 17H19C19.2652 17 19.5196 16.8947 19.7071 16.7071C19.8946 16.5196 20 16.2652 20 16V14C19.9984 12.6744 19.4711 11.4036 18.5338 10.4663C17.5964 9.52892 16.3256 9.00163 15 9.00004Z"
      />
      <path
        d="M3.347 15H2V14C2 13.2044 2.31607 12.4413 2.87868 11.8787C3.44129 11.3161 4.20435 11 5 11H6C6.42993 11.0013 6.85442 11.0962 7.244 11.278C7.286 11.265 7.324 11.244 7.366 11.232C6.51249 10.6975 5.83802 9.92031 5.429 9.00004H5C3.67441 9.00163 2.40356 9.52892 1.46622 10.4663C0.528882 11.4036 0.00158786 12.6744 0 14V16C0 16.2652 0.105357 16.5196 0.292893 16.7071C0.48043 16.8947 0.734784 17 1 17H3C3.00065 16.3184 3.11801 15.642 3.347 15Z"
      />
    </mask>
    <path
      d="M14.5 7.38061e-05C13.7815 0.0022703 13.077 0.198574 12.4609 0.568229C11.8448 0.937883 11.34 1.46715 11 2.10007C11.6592 2.23616 12.2839 2.50495 12.836 2.89006C13.0183 2.61588 13.2656 2.39096 13.5557 2.23528C13.8459 2.07959 14.17 1.99795 14.4992 1.9976C14.8285 1.99726 15.1528 2.07822 15.4433 2.2333C15.7337 2.38838 15.9814 2.61278 16.1644 2.88657C16.3473 3.16037 16.4598 3.4751 16.4918 3.80281C16.5239 4.13053 16.4746 4.46109 16.3482 4.76515C16.2218 5.06922 16.0223 5.33738 15.7674 5.54582C15.5125 5.75427 15.2101 5.89656 14.887 5.96005C15.0314 6.61838 15.0365 7.29962 14.902 7.96004C15.9247 7.86276 16.8704 7.37467 17.5422 6.59745C18.214 5.82022 18.56 4.81379 18.5082 3.78779C18.4564 2.76179 18.0107 1.79534 17.264 1.08975C16.5174 0.384155 15.5273 -0.00617158 14.5 7.38061e-05Z"
      fill="currentColor"
    />
    <path
      d="M10 11C9.20887 11 8.43552 10.7654 7.77772 10.3259C7.11992 9.88639 6.60723 9.26168 6.30448 8.53078C6.00173 7.79988 5.92252 6.99561 6.07686 6.21969C6.2312 5.44377 6.61216 4.73104 7.17157 4.17163C7.73098 3.61222 8.44371 3.23126 9.21964 3.07692C9.99556 2.92258 10.7998 3.00179 11.5307 3.30454C12.2616 3.60729 12.8864 4.11998 13.3259 4.77778C13.7654 5.43557 14 6.20893 14 7.00005C14 8.06091 13.5786 9.07832 12.8284 9.82847C12.0783 10.5786 11.0609 11 10 11ZM10 5.00006C9.60444 5.00006 9.21776 5.11735 8.88886 5.33712C8.55996 5.55688 8.30362 5.86923 8.15224 6.23468C8.00087 6.60014 7.96126 7.00227 8.03843 7.39023C8.1156 7.77819 8.30608 8.13455 8.58579 8.41426C8.86549 8.69396 9.22186 8.88444 9.60982 8.96161C9.99778 9.03878 10.3999 8.99918 10.7654 8.8478C11.1308 8.69643 11.4432 8.44008 11.6629 8.11118C11.8827 7.78229 12 7.39561 12 7.00005C12 6.46962 11.7893 5.96091 11.4142 5.58584C11.0391 5.21077 10.5304 5.00006 10 5.00006Z"
      fill="currentColor"
    />
    <path
      d="M15 20H5C4.73478 20 4.48043 19.8946 4.29289 19.7071C4.10536 19.5196 4 19.2652 4 19V17C4.00159 15.6744 4.52888 14.4036 5.46622 13.4662C6.40356 12.5289 7.67441 12.0016 9 12H11C12.3256 12.0016 13.5964 12.5289 14.5338 13.4662C15.4711 14.4036 15.9984 15.6744 16 17V19C16 19.2652 15.8946 19.5196 15.7071 19.7071C15.5196 19.8946 15.2652 20 15 20ZM6 18H14V17C14 16.2044 13.6839 15.4413 13.1213 14.8787C12.5587 14.3161 11.7956 14 11 14H9C8.20435 14 7.44129 14.3161 6.87868 14.8787C6.31607 15.4413 6 16.2044 6 17V18Z"
      fill="currentColor"
    />
    <path
      d="M5 7.00005C5.00102 6.65076 5.03856 6.30253 5.112 5.96105C4.7889 5.89756 4.48647 5.75527 4.23157 5.54682C3.97667 5.33838 3.77718 5.07022 3.65081 4.76615C3.52444 4.46209 3.4751 4.13153 3.50716 3.80381C3.53923 3.4761 3.65172 3.16137 3.83464 2.88757C4.01756 2.61378 4.26525 2.38938 4.55573 2.2343C4.8462 2.07922 5.17047 1.99826 5.49975 1.9986C5.82903 1.99895 6.15314 2.08059 6.44328 2.23628C6.73343 2.39196 6.98066 2.61688 7.163 2.89106C7.71531 2.50547 8.34033 2.23634 9 2.10007C8.67366 1.4937 8.19587 0.982159 7.61315 0.615256C7.03043 0.248353 6.36263 0.0385879 5.67478 0.00638339C4.98692 -0.0258212 4.30245 0.120632 3.688 0.431485C3.07354 0.742338 2.55005 1.207 2.16849 1.78022C1.78692 2.35345 1.56028 3.01571 1.51065 3.70252C1.46101 4.38934 1.59005 5.07731 1.88522 5.69944C2.18039 6.32158 2.63163 6.85669 3.19499 7.25267C3.75836 7.64864 4.41466 7.892 5.1 7.95904C5.03569 7.64337 5.0022 7.3222 5 7.00005Z"
      fill="currentColor"
    />
    <path
      d="M15 9.00004H14.571C14.162 9.92031 13.4875 10.6975 12.634 11.232C12.676 11.244 12.714 11.265 12.757 11.278C13.1462 11.0962 13.5704 11.0013 14 11H15C15.7956 11 16.5587 11.3161 17.1213 11.8787C17.6839 12.4413 18 13.2044 18 14V15H16.653C16.882 15.642 16.9994 16.3184 17 17H19C19.2652 17 19.5196 16.8947 19.7071 16.7071C19.8946 16.5196 20 16.2652 20 16V14C19.9984 12.6744 19.4711 11.4036 18.5338 10.4663C17.5964 9.52892 16.3256 9.00163 15 9.00004Z"
      fill="currentColor"
    />
    <path
      d="M3.347 15H2V14C2 13.2044 2.31607 12.4413 2.87868 11.8787C3.44129 11.3161 4.20435 11 5 11H6C6.42993 11.0013 6.85442 11.0962 7.244 11.278C7.286 11.265 7.324 11.244 7.366 11.232C6.51249 10.6975 5.83802 9.92031 5.429 9.00004H5C3.67441 9.00163 2.40356 9.52892 1.46622 10.4663C0.528882 11.4036 0.00158786 12.6744 0 14V16C0 16.2652 0.105357 16.5196 0.292893 16.7071C0.48043 16.8947 0.734784 17 1 17H3C3.00065 16.3184 3.11801 15.642 3.347 15Z"
      fill="currentColor"
    />
    <path
      d="M14.5 7.38061e-05C13.7815 0.0022703 13.077 0.198574 12.4609 0.568229C11.8448 0.937883 11.34 1.46715 11 2.10007C11.6592 2.23616 12.2839 2.50495 12.836 2.89006C13.0183 2.61588 13.2656 2.39096 13.5557 2.23528C13.8459 2.07959 14.17 1.99795 14.4992 1.9976C14.8285 1.99726 15.1528 2.07822 15.4433 2.2333C15.7337 2.38838 15.9814 2.61278 16.1644 2.88657C16.3473 3.16037 16.4598 3.4751 16.4918 3.80281C16.5239 4.13053 16.4746 4.46109 16.3482 4.76515C16.2218 5.06922 16.0223 5.33738 15.7674 5.54582C15.5125 5.75427 15.2101 5.89656 14.887 5.96005C15.0314 6.61838 15.0365 7.29962 14.902 7.96004C15.9247 7.86276 16.8704 7.37467 17.5422 6.59745C18.214 5.82022 18.56 4.81379 18.5082 3.78779C18.4564 2.76179 18.0107 1.79534 17.264 1.08975C16.5174 0.384155 15.5273 -0.00617158 14.5 7.38061e-05Z"
      stroke="currentColor"
      stroke-width="2"
      mask="url(#path-1-inside-1_2935_2770)"
    />
    <path
      d="M10 11C9.20887 11 8.43552 10.7654 7.77772 10.3259C7.11992 9.88639 6.60723 9.26168 6.30448 8.53078C6.00173 7.79988 5.92252 6.99561 6.07686 6.21969C6.2312 5.44377 6.61216 4.73104 7.17157 4.17163C7.73098 3.61222 8.44371 3.23126 9.21964 3.07692C9.99556 2.92258 10.7998 3.00179 11.5307 3.30454C12.2616 3.60729 12.8864 4.11998 13.3259 4.77778C13.7654 5.43557 14 6.20893 14 7.00005C14 8.06091 13.5786 9.07832 12.8284 9.82847C12.0783 10.5786 11.0609 11 10 11ZM10 5.00006C9.60444 5.00006 9.21776 5.11735 8.88886 5.33712C8.55996 5.55688 8.30362 5.86923 8.15224 6.23468C8.00087 6.60014 7.96126 7.00227 8.03843 7.39023C8.1156 7.77819 8.30608 8.13455 8.58579 8.41426C8.86549 8.69396 9.22186 8.88444 9.60982 8.96161C9.99778 9.03878 10.3999 8.99918 10.7654 8.8478C11.1308 8.69643 11.4432 8.44008 11.6629 8.11118C11.8827 7.78229 12 7.39561 12 7.00005C12 6.46962 11.7893 5.96091 11.4142 5.58584C11.0391 5.21077 10.5304 5.00006 10 5.00006Z"
      stroke="currentColor"
      stroke-width="2"
      mask="url(#path-1-inside-1_2935_2770)"
    />
    <path
      d="M15 20H5C4.73478 20 4.48043 19.8946 4.29289 19.7071C4.10536 19.5196 4 19.2652 4 19V17C4.00159 15.6744 4.52888 14.4036 5.46622 13.4662C6.40356 12.5289 7.67441 12.0016 9 12H11C12.3256 12.0016 13.5964 12.5289 14.5338 13.4662C15.4711 14.4036 15.9984 15.6744 16 17V19C16 19.2652 15.8946 19.5196 15.7071 19.7071C15.5196 19.8946 15.2652 20 15 20ZM6 18H14V17C14 16.2044 13.6839 15.4413 13.1213 14.8787C12.5587 14.3161 11.7956 14 11 14H9C8.20435 14 7.44129 14.3161 6.87868 14.8787C6.31607 15.4413 6 16.2044 6 17V18Z"
      stroke="currentColor"
      stroke-width="2"
      mask="url(#path-1-inside-1_2935_2770)"
    />
    <path
      d="M5 7.00005C5.00102 6.65076 5.03856 6.30253 5.112 5.96105C4.7889 5.89756 4.48647 5.75527 4.23157 5.54682C3.97667 5.33838 3.77718 5.07022 3.65081 4.76615C3.52444 4.46209 3.4751 4.13153 3.50716 3.80381C3.53923 3.4761 3.65172 3.16137 3.83464 2.88757C4.01756 2.61378 4.26525 2.38938 4.55573 2.2343C4.8462 2.07922 5.17047 1.99826 5.49975 1.9986C5.82903 1.99895 6.15314 2.08059 6.44328 2.23628C6.73343 2.39196 6.98066 2.61688 7.163 2.89106C7.71531 2.50547 8.34033 2.23634 9 2.10007C8.67366 1.4937 8.19587 0.982159 7.61315 0.615256C7.03043 0.248353 6.36263 0.0385879 5.67478 0.00638339C4.98692 -0.0258212 4.30245 0.120632 3.688 0.431485C3.07354 0.742338 2.55005 1.207 2.16849 1.78022C1.78692 2.35345 1.56028 3.01571 1.51065 3.70252C1.46101 4.38934 1.59005 5.07731 1.88522 5.69944C2.18039 6.32158 2.63163 6.85669 3.19499 7.25267C3.75836 7.64864 4.41466 7.892 5.1 7.95904C5.03569 7.64337 5.0022 7.3222 5 7.00005Z"
      stroke="currentColor"
      stroke-width="2"
      mask="url(#path-1-inside-1_2935_2770)"
    />
    <path
      d="M15 9.00004H14.571C14.162 9.92031 13.4875 10.6975 12.634 11.232C12.676 11.244 12.714 11.265 12.757 11.278C13.1462 11.0962 13.5704 11.0013 14 11H15C15.7956 11 16.5587 11.3161 17.1213 11.8787C17.6839 12.4413 18 13.2044 18 14V15H16.653C16.882 15.642 16.9994 16.3184 17 17H19C19.2652 17 19.5196 16.8947 19.7071 16.7071C19.8946 16.5196 20 16.2652 20 16V14C19.9984 12.6744 19.4711 11.4036 18.5338 10.4663C17.5964 9.52892 16.3256 9.00163 15 9.00004Z"
      stroke="currentColor"
      stroke-width="2"
      mask="url(#path-1-inside-1_2935_2770)"
    />
    <path
      d="M3.347 15H2V14C2 13.2044 2.31607 12.4413 2.87868 11.8787C3.44129 11.3161 4.20435 11 5 11H6C6.42993 11.0013 6.85442 11.0962 7.244 11.278C7.286 11.265 7.324 11.244 7.366 11.232C6.51249 10.6975 5.83802 9.92031 5.429 9.00004H5C3.67441 9.00163 2.40356 9.52892 1.46622 10.4663C0.528882 11.4036 0.00158786 12.6744 0 14V16C0 16.2652 0.105357 16.5196 0.292893 16.7071C0.48043 16.8947 0.734784 17 1 17H3C3.00065 16.3184 3.11801 15.642 3.347 15Z"
      stroke="currentColor"
      stroke-width="2"
      mask="url(#path-1-inside-1_2935_2770)"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const componentName = 'UsersGroupOutlineIcon'
export default defineComponent({
  name: componentName,
})
</script>

<script lang="ts" setup></script>

<style scoped></style>
