import { defineGlobalStore } from '@/app/support/defineGlobalStore'
import { useCardsStore } from '@/domain/cards/services/useCardsStore'
import { createGlobalState } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { computed, readonly, ref, toRaw, watch } from 'vue'
import { useCardExpandedDialogStore } from './useCardExpandedDialogStore'

export const useCardNavigation = defineGlobalStore(
  'cardNavigation',
  createGlobalState(() => {
    const cardsStore = useCardsStore()
    const { topic, parents, children } = storeToRefs(cardsStore)

    const cardExpandedDialogStore = useCardExpandedDialogStore()
    const { expandedCard, cardIsExpanded } = storeToRefs(cardExpandedDialogStore)

    const sortedCardIds = ref<string[]>([])
    const currentIndex = ref(0)
    const currentCardId = ref('')

    const nextButtonDisabled = computed(
      () => currentIndex.value === sortedCardIds.value.length - 1,
    )
    const previousButtonDisabled = computed(() => currentIndex.value === 0)

    watch(cardIsExpanded, (val) => {
      if (val) {
        setOpenedCard()
      }
    })

    const setOpenedCard = (mock?: { cardId?: string }) => {
      currentCardId.value = mock?.cardId ?? expandedCard.value.id
      currentIndex.value = sortedCardIds.value.indexOf(currentCardId.value)
    }

    const reset = () => {
      sortedCardIds.value = []
      currentIndex.value = 0
      currentCardId.value = ''
    }

    const addIdsOfChildrenIfNotExist = (card: any) => {
      const index = sortedCardIds.value.indexOf(card.id)
      const children = card.relationships?.children?.data

      const newArr = [...sortedCardIds.value]
      newArr.splice(index + 1, 0, ...(children.map((card) => card.id) ?? []))

      const arrToSet = new Set<string>(newArr)
      sortedCardIds.value = [...arrToSet]
    }

    const addChildrenIdToArray = (card: any) => {
      const childrenData = card.relationships?.children?.data
      if (!childrenData || !childrenData.length) {
        return
      }

      addIdsOfChildrenIfNotExist(card)

      const childrenCards = children.value[card.id]
      if (!childrenCards) {
        return
      }

      for (const child of childrenCards) {
        addChildrenIdToArray(child)
      }
    }

    const init = () => {
      sortedCardIds.value = [
        topic.value.id,
        ...(topic.value.relationships?.children?.data.map((card) => card.id) ?? []),
      ]
      for (const card of toRaw(parents.value)) {
        addChildrenIdToArray(card)
      }
    }

    const deleteIdFromArray = (
      id: string,
      mocks?: {
        init: () => void
      },
    ) => {
      const setAll = mocks?.init ?? init
      const arrToSet = new Set<string>(sortedCardIds.value)
      arrToSet.delete(id)

      sortedCardIds.value = [...arrToSet]
      setAll()
    }

    const next = () => {
      currentIndex.value += 1
      navigateToCard()
    }

    const prev = () => {
      currentIndex.value -= 1
      navigateToCard()
    }

    const navigateToCard = () => {
      currentCardId.value = sortedCardIds.value[currentIndex.value]
      cardExpandedDialogStore.pushNewExpandedRoute(currentCardId.value)
    }

    return {
      init,
      next,
      prev,
      addIdsOfChildrenIfNotExist,
      deleteIdFromArray,
      reset,
      setOpenedCard,
      sortedCardIds: readonly(sortedCardIds),
      currentCardId: readonly(currentCardId),
      currentIndex: readonly(currentIndex),
      nextButtonDisabled,
      previousButtonDisabled,
    }
  }),
)
