<template>
  <ScrollableContainerLayout
    layout-id="scrollable-layout"
    :navigation="{ containerClasses: ['h-[52px]'], whenScrolledClasses: ['shadow-lg'] }"
    :root-items-selectors="['html', 'body', '#root', '#scrollable-layout']"
    :scroll-container="{ id: 'scrollable-container', classes: ['flex', 'flex-col'] }"
    :inputs="{
      selectors: ['input', 'textarea'],
      scrollTargetSelector: '.input-scroll-target',
    }"
    :has-footer="true"
  >
    <template #navigation>
      <MainNavigation
        class="px-4 bg-base-100 max-w-7xl"
        :show-logo="true"
        :logo-container-classes="['']"
      />
    </template>

    <template #scrollable-container>
      <SectionLayout
        width="narrow"
        :visualize-width="false"
        tag="div"
        class="mt-4 md:mt-20"
      >
        <div
          class="flex flex-col items-start justify-start md:justify-start gap-10 sm:gap-6 lg:gap-8 md:items-center sm:flex-row w-full"
        >
          <div class="flex w-full pl-4 sm:pl-0 justify-center items-center">
            <SignInContent
              :type="content.type"
              :title="content.title"
              :sign-in-title="content.signInTitle"
              :case-title="content.caseTitle"
              :show-preview="content.showPreview"
              :items="content.items"
            />
          </div>
          <div class="flex w-full justify-center items-center">
            <SignInCard :title="content.signInTitle" />
          </div>
        </div>
      </SectionLayout>
      <div class="shrink-0 flex-grow min-h-[32px] md:min-h-[64px]" />
    </template>
  </ScrollableContainerLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const componentName = 'PSignIn'
export default defineComponent({
  name: componentName,
})
</script>

<script setup lang="ts">
import { onMounted } from 'vue'
import { captureWithUser } from '@/app/support/usePosthog'
import { useRouter } from 'vue-router'
import MainNavigation from '@/app/components/MainNavigation.vue'
import ScrollableContainerLayout from '@/app/components/ScrollableContainerLayout.vue'
import SectionLayout from '@/domain/Content/components/SectionLayout.vue'
import SignInCard from '@/domain/Authentication/components/SignInCard.vue'
import SignInContent from '@/domain/Authentication/components/SignInContent.vue'
import { useSignInPageContent } from '@/domain/Authentication/composables/useSignInPageContent'
import { EAuthenticationEvents } from '@/domain/Authentication/contracts/EAuthenticationEvents'

const { currentRoute } = useRouter()
const { content } = useSignInPageContent(currentRoute)

onMounted(async () => {
  captureWithUser(EAuthenticationEvents.visitedSignInPage, {
    route: (currentRoute.value.name as string).toLowerCase(),
    params: { ...currentRoute.value.params },
    query: { ...currentRoute.value.query },
    contentType: content.value.type,
  })
})
</script>
