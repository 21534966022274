import { TShallowReactiveItemList } from '@/domain/LinkedList/contracts/TShallowReactiveItemList'
import { ShallowRef } from '@vue/reactivity'
import { ref, Ref, triggerRef } from 'vue'
import { IFlattenedLinkableItem } from '@/domain/LinkedList/contracts/IFlattenedLinkableItem'

export const applyOrderToFlattenedLinkableItem = <T extends IFlattenedLinkableItem>(
  state: TShallowReactiveItemList<T>,
  idList: string[] = [],
  mocks?: { triggerRef?: () => void },
): ShallowRef<T>[] => {
  const doTriggerRef = mocks?.triggerRef ? mocks.triggerRef : triggerRef

  const previousId: Ref<string | null> = ref(null)
  const changedItems: ShallowRef<T>[] = []

  for (const id of idList) {
    const currentItem = state[id]

    if (currentItem.value.attributes.previousId === previousId.value) {
      previousId.value = id
      continue
    }

    currentItem.value.attributes.previousId = previousId.value
    changedItems.push(currentItem)
    previousId.value = id
  }

  for (const item of changedItems) {
    doTriggerRef(item)
  }

  return changedItems
}
