import { type RegisteredComponent } from '@builder.io/sdk-vue'
import component from '@/domain/Book/components/BooksDirectory.vue'

export const BooksDirectoryComponentRegistration = (
  shouldWrap = true,
): RegisteredComponent => ({
  component: component,
  name: 'BooksDirectory',
  noWrap: !shouldWrap,
  canHaveChildren: true,
  inputs: [
    {
      name: 'classes',
      type: 'object',
      advanced: true,
      subFields: [
        {
          name: 'container',
          type: 'text',
          defaultValue: 'mt-12 mb-6',
        },
        {
          name: 'contentContainer',
          type: 'text',
        },
        {
          name: 'headingContainer',
          type: 'text',
        },
        {
          name: 'titleContainer',
          type: 'text',
        },
        {
          name: 'title',
          type: 'text',
        },
        {
          name: 'multiSelect',
          type: 'text',
        },
        {
          name: 'entriesContainer',
          type: 'text',
        },
        {
          name: 'singleEntryContainer',
          type: 'text',
        },
        {
          name: 'singleContainerImageBadge',
          type: 'text',
        },
        {
          name: 'singleContainerTitle',
          type: 'text',
        },
      ],
    },
    {
      name: 'title',
      type: 'text',
      defaultValue: 'Directory',
    },
    {
      friendlyName: 'Category selector',
      name: 'showCategorySelector',
      type: 'boolean',
      defaultValue: true,
    },
    {
      friendlyName: 'Category selector placeholder',
      name: 'categoryPlaceholder',
      type: 'string',
      defaultValue: 'Select a Category',
      advanced: true,
    },
    {
      friendlyName: 'Directory Card Badge',
      name: 'imageBadgeText',
      type: 'string',
      defaultValue: 'Curated',
      advanced: true,
    },
    {
      friendlyName: 'Visible author count',
      name: 'maxVisibleAuthors',
      type: 'number',
      defaultValue: 3,
      advanced: true,
    },
    {
      friendlyName: 'Visible categories count',
      name: 'maxVisibleCategories',
      type: 'number',
      defaultValue: 1,
      advanced: true,
    },
  ],
})
