import router from '@/app/services/router'
import { useCardsStore } from '@/domain/cards/services/useCardsStore'
import { defineStore, storeToRefs } from 'pinia'
import { useCardNavigation } from './useCardNavigation'
import { ENodeEvents } from '@/app/contracts/ENodeEvents'
import { usePosthog } from '@/app/support/usePosthog'
import { ENodeLevels } from '@/app/contracts/ENodeLevels'

type CardExpandedDialogStoreState = {
  dialogIsVisible: boolean
  expandedCard: any
  expandedCardSiblings: any[]
  expandedParentCardId: string | null
  type?: 'topic' | 'parent' | 'child'
  cardEnumeration?: string
}

export const useCardExpandedDialogStore = defineStore('useCardExpandedDialogStore', {
  state: () =>
    ({
      dialogIsVisible: false,
      expandedCard: {},
      expandedCardSiblings: [],
      expandedParentCardId: null,
      type: 'topic',
      cardEnumeration: '',
    }) as CardExpandedDialogStoreState,
  getters: {
    cardIsExpanded: (state) => state.dialogIsVisible === true,
  },
  actions: {
    async pushNewExpandedRoute(id: string) {
      const query = router.currentRoute.value.query
      await router.push({
        query: {
          ...query,
          expanded: id,
        },
      })
    },
    async open(
      card?: any,
      siblingCards?: any,
      parentCardId?: any,
      type?: any,
      cardEnumeration?: string | number,
    ) {
      await this.pushNewExpandedRoute(card.id)
      this.expandedCard = card
      this.expandedCardSiblings = siblingCards ?? []
      this.expandedParentCardId = parentCardId
      this.type = type
      this.cardEnumeration = cardEnumeration?.toString()

      this.dialogIsVisible = true
      await useCardsStore().fetchChildrenOf(card)
      useCardNavigation().addIdsOfChildrenIfNotExist(card)

      try {
        const isGuide = card?.attributes?.cardType === 'topic'
        const guideId = isGuide ? card.id : card?.relationships?.topic?.data?.id

        // noinspection JSUnresolvedReference
        const nodeTitle =
          card.relationships.metadata?.data?.filter(
            (f) =>
              f?.relationships?.metadataType?.data?.id ===
              '2b754052-a2ae-430f-afb3-60ab0a242045',
          )[0]?.attributes?.payload?.value ?? null

        // noinspection JSUnresolvedReference
        const properties = {
          node_id: card.id,
          node_title: nodeTitle,
          node_parent_id: isGuide ? card.id : parentCardId,
          node_level: isGuide ? ENodeLevels.guide : ENodeLevels.parentOrChild,
          guide_id: guideId,
          guide_title: isGuide
            ? nodeTitle
            : guideId !== useCardsStore().topic.id
            ? null
            : useCardsStore().topic?.relationships?.metadata?.data?.filter(
                (f) =>
                  f?.relationships?.metadataType?.data?.id ===
                  '2b754052-a2ae-430f-afb3-60ab0a242045',
              )[0]?.attributes?.payload?.value ?? null,
        }
        usePosthog().capture(ENodeEvents.expanded, properties)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('⚠️ posthog submit failed', ENodeEvents.expanded, e)
      }
    },
    close(force = true) {
      const query = router.currentRoute.value.query
      let guideId = router.currentRoute.value?.params?.topicId ?? null
      const nodeId = router.currentRoute.value.query.expanded ?? null

      delete query['expanded']
      router.push({
        query,
        force,
      })
      this.dialogIsVisible = false
      this.$reset()

      if (!nodeId) {
        return
      }

      try {
        const card = useCardsStore().allCards.filter((c) => c.id === nodeId)[0] ?? null
        const isGuide = card?.attributes?.cardType === 'topic'
        guideId = isGuide ? card.id : guideId
        // noinspection JSUnresolvedReference
        const nodeTitle =
          card.relationships.metadata?.data?.filter(
            (f) =>
              f?.relationships?.metadataType?.data?.id ===
              '2b754052-a2ae-430f-afb3-60ab0a242045',
          )[0]?.attributes?.payload?.value ?? null

        // noinspection JSUnresolvedReference
        const properties = {
          node_id: card.id,
          node_title: nodeTitle,
          node_parent_id: isGuide
            ? card.id
            : card?.relationships?.parent?.data?.id ?? null,
          node_level: isGuide ? ENodeLevels.guide : ENodeLevels.parentOrChild,
          guide_id: guideId,
          guide_title: isGuide
            ? nodeTitle
            : guideId !== useCardsStore().topic.id
            ? null
            : useCardsStore().topic?.relationships?.metadata?.data?.filter(
                (f) =>
                  f?.relationships?.metadataType?.data?.id ===
                  '2b754052-a2ae-430f-afb3-60ab0a242045',
              )[0]?.attributes?.payload?.value ?? null,
        }
        usePosthog().capture(ENodeEvents.collapsed, properties)
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('⚠️ posthog submit failed', ENodeEvents.collapsed, e)
      }
    },
    toggle(card) {
      this.dialogIsVisible ? this.close() : this.open(card)
    },
    findIndex() {
      return this.expandedCardSiblings.findIndex(
        (card) => card.id === this.expandedCard.id,
      )
    },
    setCardPosition(direction: 'forward' | 'backward') {
      let lastPosition = Number(this.cardEnumeration?.slice(-1))
      if (direction === 'forward') {
        lastPosition += 1
      } else {
        lastPosition -= 1
      }
      this.cardEnumeration = `${this.cardEnumeration?.slice(0, -1)}${lastPosition}`
    },
    expandCardBasedOnCardId(id: string) {
      const cardsStore = useCardsStore()
      const { topic, parents, children } = storeToRefs(cardsStore)
      const card = cardsStore.findCardById(id)
      if (!card) {
        return
      }
      const relationships = card?.relationships
      const topicId = relationships?.topic?.data?.id
      const parentId = relationships?.parent?.data?.id
      const index = cardsStore.findCardEnumeration(id)
      if (card.attributes?.cardType === 'topic') {
        this.open(topic.value, [], undefined, 'topic', '')
        return
      }
      if (topicId === parentId) {
        this.open(card, parents.value, topicId, 'parent', index)
        return
      }
      this.open(card, children.value[parentId], parentId, 'child', index)
    },
  },
})
