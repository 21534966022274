import { createGlobalState, useTimeoutPoll } from '@vueuse/core'
import { defineGlobalStore } from '../support/defineGlobalStore'
import { storeToRefs } from 'pinia'
import { useTopicsStore } from './useTopicsStore'
import { resolveMetaDataFromCard } from '@/domain/cards/support/helper'
import { metaDataTypes } from './metaDataClient'
import { getCardData } from '@/domain/cards/services/cardClient'
import { ref, watch } from 'vue'

type RemoveJobFn = () => void
type JobFn = (removeJob: RemoveJobFn) => Promise<void>
type JobCallback = (removeJob: RemoveJobFn, topicId: string) => Promise<void>

const POLLING_INTERVAL_BASE = 1.02
const POLLING_INTERVAL_IN_MS_DEFAULT = 500
const POLLING_INTERVAL_AUTO_RESET_COUNT = 14
const POLLING_ATTEMPT_DEFAULT = 1
const _pollingAttempts = ref<number>(POLLING_ATTEMPT_DEFAULT)
const _pollingIntervalInMs = ref<number>(POLLING_INTERVAL_IN_MS_DEFAULT)

const _calcInterval = () => {
  _pollingIntervalInMs.value =
    _pollingIntervalInMs.value * Math.pow(POLLING_INTERVAL_BASE, _pollingAttempts.value)
  _pollingAttempts.value = _pollingAttempts.value + 1
}

const _resetInterval = () => {
  _pollingAttempts.value = POLLING_ATTEMPT_DEFAULT
  _pollingIntervalInMs.value = POLLING_INTERVAL_IN_MS_DEFAULT
}

watch(_pollingAttempts, (attempts) => {
  if (attempts >= POLLING_INTERVAL_AUTO_RESET_COUNT) {
    _resetInterval()
  }
})

export const useAsyncTopicCopyJobUpdater = defineGlobalStore(
  'useAsyncTopicCopyJobUpdater',
  createGlobalState(() => {
    const { topics } = storeToRefs(useTopicsStore())
    const jobs = <{ [key: string]: JobFn }>{}
    const jobCount = () => Object.keys(jobs).length

    const jobRemover = (topicId: string) => {
      delete jobs[topicId]

      if (jobCount() === 0) {
        pauseJobPolling()
      }
    }

    const createJobCallback = (callback: JobCallback) => callback

    const addJob = (topicId: string, callback: JobCallback) => {
      jobs[topicId] = (done) => callback(done, topicId)
      if (jobCount() > 0) {
        runJobPolling()
      }
    }

    const executeJobs = async () => {
      const promises: Promise<void>[] = []
      for (const [topicId, job] of Object.entries(jobs)) {
        promises.push(job(() => jobRemover(topicId)))
      }

      await Promise.allSettled(promises)
      _calcInterval()
    }

    const { pause: pauseJobPolling, resume: runJobPolling } = useTimeoutPoll(
      executeJobs,
      _pollingIntervalInMs,
      { immediate: false },
    )

    const checkCopyJobUntilDone = createJobCallback(
      async (removeJob: RemoveJobFn, topicId: string) => {
        const result = await getCardData(topicId)

        const sharedAsCopyMeta = resolveMetaDataFromCard(
          result,
          metaDataTypes.sharedAsCopyId,
        )

        if (
          sharedAsCopyMeta &&
          (sharedAsCopyMeta?.attributes?.payload?.copyProcess ?? '') !== 'done'
        ) {
          return
        }

        if (sharedAsCopyMeta) {
          const topicData = topics.value.find((data) => data.id === topicId)
          const metadata = resolveMetaDataFromCard(
            topicData,
            metaDataTypes.sharedAsCopyId,
          )
          metadata.attributes.payload.copyProcess = 'done'
        }

        const extendDocumentWithAiIdMetadata = resolveMetaDataFromCard(
          result,
          metaDataTypes.extendDocumentWithAiId,
        )

        const status = extendDocumentWithAiIdMetadata?.attributes?.payload?.status
        if (extendDocumentWithAiIdMetadata && status !== 'nodes-created') {
          return
        }

        if (extendDocumentWithAiIdMetadata) {
          const topicData = topics.value.find((data) => data.id === topicId)
          const metadata = resolveMetaDataFromCard(
            topicData,
            metaDataTypes.extendDocumentWithAiId,
          )
          metadata.attributes.payload.status = 'nodes-created'
        }

        removeJob()
      },
    )

    const watchJobs = () => {
      for (const topic of topics.value) {
        const sharedAsCopyMeta = resolveMetaDataFromCard(
          topic,
          metaDataTypes.sharedAsCopyId,
        )

        if (sharedAsCopyMeta) {
          const status = sharedAsCopyMeta?.attributes?.payload?.copyProcess ?? ''

          if (['new', 'inProcess'].includes(status)) {
            addJob(topic.id, checkCopyJobUntilDone)
          }
        }

        const extendDocumentWithAiIdMetadata = resolveMetaDataFromCard(
          topic,
          metaDataTypes.extendDocumentWithAiId,
        )

        if (extendDocumentWithAiIdMetadata) {
          const status = extendDocumentWithAiIdMetadata?.attributes?.payload?.status

          if (
            ['new', 'prompt-request-created', 'prompt-response-received'].includes(status)
          ) {
            addJob(topic.id, checkCopyJobUntilDone)
          }
        }
      }
    }

    return {
      watchJobs,
      pause: () => {
        pauseJobPolling()
        _resetInterval()
      },
      resume: () => {
        runJobPolling()
        _resetInterval()
      },
    }
  }),
)
