import { signInRouteGuard as routeGuard } from '@/domain/Authentication/guards/signInRouteGuard'
import PSignInComponent from '@/domain/Authentication/components/PSignIn.vue'
import { authenticationRoutes as routes } from '@/domain/Authentication/authenticationRoutes'
import { CAuthenticationRouteNames as routeNames } from '@/domain/Authentication/contracts/CAuthenticationRouteNames'

export const PSignIn = PSignInComponent
export const signInRouteGuard = routeGuard
export const authenticationRoutes = routes

export const CAuthenticationRouteNames = routeNames
