<template>
  <footer class="flex justify-center items-center">
    <div
      class="flex flex-col-reverse md:flex-row gap-2 md:gap-4 justify-between items-start md:items-center w-full max-w-7xl p-4"
    >
      <div class="text-sm leading-5 text-base-400">{{ props.copyright }}</div>
      <nav
        class="flex justify-center content-center items-center my-auto text-sm leading-5 text-base-500 md:text-base-400 underline"
      >
        <ul v-if="items.length > 0" class="flex flex-wrap gap-1 md:gap-4">
          <li
            v-for="item in items"
            :key="item.target"
            class="hover:text-base-content mr-2 md:mr-0"
          >
            <RouterLink :to="item.target">{{ item.title }}</RouterLink>
          </li>
          <li
            v-if="showActivateFeedbackWidgetInFooter"
            class="hover:text-base-content mr-2 md:mr-0"
          >
            <button
              class="underline"
              @click.stop="
                activateFeedbackWidget(EFeedbackWidgetActivationSource.footerEntry)
              "
            >
              Feedback
            </button>
          </li>
        </ul>
      </nav>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const componentName = 'Footer'
export default defineComponent({
  name: componentName,
})
</script>

<script lang="ts" setup>
import { RouterLink } from 'vue-router'
import { type TFooterItem } from '@/app/contracts/TFooterItem'
import { onMounted, ref } from 'vue'
import { fetchEntries } from '@builder.io/sdk-vue'
import { useConfig } from '@/app/services/useConfig'
import { useFeedbackWidget } from '@/domain/FeedbackWidget'
import { EFeedbackWidgetActivationSource } from '@/domain/FeedbackWidget/contracts/EFeedbackWidgetActivationSource'

const props = withDefaults(
  defineProps<{
    copyright?: string
  }>(),
  {
    copyright: `© ${new Date().getFullYear()} Miljn GmbH All rights reserved.`,
  },
)

const items = ref<TFooterItem[]>([] as TFooterItem[])
const { showActivateFeedbackWidgetInFooter, activateFeedbackWidget } = useFeedbackWidget()

onMounted(async () => {
  items.value = (
    (await fetchEntries({
      apiKey: useConfig().get().builderIO.publicApiKey,
      model: 'page',
      limit: 4,
      query: {
        data: {
          isVisibleInFooter: { $eq: true },
        },
      },
      fields: 'data.footerOrder,data.footerTitle,data.url',
      sort: {
        ['data.footerOrder']: 1,
      },
    })) ?? []
  )
    .map((item) => ({
      title: item?.data?.footerTitle ?? undefined,
      order: item?.data?.footerOrder ?? 0,
      target: item?.data?.url ?? undefined,
    }))
    .filter((i) => i.target && i.title)
})
</script>

<style scoped></style>
