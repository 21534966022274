import { ref } from 'vue'
import { useLinkedInAuthCodeStore } from '@/domain/Authentication/composables/useLinkedInAuthCodeStore'
import { useConfig } from '@/app/services/useConfig'
import { resolvePopupDimensionAndPosition } from '@/domain/Authentication/support/resolvePopupDimensionAndPosition'

const resolveAuthorizationUrl = (
  clientId: string,
  redirectUri: string,
  scope: string,
  stateString: string,
) => {
  const authorizationUrl = new URL('https://www.linkedin.com/oauth/v2/authorization')
  const query = new URLSearchParams(authorizationUrl.search)
  query.append('response_type', 'code')
  query.append('client_id', clientId)
  query.append('redirect_uri', redirectUri)
  query.append('scope', scope)
  query.append('state', stateString)

  authorizationUrl.search = query.toString()

  return authorizationUrl.toString()
}

export const useLinkedInPopupWindow = (
  clientId: string,
  redirectUri: string,
  scope: string,
  errorHandler: () => void,
) => {
  const popupRef = ref<Window | null>()
  const popUpIntervalRef = ref<number>()

  const open = () => {
    const authorizationUrl = resolveAuthorizationUrl(
      useConfig().get().authentication.linkedIn.clientId as string,
      useConfig().get().authentication.linkedIn.redirect as string,
      'profile email openid',
      useLinkedInAuthCodeStore().generateState(),
    )

    popupRef.value?.close()
    popupRef.value = window.open(
      authorizationUrl,
      '_blank',
      resolvePopupDimensionAndPosition({ width: 600, height: 720 }),
    )

    if (popUpIntervalRef.value) {
      window.clearInterval(popUpIntervalRef.value)
      popUpIntervalRef.value = undefined
    }

    popUpIntervalRef.value = window.setInterval(() => {
      try {
        if (popupRef.value && popupRef.value.closed) {
          window.clearInterval(popUpIntervalRef.value)
          popUpIntervalRef.value = undefined
          !useLinkedInAuthCodeStore().state.value.code && errorHandler()
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        window.clearInterval(popUpIntervalRef.value)
        popUpIntervalRef.value = undefined
      }
    }, 1000)
  }

  return {
    open,
  }
}
