import { onMounted, onUnmounted, type Ref, ref, watch } from 'vue'
import type { TDirectoryEntry } from '@/domain/directory/contracts/TDirectoryEntry'
import { fetchEntries } from '@builder.io/sdk-vue'
import { directoryEntriesDataTransformer } from '@/domain/directory/services/directoryEntriesDataTransformer'
import type { TDirectoryEntryData } from '@/domain/directory/contracts/TDirectoryEntryData'
import type { TDocumentCategory } from '@/domain/directory/contracts/TDocumentCategory'
import { categoryDataTransformer } from '@/domain/directory/services/categoryDataTransformer'
import type { TDocumentCategoryData } from '@/domain/directory/contracts/TDocumentCategoryData'
import { CBuilderModels } from '@/app/contracts/CBuilderModels'

/**
 * @deprecated This composable is deprecated and will be removed in a future release.
 */
export const useDirectoryEntries = (
  apiKey: string,
  useFakeData = false,
  options: {
    fetchCategoriesOnMount: boolean
    fetchEntriesOnMount: boolean
  } = {
    fetchCategoriesOnMount: true,
    fetchEntriesOnMount: true,
  },
) => {
  const categories = ref<TDocumentCategory[]>([])
  const entries = ref<TDirectoryEntry[]>([])
  const selectedCategoryIds = ref<string[]>([])
  const selectedExpertIds = ref<string[]>([])
  const selectedEntriesIds = ref<string[]>([])

  const reset = () => {
    categories.value = []
    entries.value = []
    selectedCategoryIds.value = []
    selectedExpertIds.value = []
  }

  const fetchCategories = async (apiKey: string): Promise<TDocumentCategory[]> => {
    if (useFakeData) {
      return (await import('@/domain/directory/data/categories.json')).default
    }

    const result =
      (await fetchEntries({
        apiKey: apiKey,
        model: 'document-categories',
      })) ?? []

    return result.map(
      (item) =>
        categoryDataTransformer(item as TDocumentCategoryData) as TDocumentCategory,
    )
  }

  const fetchDirectoryEntries = async (
    apiKey: string,
    selectedExpertIds: Ref<string[]>,
    selectedFilterIds: Ref<string[]>,
  ): Promise<TDirectoryEntry[]> => {
    if (useFakeData) {
      return (await import('@/domain/directory/data/directory-entries.json'))
        .default as TDirectoryEntry[]
    }

    const query = {}

    if (selectedEntriesIds.value.length > 0) {
      query['id'] = { $in: selectedEntriesIds.value }
    }

    if (selectedExpertIds.value.length > 0) {
      query['data.experts.expert.id'] = { $in: selectedExpertIds.value }
    }

    if (selectedFilterIds.value.length > 0) {
      query['data.category.id'] = { $in: selectedFilterIds.value }
    }

    const result =
      (await fetchEntries({
        apiKey: apiKey,
        model: CBuilderModels.DirectoryEntries,
        enrich: true,
        limit: 100,
        query,
      })) ?? []

    return result.map((dataItem) =>
      directoryEntriesDataTransformer(dataItem as TDirectoryEntryData),
    )
  }

  const _doFetchCategories = async () => fetchCategories(apiKey)
  const _doFetchEntries = async () => {
    let entries = await fetchDirectoryEntries(
      apiKey,
      selectedExpertIds,
      selectedCategoryIds,
    )

    if ((selectedEntriesIds.value?.length ?? []) > 0 && entries) {
      entries = selectedEntriesIds.value
        .map((id) => entries.find((obj) => obj.id === id))
        .filter((obj) => obj !== undefined) as TDirectoryEntry[]
    }

    return entries
  }

  onMounted(async () => {
    if (options.fetchCategoriesOnMount) {
      categories.value = await _doFetchCategories()
    }

    if (options.fetchEntriesOnMount) {
      entries.value = await _doFetchEntries()
    }
  })

  onUnmounted(() => {
    reset()
  })

  watch(selectedCategoryIds, async (newIds, oldIds) => {
    if (newIds.length === 0 && oldIds.length === 0) {
      return
    }
    entries.value = await _doFetchEntries()
  })

  watch(selectedExpertIds, async (newIds, oldIds) => {
    if (newIds.length === 0 && oldIds.length === 0) {
      return
    }
    entries.value = await _doFetchEntries()
  })

  return {
    categories,
    selectedCategoryIds,
    selectedExpertIds,
    entries,
    selectedEntriesIds,
    fetchCategories: async () => (categories.value = await _doFetchCategories()),
    fetchEntries: async () => (entries.value = await _doFetchEntries()),
  }
}
