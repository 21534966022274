import type { TExpertData } from '@/domain/directory/contracts/TExpertData'
import type { TExpert } from '@/domain/directory/contracts/TExpert'

export const expertDataTransformer = (expert: TExpertData): TExpert => {
  return {
    id: expert.id,
    firstname: expert.data.firstname,
    lastname: expert.data.lastname,
    slogan: expert.data.slogan,
    slug: expert.data.slug,
    photo: expert.data.photo,
    urls: expert.data.urls,
  }
}
