import { NavigationFailure, RouteLocationRaw, Router } from 'vue-router'
import { ref } from 'vue'

const _router = ref<Router>()

export const useNavigator = () => {
  return {
    init: (router: Router) => (_router.value = router),
    push: async (
      to: RouteLocationRaw,
      afterNavigationCallback?: (
        navigationResult: void | NavigationFailure | undefined,
      ) => Promise<void>,
    ) => {
      if (!_router.value) {
        throw new Error(`router isn't initialized`)
      }

      const navigationResult = await _router.value.push(to)

      if (afterNavigationCallback) {
        await afterNavigationCallback(navigationResult)
      }

      return navigationResult
    },
  }
}
