import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import { useUserStore } from '@/app/services/useUserStore'
import { captureWithUser } from '@/app/support/usePosthog'
import { EUserEvents } from '@/app/contracts/EUserEvents'
import { routeLogger } from '@/app/services/routeLogger'
import { CRouteNames } from '@/app/contracts/CRouteNames'

export const signInRouteGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const { isLoggedIn } = useUserStore()

  if (isLoggedIn.value) {
    captureWithUser(EUserEvents.visited, {
      route: to.name,
      route_query: { ...to.query },
      route_params: { ...to.params },
      comment: `user is already signed in will redirect to ${CRouteNames.dashboard}`,
      guard: 'signInRouteGuard',
    })

    routeLogger({
      msg: `already logged in redirect to ${CRouteNames.dashboard}`,
      nav: { from, to },
    })

    next({
      name: CRouteNames.dashboard,
    })
    return
  }

  next()
}
