import posthog from 'posthog-js'
import { App, Plugin } from 'vue'
import { PosthogConfig } from '@/app/services/FrontEndConfigTypes'

export const PosthogPlugin: Plugin = {
  install(app: App, options: PosthogConfig) {
    app.config.globalProperties.$posthog = posthog.init(options.projectApiKey, {
      api_host: options.instanceAddress,
    })
  },
}
