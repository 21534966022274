import { useStorage } from '@vueuse/core'
import { nextTick, readonly } from 'vue'

const _defaultState = { oauthToken: undefined, oauthVerifier: undefined }
const _state = useStorage<{ oauthToken?: string; oauthVerifier?: string }>(
  'x_oauth_store',
  { ..._defaultState },
)

export const useXOAuthStore = () => {
  return {
    state: readonly(_state),
    collect: async (oauthToken: string, oauthVerifier: string) => {
      await nextTick(
        () =>
          (_state.value = {
            oauthToken: oauthToken,
            oauthVerifier: oauthVerifier,
          }),
      )
    },
    reset: async () => {
      await nextTick(() => (_state.value = { ..._defaultState }))
    },
  }
}
