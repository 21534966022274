<template>
  <article
    v-if="isVisible"
    :id="slug"
    class="flex flex-col bg-base-100 rounded-2xl border border-solid shadow-lg border-base-200 overflow-hidden text-left cursor-pointer transition-transform duration-300 ease-in-out"
    :class="[!isTouchDevice ? 'group hover:scale-105' : '']"
    :data-directory-entry-type="props.source.type"
    :data-directory-entry-value="
      props.source.type === 'prompt' ? 'prompt' : props.source.value
    "
    :data-directory-entry-is-premium="props.isPremium"
  >
    <a :href="navigationTarget" @click.prevent="trackSelection(props)">
      <div class="relative overflow-hidden">
        <img
          loading="lazy"
          :src="props.teaserImage"
          :alt="`Teaser image for the '${props.title}' guide published on Miljn`"
          class="w-full object-cover aspect-[2.27] group-hover:scale-110 transition-transform duration-300 ease-in-out"
          width="636"
          height="280"
        />
        <span
          v-if="props.isPremium"
          class="absolute top-0 end-0 rounded-se-2xl rounded-es-2xl text-xs font-medium bg-state-error-focus text-white py-1.5 px-4 group-hover:bg-state-error"
        >
          Curated
        </span>
      </div>
      <section class="flex flex-col justify-between px-4 py-3 w-full">
        <h2
          class="text-base font-semibold leading-5 text-base-content line-clamp-2 min-h-[40px]"
          v-html="props.title"
        />
        <div
          class="flex justify-between items-center mt-3 text-sm leading-5 text-base-400 space-x-3"
        >
          <span
            v-if="props?.category?.label"
            class="inline-block font-normal overflow-hidden overflow-ellipsis whitespace-nowrap"
          >
            {{ props?.category?.label }}
          </span>
        </div>
      </section>
    </a>
  </article>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const componentName = 'DirectoryCard'
export default defineComponent({
  name: componentName,
})
</script>

<script lang="ts" setup>
import type { TDirectoryEntry } from '@/domain/directory/contracts/TDirectoryEntry'
import { computed } from 'vue'
import { CRouteNames } from '@/app/contracts/CRouteNames'
import { useIsTouchDevice } from '@/app/composables/useIsTouchDevice'
import { useRouter } from 'vue-router'
import slugify from 'slugify'
import { captureWithUser } from '@/app/support/usePosthog'
import { EUserEvents } from '@/app/contracts/EUserEvents'
import { ESignInContentTypes } from '@/domain/Authentication/contracts/ESignInContentTypes'

const { isTouchDevice } = useIsTouchDevice()
const { resolve: resolveRoute } = useRouter()

const props = defineProps<TDirectoryEntry>()

const isVisible = computed(() => {
  return props.title && props.teaserImage
})

const navigationTarget = computed(() => {
  if (props.source.type === 'copy') {
    const url = new URL(props.source.value)
    const query = new URLSearchParams(url.search)
    query.append('directory_entry_id', props.id)
    query.append('case_title', props.title)
    query.append('case_type', 'directory-copy')
    url.search = query.toString()

    return url.href
  }

  return resolveRoute({
    name: CRouteNames.documents.create,
    query: {
      prompt: props.source.value,
      loading_style: 'skeleton',
      directory_entry_id: props.id,
      case_title: props.title,
    },
  }).href
})

const slug = computed(() => {
  if (!props.title) {
    return undefined
  }

  return slugify(props.title)
})

const { currentRoute } = useRouter()
const trackSelection = (props: TDirectoryEntry) => {
  captureWithUser(EUserEvents.selectedACase, {
    case_title: props.title,
    case_type:
      props.source.type === 'copy'
        ? ESignInContentTypes.caseFromDirectory
        : ESignInContentTypes.promptFromDirectory,
    route: currentRoute.value.name,
    route_query: { ...currentRoute.value.query },
    route_params: { ...currentRoute.value.params },
  })
  window.location.href = navigationTarget.value
}
</script>

<style scoped></style>
