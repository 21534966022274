<template>
  <svg
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    :class="[componentName]"
  >
    <path
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M9 9a3 3 0 0 1 3-3m-2 15h4m0-3c0-4.1 4-4.9 4-9A6 6 0 1 0 6 9c0 4 4 5 4 9h4Z"
    />
  </svg>
</template>

<script lang="ts">
export const componentName = ''
</script>

<script lang="ts" setup>
import { defineOptions } from 'vue'

defineOptions({
  name: componentName,
})
</script>

<style scoped></style>
