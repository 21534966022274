<template>
  <div :class="containerClassesComputed">
    <h2 :class="titleClassesComputed" v-html="props.title" />
    <div
      v-for="(image, imageIndex) in props.images"
      :key="imageIndex"
      :class="
        twMerge(
          imageContainerClasses({ type: props.type, class: image.containerClasses }),
        )
      "
    >
      <img
        loading="lazy"
        :src="image.src"
        :alt="image.alt"
        :class="twMerge(imageClasses({ type: props.type, class: image.classes }))"
      />
    </div>
  </div>
</template>

<script lang="ts">
export const componentName = 'ComparisonTableRow'
</script>

<script lang="ts" setup>
import { computed, defineOptions } from 'vue'
import { cva } from 'class-variance-authority'
import { twMerge } from 'tailwind-merge'
import { ETableItem } from '@/domain/Content/contracts/ETableItem'

defineOptions({
  name: componentName,
})

const props = withDefaults(
  defineProps<{
    type?: ETableItem
    containerClasses?: string
    title?: string
    titleClasses?: string
    images?: {
      src?: string
      alt?: string
      classes?: string
      containerClasses?: string
    }[]
  }>(),
  {
    type: ETableItem.ROW,
    containerClasses: undefined,
    title: undefined,
    titleClasses: undefined,
    images: () => [],
  },
)

const containerClassesComputed = computed(() =>
  twMerge(
    cva('flex gap-4 items-center px-1 xs:px-4 w-full', {
      variants: {
        type: {
          [ETableItem.HEAD]: 'bg-red-50 py-2 lg:py-4',
          [ETableItem.ROW]: 'bg-base-150 py-4 lg:py-6',
        },
      },
    })({
      type: props.type,
      class: props.containerClasses,
    }),
  ),
)

const titleClassesComputed = computed(() =>
  twMerge(
    cva('flex-1 shrink self-stretch my-auto text-base', {
      variants: {
        type: {
          [ETableItem.HEAD]: 'text-base-500',
          [ETableItem.ROW]: 'text-base-content lg:text-xl font-semibold',
        },
      },
    })({
      type: props.type,
      class: props.titleClasses,
    }),
  ),
)

const imageContainerClasses = cva(
  'flex overflow-hidden flex-1 shrink justify-center items-center my-auto max-w-[56px] lg:max-w-[120px]',
  {
    variants: {
      type: {
        [ETableItem.HEAD]: '',
        [ETableItem.ROW]: '',
      },
    },
  },
)

const imageClasses = cva('object-contain my-auto w-5', {
  variants: {
    type: {
      [ETableItem.HEAD]: '',
      [ETableItem.ROW]: '',
    },
  },
})
</script>

<style scoped lang="postcss"></style>
