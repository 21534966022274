import { CardTypeProps } from '@/domain/cards/support/interfaces'
import { createSharedComposable, useDebounceFn, useStorage } from '@vueuse/core'
import { computed, readonly, ref } from 'vue'
import { useTopicsStore } from '@/app/services/useTopicsStore'
import { useCardsStore } from '@/domain/cards/services/useCardsStore'
import { useTheBlockStore } from '@/domain/blockList/services/useTheBlockStore'

const { restore: restoreBlock } = useTheBlockStore()

export type LastDeletedProp = {
  type: CardTypeProps | 'block' | 'theBlock'
  id: string
  value?: any
}

const NOTIFICATION_TIMEOUT_IN_MS = 10 * 1000

export const useRestoreHistory = createSharedComposable(() => {
  const _lastDeletedState = useStorage<LastDeletedProp>(
    'last-deleted',
    {} as LastDeletedProp,
  )
  const _showNotificationState = ref(false)

  const hideNotificationAfterDelay = useDebounceFn(
    () => (_showNotificationState.value = false),
    NOTIFICATION_TIMEOUT_IN_MS,
  )

  const setUndoNotification = (value: LastDeletedProp) => {
    _showNotificationState.value = true
    _lastDeletedState.value = value
    hideNotificationAfterDelay()
  }

  const doUndo = async () => {
    _showNotificationState.value = false

    const { type } = _lastDeletedState.value
    switch (type) {
      case 'theBlock':
        await restoreBlock(_lastDeletedState.value.id)
        break
      case 'topic':
        await useTopicsStore().restoreLastTopic()
        break
      default:
        await useCardsStore().restoreLastCard()
        break
    }
  }

  const lastDeletedTypeName = computed(() => {
    const type = _lastDeletedState.value.type

    if (type === 'block' || type === 'theBlock') {
      return 'block'
    }

    if (type === 'topic') {
      return 'topic'
    }

    return 'card' // in case of type === 'parent' || type === 'child'
  })

  return {
    // state
    lastDeleted: readonly(_lastDeletedState),
    showNotification: readonly(_showNotificationState),
    // getters / computed
    lastDeletedTypeName,
    // actions
    setUndoNotification,
    undoLastAction: doUndo,
  }
})
