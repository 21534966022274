import { ITheBlock } from '@/domain/blockList/contracts/ITheBlock'
import { TIdentifiedBlockPayloadSchemaGuardAction } from '@/domain/blockList/contracts/TIdentifiedBlockPayloadSchemaGuardAction'
import { EBlockType } from '@/domain/blockList/contracts/EBlockType'

export const textBlockPayloadSchemaGuardAction: TIdentifiedBlockPayloadSchemaGuardAction =
  [
    EBlockType.TEXT,
    (block: ITheBlock): Promise<ITheBlock> => {
      block.attributes.payload = {
        value: block.attributes.payload?.value ?? '',
      }

      return Promise.resolve(block)
    },
  ]
