export enum EBlockType {
  TEXT = '694b10c6-2f99-4b1d-aafd-5c54900b0cf8',
  H1 = '25fe4020-6b5e-4774-b753-82a8b6f018f1',
  H2 = '25fe4020-6b5e-4774-b753-82a8b6f018f2',
  H3 = '25fe4020-6b5e-4774-b753-82a8b6f018f3',
  CHECKBOX = 'e30c6133-bd05-405d-a47c-688899f925f4',
  IMAGE = '78d6a703-9f63-4635-9e51-cdd60f804dd4',
  VIDEO = 'c14046f0-c6b5-4130-9920-5b9f0b123b7e',
  LINK = '45fe4020-6b5e-4774-b753-82a8b6f018f1',
}
