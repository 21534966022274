<template>
  <svg
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    :class="[componentName]"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M12 6h8m-8 6h8m-8 6h8M4 16a2 2 0 1 1 3.321 1.5L4 20h5M4 5l2-1v6m-2 0h4"
    />
  </svg>
</template>

<script lang="ts">
export const componentName = 'FlowBiteOrderedListIcon'
</script>

<script lang="ts" setup>
import { defineOptions } from 'vue'

defineOptions({
  name: componentName,
})
</script>

<style scoped></style>
