import QueryStringAddon from 'wretch/addons/queryString'
import baseClient from 'wretch'
import { useConfig } from '@/app/services/useConfig'

export const builderIoClientFactory = (apiKey?: string) => {
  const resolvedApiKey = apiKey ?? useConfig().get().builderIO.publicApiKey

  return baseClient('https://cdn.builder.io/api/v3/content')
    .addon(QueryStringAddon)
    .query({ apiKey: resolvedApiKey })
    .headers({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    })
    .errorType('json')
    .resolve((r) =>
      r.json((json) => {
        return json.results.map((item) => ({
          id: item.id,
          type: item.modelId,
          attributes: { ...item.data },
        }))
      }),
    )
}
