import type { RegisteredComponent } from '@builder.io/sdk-vue'
import component from '@/domain/Authentication/components/SignInWithEmailForm.vue'
import { ESignInWithMagicUriFormUsageContext } from '@/domain/Authentication/contracts/ESignInWithMagicUriFormUsageContext'
import { toKeyValue } from '@/domain/Content/support/toKeyValue'
import { CBookModel } from '@/domain/Book/contracts/CBookModel'

export const SignInWithEmailFormComponentRegistration = (
  shouldWrap = true,
): RegisteredComponent => ({
  component: component,
  friendlyName: 'Email SignIn CTA',
  name: 'SignInWithEmailForm',
  noWrap: !shouldWrap,
  canHaveChildren: false,
  inputs: [
    {
      friendlyName: 'Use page content',
      name: 'usePageContent',
      type: 'boolean',
      helperText: 'Derive content from enclosing page instead of manually adding.',
      defaultValue: false,
    },
    {
      friendlyName: 'Define page content model',
      name: 'pageContentModel',
      type: 'string',
      enum: ['page', CBookModel.NAME],
      defaultValue: CBookModel.NAME,
      showIf(options) {
        return options.get('usePageContent') === true
      },
    },
    {
      name: 'classes',
      type: 'object',
      advanced: true,
      subFields: [
        {
          name: 'container',
          type: 'text',
        },
      ],
    },
    {
      friendlyName: 'Usage Context',
      name: 'usageContext',
      type: 'string',
      enum: toKeyValue(ESignInWithMagicUriFormUsageContext),
    },
    {
      friendlyName: 'Placeholder',
      name: 'inputPlaceholder',
      type: 'string',
      defaultValue: 'Email: you@example.com',
    },
    {
      friendlyName: 'Action Title',
      name: 'actionTitle',
      type: 'string',
      defaultValue: 'Sign in with email',
    },
    {
      friendlyName: 'Action Target',
      name: 'actionTarget',
      type: 'string',
      showIf(options) {
        return options.get('usePageContent') !== true
      },
    },
  ],
})
