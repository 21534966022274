import { type RegisteredComponent } from '@builder.io/sdk-vue'
import component from '@/domain/Content/components/FaqSection.vue'

export const FaqSectionRegistration = (shouldWrap = true): RegisteredComponent => ({
  component: component,
  friendlyName: 'FAQ Section',
  name: 'FaqSection',
  noWrap: !shouldWrap,
  canHaveChildren: false,
  inputs: [
    {
      friendlyName: 'Section Title',
      name: 'title',
      type: 'longText',
    },
    {
      friendlyName: 'Description',
      name: 'sublines',
      type: 'list',
      defaultValue: [],
      subFields: [
        {
          friendlyName: 'Paragraph',
          name: 'text',
          type: 'longText',
        },
      ],
    },
    {
      friendlyName: 'Container Classes',
      name: 'containerClasses',
      type: 'string',
      defaultValue: 'py-3 lg:py-4',
    },
    {
      friendlyName: 'FAQ entries',
      name: 'faqs',
      type: 'list',
      defaultValue: [],
      subFields: [
        {
          name: 'question',
          type: 'longText',
        },
        {
          name: 'answer',
          type: 'longText',
        },
      ],
    },
  ],
})
