import { useStorage } from '@vueuse/core'
import { nextTick, readonly } from 'vue'

const defaultState = { code: undefined, state: undefined }
const _linkedInCode = useStorage<{ code?: string; state?: string }>(
  'linkedin_code_store',
  { ...defaultState },
)

export const useLinkedInAuthCodeStore = () => {
  return {
    state: readonly(_linkedInCode),
    collect: (code: string, state: string) => {
      _linkedInCode.value.code = code
      _linkedInCode.value.state = state
    },
    generateState: () => {
      const length = 20
      let stateString = ''

      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length
      for (let i = 0; i < length; i++) {
        stateString += characters.charAt(Math.floor(Math.random() * charactersLength))
      }

      _linkedInCode.value.state = stateString

      return _linkedInCode.value.state
    },
    reset: async () => {
      await nextTick(() => (_linkedInCode.value = {}))
    },
    hasMatchingState(state?: string) {
      if (!state) {
        return false
      }

      return _linkedInCode.value.state === state
    },
  }
}
