import { Ref } from 'vue'
import type { BuilderContent } from '@builder.io/sdk-vue/lib/node/types/builder-content'
import { CBookModel } from '@/domain/Book/contracts/CBookModel'

export const toSpecs = (page: Readonly<Ref<BuilderContent | null | undefined>>) => {
  if (!page || !page.value) {
    return []
  }

  if (page.value.modelId !== CBookModel.ID) {
    throw new Error(
      `Model id is not supported required '${CBookModel.ID}' but got '${page.value.modelId}'`,
    )
  }

  return page?.value?.data?.specs
}
