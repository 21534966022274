<template>
  <component :is="props.tag" v-if="props.wrap" :class="props.containerClasses">
    <slot name="element" />
  </component>
  <slot v-else name="element" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const componentName = 'ConditionalElementWrapper'
export default defineComponent({
  name: componentName,
})
</script>

<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    tag?: string
    containerClasses?: string
    wrap: boolean
  }>(),
  {
    tag: 'div',
    wrap: true,
    containerClasses: '',
  },
)
</script>

<style scoped></style>
