import { readonly, ref, Ref, watch } from 'vue'
import { useStorage, useUrlSearchParams } from '@vueuse/core'
import { signInWithGoogle } from '@/domain/Authentication/services/signInWithGoogle'
import { EAuthenticationProviderSignInFlowStates } from '@/domain/Authentication/contracts/EAuthenticationProviderSignInFlowStates'
import { EAuthenticationProviders } from '@/domain/Authentication/contracts/EAuthenticationProviders'
import type { TSignInContext } from '@/domain/Authentication/contracts/TSignInContext'
import type { TSignInWithProviderAction } from '@/domain/Authentication/contracts/TSignInWithProviderAction'
import type { TRouteResolver } from '@/domain/Authentication/contracts/TRouteResolver'
import type { TCurrentAuthenticationState } from '@/domain/Authentication/contracts/TCurrentAuthenticationState'
import { captureWithUser, usePosthog } from '@/app/support/usePosthog'
import { EAuthenticationEvents } from '@/domain/Authentication/contracts/EAuthenticationEvents'
import { signInWithX } from '@/domain/Authentication/services/signInWithX'
import { signInWithLinkedIn } from '@/domain/Authentication/services/signInWithLinkedIn'
import type { TSignInOptions } from '@/domain/Authentication/contracts/TSignInOptions'
import { signInWithMagicSignIn } from '@/domain/Authentication/services/signInWithMagicSignIn'
import type { RouteLocation } from 'vue-router'
import miljnApiBaseClientFactory from '@/app/services/miljnApiBaseClientFactory'
import { getSharingInformation } from '@/domain/cards/services/cardClient'

const defaultSignInContext = {
  share_id: undefined,
  directory_entry_id: undefined,
  case_title: undefined,
  case_type: undefined,
  prompt: undefined,
  loading_style: undefined,
}

const _signInContext = useStorage<TSignInContext>('signInContext', defaultSignInContext)
const _mergeSignContext = (newContext: TSignInContext) =>
  (_signInContext.value = { ..._signInContext.value, ...newContext })

const _jwt = useStorage<string>('mjn_jwt', null)
const _storeJwt = (jwt: string) => (_jwt.value = jwt)
const _removeJwt = () => (_jwt.value = undefined)

const _status = ref<EAuthenticationProviderSignInFlowStates>(
  EAuthenticationProviderSignInFlowStates.UNDEFINED,
)
const _setStatus = (status: EAuthenticationProviderSignInFlowStates) =>
  (_status.value = status)

const _selectedProvider = ref<EAuthenticationProviders>(
  EAuthenticationProviders.UNDEFINED,
)
const _authenticationPayload: Ref<unknown | undefined> = ref(undefined)
const _routeResolver = ref<TRouteResolver>()
const _triggerErrorNotification = ref<() => void>(() => ({}))

const _reset = (): TCurrentAuthenticationState => {
  const currentValues = {
    status: _status.value,
    selectedProvider: _selectedProvider.value,
    authenticationPayload: _authenticationPayload.value,
    signInContext: _signInContext.value,
  }

  _status.value = EAuthenticationProviderSignInFlowStates.UNDEFINED
  _selectedProvider.value = EAuthenticationProviders.UNDEFINED
  _authenticationPayload.value = undefined
  _signInContext.value = defaultSignInContext

  return currentValues
}

const _providerFlows: Record<string, TSignInWithProviderAction> = {
  [EAuthenticationProviders.GOOGLE]: signInWithGoogle,
  [EAuthenticationProviders.X]: signInWithX,
  [EAuthenticationProviders.LINKEDIN]: signInWithLinkedIn,
  [EAuthenticationProviders.MAGIC_SIGN_IN]: signInWithMagicSignIn,
}

const _logout = async (options: {
  userCleanup?: () => {
    user_id: string
    user_email: string
    user_name: string
  }
  navigate?: () => void
}) => {
  const userProperties = (options?.userCleanup && options?.userCleanup()) ?? {}

  const instance = miljnApiBaseClientFactory()
  await instance.delete('/authentication') // @todo add this path to api.endpoints as constant
  _removeJwt()

  captureWithUser(EAuthenticationEvents.logout, { ...userProperties })
  usePosthog().reset()

  if (options.navigate) {
    options.navigate()
  }
}

export const useAuthentication = () => {
  watch(_status, (newStatus, oldStatus) => {
    if (
      newStatus === EAuthenticationProviderSignInFlowStates.FAILED &&
      oldStatus === EAuthenticationProviderSignInFlowStates.STARTED
    ) {
      _triggerErrorNotification.value() // @todo trigger notification
      _reset()
    }
  })

  return {
    // state
    // getter
    status: readonly(_status),
    selectedProvider: readonly(_selectedProvider),
    signInContext: readonly(_signInContext),
    jwt: readonly(_jwt),

    // actions
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    signInWith: async (
      provider: EAuthenticationProviders,
      options?: TSignInOptions,
    ): Promise<void | (RouteLocation & { href: string })> => {
      _status.value = EAuthenticationProviderSignInFlowStates.STARTED
      _selectedProvider.value = provider

      const params = useUrlSearchParams()

      _signInContext.value = {
        ..._signInContext.value,
        ...params,
      }

      if (options?.relatedCopyId) {
        const information = await getSharingInformation(options?.relatedCopyId)

        _signInContext.value.share_id = options.relatedCopyId
        _signInContext.value.case_title = information?.data.attributes.documentTitle
        _signInContext.value.case_type = information?.data.attributes.type
      }

      const properties = {
        provider: provider,
        status: _status.value,
        signInContext: { ..._signInContext.value },
      }

      if (options?.stage) {
        properties['stage'] = options?.stage
      }

      captureWithUser(EAuthenticationEvents.selectedProvider, properties)

      if (!(_providerFlows[_selectedProvider.value] ?? undefined)) {
        _status.value = EAuthenticationProviderSignInFlowStates.FAILED
        const errorMessage = `provider flow not found for '${_selectedProvider.value}'`
        const errorProperties = {
          provider: provider,
          status: _status.value,
          signInContext: { ..._signInContext.value },
          errorMessage,
        }

        if (options?.stage) {
          errorProperties['stage'] = options?.stage
        }

        captureWithUser(EAuthenticationEvents.selectedProvider, errorProperties)

        throw new Error(errorMessage)
      }

      if (!_routeResolver.value) {
        throw new Error('route resolver is missing, but required')
      }

      return await _providerFlows[_selectedProvider.value](
        _status,
        _selectedProvider,
        _signInContext,
        _routeResolver.value,
        _reset,
        options,
      )
    },
    init: (routeResolver: TRouteResolver) => {
      _routeResolver.value = routeResolver
    },
    setStatus: _setStatus,
    storeJwt: _storeJwt,
    removeJwt: _removeJwt,
    mergeSignContext: _mergeSignContext,
    reset: _reset,
    logout: _logout,
  }
}
