import { computed, onMounted, ref, type Ref, toRaw, watch } from 'vue'
import { useMagicKeys, useUrlSearchParams } from '@vueuse/core'
import { isEmpty } from 'lodash-es'
import type { TDocumentLoaderQueryParams } from '@/domain/prompt/contracts/TDocumentLoaderQueryParams'
import { useUserStore } from '@/app/services/useUserStore'
import { createDocument } from '@/domain/documents/services/documentClient'

export const usePrompt = (
  prompt: Ref<string>,
  isPromptInputFocused: Ref<boolean>,
  redirectToSolutionAction: (
    uuid: string,
    query: TDocumentLoaderQueryParams,
  ) => Promise<void>,
  redirectToLoginFirstAction: (query: TDocumentLoaderQueryParams) => Promise<void>,
  afterRedirectCallback?: (promptValue: string) => Promise<void>,
) => {
  const _params = useUrlSearchParams<TDocumentLoaderQueryParams>('history')
  const _isSubmitting = ref(false)

  const ensureQueryParamsArePresent = (
    params: TDocumentLoaderQueryParams,
    prompt: string,
  ) => {
    params['prompt'] = prompt
    params['loading_style'] = 'skeleton'
  }
  const ensureQueryParamsAreAbsent = (params: TDocumentLoaderQueryParams) => {
    delete params['prompt']
    delete params['loading_style']
  }

  const _doSubmit = async () => {
    _isSubmitting.value = true

    const prompt = _params.prompt
    if (!prompt) {
      // eslint-disable-next-line no-console
      console.error(`title cannot be empty`)
      return
    }

    if (!useUserStore().isLoggedIn.value) {
      await redirectToLoginFirstAction({ ...toRaw(_params) })
      if (afterRedirectCallback) {
        afterRedirectCallback(prompt)
      }
      return
    }

    const result = await createDocument(prompt as string)
    _isSubmitting.value = false

    if (!result?.id) {
      // eslint-disable-next-line no-console
      console.error(`creating document failed for '${prompt}'`)
      return
    }

    await redirectToSolutionAction(result.id, { ...toRaw(_params) })

    if (afterRedirectCallback) {
      afterRedirectCallback(prompt)
    }
  }

  onMounted(() => {
    if (!isEmpty(_params?.prompt)) {
      prompt.value = _params.prompt as string
    }
  })

  watch(prompt, (newPromptValue) => {
    if (isEmpty(newPromptValue)) {
      ensureQueryParamsAreAbsent(_params)
    } else {
      ensureQueryParamsArePresent(_params, newPromptValue)
    }
  })

  const keys = useMagicKeys()
  const metaEnter = keys['Meta+Enter']
  watch(
    [metaEnter, isPromptInputFocused],
    async ([newMetaEnter, newIsPromptInputFocused]) => {
      if (newMetaEnter && newIsPromptInputFocused) {
        await _doSubmit()
      }
    },
  )

  return {
    isSubmissionDisabled: computed(() => isEmpty(prompt.value)),
    isSubmitting: computed(() => _isSubmitting.value),
    submit: _doSubmit,
  }
}
