import type { TDirectoryEntryData } from '@/domain/directory/contracts/TDirectoryEntryData'
import type { TDirectoryEntry } from '@/domain/directory/contracts/TDirectoryEntry'
import { categoryDataTransformer } from '@/domain/directory/services/categoryDataTransformer'
import { expertDataTransformer } from '@/domain/directory/services/expertDataTransformer'
import type { TExpert } from '@/domain/directory/contracts/TExpert'

export const directoryEntriesDataTransformer = (
  entryData: TDirectoryEntryData,
): TDirectoryEntry => {
  return {
    id: entryData.id,
    title: entryData.data.title,
    teaserImage: entryData.data.images.teaser,
    isPremium: entryData?.data?.metadata?.isPremiumEntry ?? false,
    source: {
      type: entryData.data.source.type,
      value: entryData.data.source.payload.value,
      context: entryData.data.source.payload.context ?? undefined,
    },
    category: categoryDataTransformer(entryData.data?.category?.value),
    experts: (entryData.data?.experts ?? []).reduce((acc, entry) => {
      if (entry.expert) {
        acc.push(expertDataTransformer(entry.expert.value))
      }
      return acc
    }, [] as TExpert[]),
  }
}
