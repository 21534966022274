export enum EUserEvents {
  visited = 'user_visited',
  triedToVisit = 'user_tried_to_visit',
  resetUserData = 'user_reset_user_data', // @todo should be moved to `User` domain
  changedPromptValue = 'user_changed_prompt_value',
  selectedACase = 'user_selected_a_case',
  inspectedACase = 'user_user_inspected_a_case',
  clicked = 'user_clicked',
  triggeredCta = 'user_triggered_cta',
}
