import { type RegisteredComponent } from '@builder.io/sdk-vue'
import component from '@/domain/directory/components/DirectorySection.vue'

export const DirectorySectionRegistration = (shouldWrap = true): RegisteredComponent => ({
  component: component,
  name: 'Directory Section',
  noWrap: !shouldWrap,
  canHaveChildren: true,
  inputs: [
    {
      friendlyName: 'Title',
      name: 'title',
      type: 'string',
      defaultValue: 'Discover Guides',
    },
    {
      friendlyName: 'Title Classes',
      name: 'titleClasses',
      type: 'string',
      defaultValue: 'font-extrabold text-base-content text-3xl',
    },
    {
      friendlyName: 'Load all available entries',
      name: 'useAllEntries',
      type: 'boolean',
      defaultValue: true,
    },
    {
      friendlyName: 'Enable category selector',
      name: 'enableCategorySelector',
      type: 'boolean',
      defaultValue: true,
    },
    {
      friendlyName: 'Container Classes',
      name: 'containerClasses',
      defaultValue: 'mt-12 mb-6',
      type: 'string',
    },
    {
      friendlyName: 'Select Entries',
      name: 'selectedEntries',
      type: 'list',
      subFields: [
        {
          name: 'Entry',
          type: 'reference',
          model: 'directory-entries',
        },
      ],
    },
  ],
})
