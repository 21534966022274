import { type RegisteredComponent } from '@builder.io/sdk-vue'
import component from '@/domain/Content/components/ContentCanvas.vue'
import { EProseTypes } from '@/app/contracts/EProseTypes'

export const ContentCanvasRegistration = (shouldWrap = true): RegisteredComponent => ({
  component: component,
  name: 'Content Canvas',
  noWrap: !shouldWrap,
  canHaveChildren: true,
  inputs: [
    {
      friendlyName: 'Enable prose content styling',
      name: 'useProse',
      type: 'boolean',
      defaultValue: true,
    },
    {
      friendlyName: 'Type of prose styling classes',
      name: 'proseType',
      type: 'string',
      enum: Object.values(EProseTypes),
      defaultValue: EProseTypes.marketing,
    },
  ],
})
