import posthog, { type CaptureOptions, type Properties } from 'posthog-js'
import { useConfig } from '@/app/services/useConfig'
import { useUserStore } from '@/app/services/useUserStore'
import { useLocalLogger } from '@/app/composables/useLocalLogger'

const mockActionFactory =
  (type: string) =>
  (...args: unknown[]) => {
    // eslint-disable-next-line no-console
    console.log(`⚠️  posthog.${type}`, ...args)

    if (useConfig().get().environment !== 'production') {
      useLocalLogger().log((args[0] as string) ?? 'unknown', args[1])
    }
  }

export const usePosthog = () => {
  const fakePosthog = {
    capture: mockActionFactory('capture'),
    identify: mockActionFactory('identify'),
    reset: mockActionFactory('reset'),
  }
  try {
    return useConfig().get().posthog.isEnabled && posthog ? posthog : fakePosthog
  } catch {
    return fakePosthog
  }
}

export const captureWithUser = (
  eventName: string,
  properties?: Properties | null,
  options?: CaptureOptions,
) => {
  const { userId, email } = useUserStore()

  const resolvedProperties = {
    user_id: userId.value,
    user_email: email.value,
    ...(properties ? properties : {}),
  }

  try {
    usePosthog().capture(eventName, resolvedProperties, options)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('⚠️ posthog submit failed', eventName, resolvedProperties, e)
  }
}
