<template>
  <component
    :is="props.tag"
    :class="
      cva(['flex', 'flex-row', 'flex-wrap', 'gap-2', 'items-center'])({
        class: [props.containerClasses, componentName],
      })
    "
  >
    <component :is="icon" class="w-5 h-5 text-base-content" />
    <span class="">{{ props.value }}</span>
  </component>
</template>

<script lang="ts">
export const componentName = 'SpecItem'
</script>

<script lang="ts" setup>
import { computed, defineOptions } from 'vue'
import { ESpecTypes } from '@/domain/Content/contracts/ESpecTypes'
import { cva } from 'class-variance-authority'
import FlowBiteStarOutlineIcon from '@/domain/Content/components/icons/FlowBiteStarOutlineIcon.vue'
import FlowBiteClockOutlineIcon from '@/domain/Content/components/icons/FlowBiteClockOutlineIcon.vue'
import FlowBiteLightBulbIcon from '@/domain/Content/components/icons/FlowBiteLightBulbIcon.vue'
import FlowBiteListIcon from '@/domain/Content/components/icons/FlowBiteListIcon.vue'

defineOptions({
  name: componentName,
})

const props = withDefaults(
  defineProps<{
    tag?: string
    type: ESpecTypes
    value: string
    containerClasses?: string
    iconClasses?: string
    valueClasses?: string
  }>(),
  {
    tag: 'div',
    containerClasses: undefined,
    iconClasses: undefined,
    valueClasses: undefined,
  },
)

const icon = computed(() => {
  let iconComponent: typeof FlowBiteStarOutlineIcon

  switch (props.type) {
    case ESpecTypes.RATING:
      iconComponent = FlowBiteStarOutlineIcon
      break
    case ESpecTypes.ACTIONS:
      iconComponent = FlowBiteLightBulbIcon
      break
    case ESpecTypes.DURATION:
      iconComponent = FlowBiteClockOutlineIcon
      break
    case ESpecTypes.LIST:
      iconComponent = FlowBiteListIcon
      break
  }

  return iconComponent
})
</script>

<style scoped></style>
