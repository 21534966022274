<template>
  <div :class="containerClassesComputed">
    <p
      v-if="!isEmpty(props?.content?.category)"
      :class="categoryClassesComputed"
      v-html="props.content?.category"
    />
    <component
      :is="props.titleTag ?? 'div'"
      v-if="!isEmpty(props?.content?.title)"
      :class="titleClassesComputed"
      v-html="props.content?.title"
    />
    <p
      v-if="!isEmpty(props?.content?.subline)"
      :class="sublineClassesComputed"
      v-html="props.content?.subline"
    />
    <p
      v-if="!isEmpty(props?.content?.claim)"
      :class="claimClassesComputed"
      v-html="props?.content?.claim"
    />
  </div>
</template>

<script lang="ts">
export const componentName = 'TextHeading'
</script>

<script lang="ts" setup>
import { computed, type ComputedRef, defineOptions } from 'vue'
import { isEmpty } from 'lodash-es'
import { cva } from 'class-variance-authority'
import { twMerge } from 'tailwind-merge'
import { EHeadline } from '@/domain/Content/contracts/EHeadline'

defineOptions({
  name: componentName,
})

const props = withDefaults(
  defineProps<
    {
      titleTag?: EHeadline
      content?: {
        category?: string
        title?: string
        subline?: string
        claim?: string
      }
      classes?: {
        container?: string
        category?: string
        title?: string
        subline?: string
        claim?: string
      }
      gap?: string
    } & {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      attributes?: any
    }
  >(),
  {
    titleTag: EHeadline.H1,
    content: () => ({
      category: undefined,
      title: undefined,
      subline: undefined,
      claim: undefined,
    }),
    classes: () => ({
      container: undefined,
      category: undefined,
      title: undefined,
      subline: undefined,
      claim: undefined,
    }),
    gap: 'mt-2',
  },
)

const containerClassesComputed = computed(() =>
  twMerge(
    cva(['mt-2'])({
      class: [props.classes.container],
    }),
  ),
)

const categoryClassesComputed = computed(() =>
  twMerge(
    cva(['mt-2', 'text-base-content', 'text-left'], {
      variants: {
        titleTag: {
          [EHeadline.H1]: 'text-base font-medium',
          [EHeadline.H2]: 'text-base font-medium',
          [EHeadline.H3]: 'text-base font-medium',
          [EHeadline.H4]: 'text-base font-medium',
          [EHeadline.H5]: 'text-base font-medium',
          [EHeadline.H6]: 'text-base font-medium',
        },
      },
    })({
      titleTag: props?.titleTag,
      class: [props.classes.category],
    }),
  ),
)

const titleClassesComputed: ComputedRef<string> = computed(() =>
  twMerge(
    cva(['mt-2', 'text-base-content', 'text-left'], {
      variants: {
        titleTag: {
          [EHeadline.H1]: 'font-extrabold text-2xl',
          [EHeadline.H2]: 'font-extrabold text-xl',
          [EHeadline.H3]: 'font-extrabold text-lg',
          [EHeadline.H4]: 'font-extrabold text-md',
          [EHeadline.H5]: 'font-extrabold text-md',
          [EHeadline.H6]: 'font-extrabold text-md',
        },
      },
    })({
      titleTag: props?.titleTag,
      class: [props.classes.title],
    }),
  ),
)

const sublineClassesComputed = computed(() =>
  twMerge(
    cva(['mt-2', 'text-base-content', 'text-left'], {
      variants: {
        titleTag: {
          [EHeadline.H1]: 'text-lg font-semibold leading-tight',
          [EHeadline.H2]: 'text-lg font-semibold leading-tight',
          [EHeadline.H3]: 'text-lg font-semibold leading-tight',
          [EHeadline.H4]: 'text-lg font-semibold leading-tight',
          [EHeadline.H5]: 'text-lg font-semibold leading-tight',
          [EHeadline.H6]: 'text-lg font-semibold leading-tight',
        },
      },
    })({
      titleTag: props?.titleTag,
      class: [props.classes.subline],
    }),
  ),
)

const claimClassesComputed = computed(() =>
  twMerge(
    cva(['mt-2', 'text-base-500', 'pt-2', 'text-left'], {
      variants: {
        titleTag: {
          [EHeadline.H1]: 'text-base',
          [EHeadline.H2]: 'text-base',
          [EHeadline.H3]: 'text-base',
          [EHeadline.H4]: 'text-base',
          [EHeadline.H5]: 'text-base',
          [EHeadline.H6]: 'text-base',
        },
      },
    })({
      titleTag: props?.titleTag,
      class: [props.classes.claim],
    }),
  ),
)
</script>

<style scoped></style>
