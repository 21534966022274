import type { RegisteredComponent } from '@builder.io/sdk-vue'
import component from '@/domain/Content/components/CaseSourceAuthorSection.vue'

export const CaseSourceAuthorSectionComponentRegistration = (
  shouldWrap = true,
): RegisteredComponent => ({
  component: component,
  name: 'CaseSourceAuthorSection',
  friendlyName: 'CaseSourceAuthorSection',
  noWrap: !shouldWrap,
  inputs: [
    {
      name: 'name',
      type: 'text',
    },
    {
      name: 'content',
      type: 'longText',
    },
    {
      friendlyName: 'Container Classes',
      name: 'containerClasses',
      defaultValue: 'mt-12 mb-6',
      type: 'string',
    },
    {
      name: 'image',
      friendlyName: 'Image',
      type: 'file',
      allowedFileTypes: ['jpeg', 'jpg', 'png'],
    },
  ],
})
