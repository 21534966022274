<template>
  <svg
    :class="[componentName]"
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M14.99 9H15M8.99 9H9m12 3a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM7 13c0 1 .507 2.397 1.494 3.216a5.5 5.5 0 0 0 7.022 0C16.503 15.397 17 14 17 13c0 0-1.99 1-4.995 1S7 13 7 13Z"
    />
  </svg>
</template>

<script lang="ts">
export const componentName = 'FlowBiteFaceGrinOutlineIcon'
</script>

<script lang="ts" setup>
import { defineOptions } from 'vue'

defineOptions({
  name: componentName,
})
</script>

<style scoped></style>
