import { useStorage } from '@vueuse/core'
import { useConfig } from '@/app/services/useConfig'
import { toRaw } from 'vue'

const _storageName = 'trackingLogger'
const _logStore = useStorage<
  {
    event: string
    payload: unknown
    timestamp: string
  }[]
>(_storageName, [])

const _reset = () => (_logStore.value = [])

type TPosthogLoggerHelper = {
  events: () => unknown[]
  eventsAsJson: () => string | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reset: () => any[]
}
type TWindowMiljnHelperWithPosthog = Window &
  typeof globalThis & { _mjn: unknown & { posthog: TPosthogLoggerHelper } }

export const useLocalLogger = () => {
  if (
    useConfig().get().environment !== 'production' &&
    !(window as TWindowMiljnHelperWithPosthog)?._mjn?.posthog
  ) {
    ;(window as TWindowMiljnHelperWithPosthog)['_mjn'] = {
      posthog: {
        events: () => {
          // eslint-disable-next-line no-console
          console.log('❌PH events', _logStore.value)
          return toRaw(_logStore.value)
        },
        eventsAsJson: () => {
          const json = localStorage.getItem(_storageName)
          // eslint-disable-next-line no-console
          console.log('❌PH events (json)', json)
          return json
        },
        reset: _reset,
      },
    }
  }

  return {
    log: (eventName: string, payload: unknown) => {
      const timestamp = new Date().toISOString()
      _logStore.value.push({ event: eventName, payload, timestamp })
    },
    reset: _reset,
  }
}
