import { type RegisteredComponent } from '@builder.io/sdk-vue'
import component from '@/domain/prompt/components/ScrollTarget.vue'

export const ScrollTargetRegistration = (shouldWrap = true): RegisteredComponent => ({
  component: component,
  name: 'Prompt Input Scroll target',
  noWrap: !shouldWrap,
  inputs: [
    {
      name: 'scrollTargetId',
      type: 'string',
      defaultValue: 'prompt-input-scroll-target',
    },
  ],
})
