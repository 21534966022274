import { setupDevtoolsPlugin } from '@vue/devtools-api'
import { App, isReactive, isRef } from 'vue'
import { useGlobalStore } from '@/app/support/useGlobalStore'
import { Environment } from './Environment'
import { EEnvironment } from '@/app/contracts/EEnvironment'

function setupDevtools(app: App) {
  const inspectorId = 'miljn-global-store'
  const { stores } = useGlobalStore()

  setupDevtoolsPlugin(
    {
      id: 'miljn-global-store-plugin',
      label: 'Miljn Specific',
      packageName: 'miljn-global-store',
      app: app as object,
    },
    (api) => {
      api.addInspector({
        id: inspectorId,
        label: 'Miljn Global Store',
        icon: 'eject',
      })

      api.on.getInspectorTree((payload) => {
        if (payload.inspectorId === inspectorId) {
          payload.rootNodes = Object.entries(stores.value).map(([key]) => {
            return {
              id: key,
              label: key,
            }
          })
        }
      })

      api.on.getInspectorState((payload) => {
        if (payload.inspectorId === inspectorId) {
          Object.entries(stores.value).forEach(([key, store]) => {
            if (payload.nodeId === key) {
              payload.state = {
                state: [],
              }
              Object.entries(store()).forEach(([key, value]) => {
                if (isRef(value)) {
                  payload.state.state.push({
                    key: key,
                    value: value?.value,
                  })
                }
                if (isReactive(value)) {
                  payload.state.state.push({
                    key: key,
                    value: value,
                  })
                }
              })
            }
          })
        }
      })
      const tick = () => {
        api.sendInspectorState(inspectorId)
        window.requestAnimationFrame(tick)
      }

      tick()
    },
  )
}

export const GlobalStatePlugin = {
  install(app: App) {
    if (Environment.is(EEnvironment.PRODUCTION)) {
      setupDevtools(app)
    }
  },
}
