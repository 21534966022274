import { TShallowReactiveItemList } from '@/domain/LinkedList/contracts/TShallowReactiveItemList'
import { TSortedItemList } from '@/domain/LinkedList/contracts/TSortedItemList'
import { ShallowRef } from '@vue/reactivity'
import { unref } from 'vue'
import { IFlattenedLinkableItem } from '@/domain/LinkedList/contracts/IFlattenedLinkableItem'

export const toSortedFlattenedLinkedItemList = <T extends IFlattenedLinkableItem>(
  source: TShallowReactiveItemList<T>,
): TSortedItemList<T> => {
  const sourceItemList = Object.values(source)

  const startItemIndex = sourceItemList.findIndex(
    (item) => unref(item).attributes.previousId === null,
  )

  const sortedItemList: ShallowRef<T>[] = []
  sortedItemList.push(...sourceItemList.splice(startItemIndex, 1))

  while (sourceItemList.length > 0) {
    const pickedItem = sortedItemList[sortedItemList.length - 1]
    const searchId = unref(pickedItem).id

    const nextItemIndex = sourceItemList.findIndex(
      (item) => unref(item).attributes.previousId === searchId,
    )

    sortedItemList.push(...sourceItemList.splice(nextItemIndex, 1))
  }

  return sortedItemList
}
