import type { RegisteredComponent } from '@builder.io/sdk-vue'
import component from '@/domain/Content/components/CaseTestimonialsSection.vue'

export const CaseTestimonialsSectionComponentRegistration = (
  shouldWrap = true,
): RegisteredComponent => ({
  component: component,
  name: 'CaseTestimonialsSection',
  friendlyName: 'CaseTestimonialsSection',
  noWrap: !shouldWrap,
  inputs: [
    {
      name: 'category',
      type: 'longText',
    },
    {
      name: 'title',
      type: 'longText',
    },
    {
      name: 'subline',
      type: 'longText',
    },
    {
      friendlyName: 'Container Classes',
      name: 'containerClasses',
      defaultValue: 'mt-12 mb-6',
      type: 'string',
    },
    {
      friendlyName: 'testimonials',
      name: 'testimonials',
      type: 'list',
      defaultValue: [],
      subFields: [
        {
          name: 'image',
          friendlyName: 'Image',
          type: 'file',
          allowedFileTypes: ['jpeg', 'jpg', 'png'],
        },
        {
          name: 'name',
          type: 'text',
        },
        {
          name: 'hasCheckMark',
          type: 'boolean',
        },
        {
          name: 'handle',
          type: 'text',
        },
        {
          name: 'content',
          type: 'longText',
        },
      ],
    },
  ],
})
