<template>
  <svg
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    :class="[componentName]"
  >
    <path
      stroke="currentColor"
      stroke-linecap="round"
      stroke-width="2"
      d="M9 8h10M9 12h10M9 16h10M4.99 8H5m-.02 4h.01m0 4H5"
    />
  </svg>
</template>

<script lang="ts">
export const componentName = 'FlowBiteListIcon'
</script>

<script lang="ts" setup>
import { defineOptions } from 'vue'

defineOptions({
  name: componentName,
})
</script>

<style scoped></style>
