import miljnApiBaseClientFactory from '@/app/services/miljnApiBaseClientFactory'
import { theBlockApiEndpoints } from '@/domain/blockList/support/theBlockApiEndpoint'
import { setPreferHeader } from '@/app/services/preferHeaderResolver'
import { IViewAnyTheBlockResponse } from '@/domain/blockList/contracts/IViewAnyTheBlockResponse'
import { ITheBlock } from '@/domain/blockList/contracts/ITheBlock'
import { IViewTheBlockResponse } from '@/domain/blockList/contracts/IViewTheBlockResponse'
import { IViewAnyShallowTheBlockResponse } from '@/domain/blockList/contracts/IViewAnyShallowTheBlockResponse'
import { IShallowTheBlock } from '@/domain/blockList/contracts/IShallowTheBlock'
import { TReorderBlocksAction } from '@/domain/blockList/contracts/TBlockActions'

export const viewAny = async (nodeId: string): Promise<ITheBlock[]> => {
  const instance = miljnApiBaseClientFactory()
  const requestConfig = setPreferHeader({}, 'theBlock')

  return (
    (
      await instance.get<IViewAnyTheBlockResponse>(
        theBlockApiEndpoints.viewAny(nodeId),
        requestConfig,
      )
    ).data.data ?? []
  )
}

export const create = async (newBlock: ITheBlock): Promise<IShallowTheBlock[]> => {
  const instance = miljnApiBaseClientFactory()
  const requestConfig = setPreferHeader({}, 'theBlock')

  return (
    (
      await instance.post<IViewAnyShallowTheBlockResponse>(
        theBlockApiEndpoints.create(newBlock.attributes.nodeId),
        {
          data: newBlock,
        },
        requestConfig,
      )
    )?.data?.data ?? []
  )
}

export const reorder: TReorderBlocksAction = async (
  reorderedTheBlocks: ITheBlock[],
): Promise<IShallowTheBlock[]> => {
  const shallowReorderedTheBlocks: IShallowTheBlock[] = []

  for (const theBlock of reorderedTheBlocks) {
    shallowReorderedTheBlocks.push({
      id: theBlock.id,
      type: 'shallowTheBlock',
      attributes: {
        nodeId: theBlock.attributes.nodeId,
        previousId: theBlock.attributes.previousId,
      },
    })
  }

  const instance = miljnApiBaseClientFactory()
  const requestConfig = setPreferHeader({}, 'theBlock')

  const nodeId = shallowReorderedTheBlocks[0].attributes.nodeId
  return (
    await instance.post<IViewAnyShallowTheBlockResponse>(
      theBlockApiEndpoints.reorder(nodeId),
      {
        data: shallowReorderedTheBlocks,
      },
      requestConfig,
    )
  ).data.data
}

export const update = async (updatedBlock: ITheBlock): Promise<ITheBlock | undefined> => {
  const instance = miljnApiBaseClientFactory()
  const requestConfig = setPreferHeader({}, 'theBlock')

  return (
    await instance.put<IViewTheBlockResponse>(
      theBlockApiEndpoints.update(updatedBlock.id),
      {
        data: updatedBlock,
      },
      requestConfig,
    )
  ).data.data
}

export const remove = async (removedBlockId: string): Promise<IShallowTheBlock[]> => {
  const instance = miljnApiBaseClientFactory()
  const requestConfig = setPreferHeader({}, 'theBlock')

  return (
    (
      await instance.delete<IViewAnyShallowTheBlockResponse>(
        theBlockApiEndpoints.remove(removedBlockId),
        requestConfig,
      )
    )?.data?.data ?? []
  )
}

export const restore = async (removedBlockId: string): Promise<ITheBlock[]> => {
  const instance = miljnApiBaseClientFactory()
  const requestConfig = setPreferHeader({}, 'theBlock')

  return (
    (
      await instance.post<IViewAnyTheBlockResponse>(
        theBlockApiEndpoints.restore(removedBlockId),
        requestConfig,
      )
    )?.data?.data ?? []
  )
}
