<template>
  <header
    class="flex flex-col items-end justify-center font-sans font-medium leading-[150%] w-full min-h-nav max-h-nav"
    :class="[componentName]"
  >
    <nav class="flex justify-between gap-4 w-full">
      <RouterLink
        v-if="props.showLogo || showAllItems"
        class="grow-0 flex items-center justify-center"
        :class="[...props.logoContainerClasses]"
        :to="{ name: CRouteNames.home }"
      >
        <MainNavigationLogo :class="[...props.logoClasses]" />
      </RouterLink>

      <div class="grow"></div>

      <div
        class="flex shrink-0 grow-0 items-center justify-center space-x-2 xs:space-x-4 sm:space-x-6 my-auto text-center text-base-500 text-sm"
      >
        <router-link
          v-for="item in navigationItems"
          :key="item.path"
          class="font-light uppercase"
          :class="[item.cssClasses]"
          :to="{ path: item.path, hash: item.hash }"
        >
          {{ item.title }}
        </router-link>

        <router-link
          v-if="
            (currentRoute.name !== CAuthenticationRouteNames.signIn && !isLoggedIn) ||
            showAllItems
          "
          class="flex shrink-0 grow-0 justify-center font-medium rounded-xl px-4 py-1 bg-state-error text-state-error-content uppercase hover:bg-state-error-focus text-sm"
          :to="{ name: CAuthenticationRouteNames.signIn }"
        >
          <button role="link" class="uppercase">Sign In</button>
        </router-link>
      </div>

      <RouterLink
        v-if="isLoggedIn || showAllItems"
        v-slot="{ navigate }"
        :to="{ name: CRouteNames.dashboard }"
        class="flex grow-0 justify-center font-medium rounded-xl px-4 py-1 bg-state-error text-state-error-content uppercase hover:bg-state-error-focus text-sm"
      >
        <button role="link" class="uppercase" @click="navigate">Dashboard</button>
      </RouterLink>
      <div
        v-if="isLoggedIn || showAllItems"
        class="flex items-center justify-center grow-0 -ml-1 py-1 text-state-error hover:text-state-error-focus hover:underline text-sm"
      >
        <button role="link" @click="logout">
          <HeroiconLogoutOutline />
        </button>
      </div>
    </nav>
  </header>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const componentName = 'MainNavigation'
export default defineComponent({
  name: componentName,
})
</script>

<script lang="ts" setup>
import { CRouteNames } from '@/app/contracts/CRouteNames'
import { RouterLink, useRouter } from 'vue-router'
import { onMounted, ref } from 'vue'
import MainNavigationLogo from '@/app/components/MainNavigationLogo.vue'
import { useUserStore } from '@/app/services/useUserStore'
import HeroiconLogoutOutline from '@/app/components/HeroiconLogoutOutline.vue'
import type { TMainNavigationItem } from '@/app/contracts/TMainNavigationItem'
import { fetchOneEntry } from '@builder.io/sdk-vue'
import { useConfig } from '@/app/services/useConfig'
import { CBuilderModels } from '@/app/contracts/CBuilderModels'
import { CAuthenticationRouteNames } from '@/domain/Authentication'
import { useAuthentication } from '@/domain/Authentication/composables/useAuthentication'

const { currentRoute } = useRouter()

const props = withDefaults(
  defineProps<{
    showLogo: boolean
    logoContainerClasses?: string[]
    logoClasses?: string[]
  }>(),
  {
    showLogo: false,
    logoContainerClasses: () => [],
    logoClasses: () => [],
  },
)
const showAllItems = ref(false)
const { isLoggedIn, reset } = useUserStore()

const { replace } = useRouter()
const logout = async () => {
  await useAuthentication().logout({
    userCleanup: reset,
    navigate: () => replace({ name: CAuthenticationRouteNames.signIn }),
  })
}

const navigationItems = ref<TMainNavigationItem[]>([])
onMounted(async () => {
  const items: TMainNavigationItem[] =
    (
      (
        await fetchOneEntry({
          apiKey: useConfig().get().builderIO.publicApiKey,
          model: CBuilderModels.MainNavigationItems,
          query: {
            id: '502e04655ba744efbef143d078821682',
          },
        })
      )?.data ?? null
    )?.items ?? []

  navigationItems.value = items.map((item) => ({
    ...item,
    hash: item.hash === 'undefined' ? undefined : item.hash,
  }))
})
</script>

<style scoped></style>
