import { v4 as uuid } from 'uuid'
import {
  NotificationAction,
  NotificationProps,
  NotificationType,
} from '@/domain/notifications/services/useNotificationsStore'

export const createNotification = (
  message: string,
  type: NotificationType,
  action?: NotificationAction,
): NotificationProps => {
  const newNotification = {
    id: uuid(),
    message: message,
    isRead: false,
    type,
    timestamp: new Date().getTime().toString(),
  } as NotificationProps

  if (action) {
    newNotification['action'] = action
  }

  return newNotification
}
