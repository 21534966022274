import type {
  NavigationGuardNext,
  RouteLocation,
  RouteLocationNormalized,
} from 'vue-router'
import { useAuthentication } from '@/domain/Authentication/composables/useAuthentication'
import { EAuthenticationProviders } from '@/domain/Authentication/contracts/EAuthenticationProviders'
import { EMagicSignInStages } from '@/domain/Authentication/contracts/EMagicSignInStages'
import type { TSignInContext } from '@/domain/Authentication/contracts/TSignInContext'

export const signInWithMagicUriRouteGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const { mergeSignContext } = useAuthentication()
  mergeSignContext(to.query as TSignInContext)
  const magicLinkUrl = `${window.location.origin}${to.path}`

  const target = (await useAuthentication().signInWith(
    EAuthenticationProviders.MAGIC_SIGN_IN,
    {
      stage: EMagicSignInStages.REQUEST_JWT,
      secret: magicLinkUrl,
    },
  )) as RouteLocation & { href: string }

  next(target)
  return
}
