import type { TRequestError } from '@/domain/Book/contracts/TRequestError'
import type { WretchError } from 'wretch'

export const toRequestErrors = (error: WretchError): { errors: TRequestError[] } => {
  const message =
    typeof error?.json?.message === 'object' &&
    Object.keys(error?.json?.message).length > 0
      ? JSON.stringify(error?.json?.message)
      : error?.json?.message

  const resolvedError = {
    status: error.status.toString(),
    code: 'builder.io',
    title: message,
    detail: { ...error },
    source: {
      pointer: error.url,
    },
  }

  return {
    errors: [resolvedError],
  }
}
