<template>
  <FlexibleSectionLayout
    tag="section"
    :visualize-width="false"
    width="narrow"
    :class="componentName"
    :container-classes="containerClassesComputed"
    :content-container-classes="contentContainerClassesComputed"
  >
    <div
      class="flex flex-col sm:flex-row space-y-4 sm:space-y-0 justify-between items-start sm:items-center w-full pb-4 sm:grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 sm:gap-x-4 sm:gap-y-8"
    >
      <h3 :class="titleClassesComputed" v-html="props.title" />

      <MultiSelect
        v-if="enableCategorySelector"
        v-model="selectedCategoryIds"
        :options="categories"
        filter
        option-label="label"
        option-value="id"
        placeholder="Select Category"
        :max-selected-labels="3"
        class="w-full bg-base-100"
      />
    </div>

    <div
      class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-4 gap-y-8"
    >
      <DirectoryCard v-for="entry in entries" :key="entry.id" v-bind="entry" class="" />
    </div>
  </FlexibleSectionLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export const componentName = 'DirectorySection'
export default defineComponent({
  name: componentName,
})
</script>

<script lang="ts" setup>
import MultiSelect from 'primevue/multiselect'
import DirectoryCard from '@/domain/directory/components/DirectoryCard.vue'
import { useDirectoryEntries } from '@/domain/directory/composables/useDirectoryEntries'
import { useConfig } from '@/app/services/useConfig'
import { computed, onMounted, watch } from 'vue'
import { cva } from 'class-variance-authority'
import FlexibleSectionLayout from '@/domain/Content/components/FlexibleSectionLayout.vue'
import { twMerge } from 'tailwind-merge'

const props = withDefaults(
  defineProps<
    {
      title?: string
      titleClasses?: string
      selectedExpertIds?: string[]
      selectedEntries?: { Entry: { id: string } }[]
      useFakeData?: boolean
      useAllEntries?: boolean
      enableCategorySelector?: boolean
      containerClasses?: string
      contentContainerClasses?: string
    } & {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      attributes?: any
    }
  >(),
  {
    title: 'Discover Guides',
    titleClasses: undefined,
    containerClasses: undefined,
    contentContainerClasses: 'flex-col gap-6 justify-center items-start',
    useAllEntries: true,
    selectedEntries: () => [],
    selectedExpertIds: () => [],
    useFakeData: false,
    enableCategorySelector: true,
  },
)

const {
  entries,
  categories,
  selectedCategoryIds,
  selectedExpertIds,
  selectedEntriesIds,
  fetchEntries,
} = useDirectoryEntries(useConfig().get().builderIO.publicApiKey, props.useFakeData, {
  fetchCategoriesOnMount: true,
  fetchEntriesOnMount: false,
})

onMounted(async () => {
  selectedExpertIds.value = props.selectedExpertIds
  if (props.useAllEntries === false) {
    selectedEntriesIds.value = props.selectedEntries.map((entry) => entry.Entry.id)
  }
  await fetchEntries()
})

watch(
  () => props.selectedExpertIds,
  () => {
    selectedExpertIds.value = props.selectedExpertIds
  },
)

const containerClassesComputed = computed(() => cva()({ class: props.containerClasses }))
const contentContainerClassesComputed = computed(() =>
  cva()({ class: props.contentContainerClasses }),
)
const titleClassesComputed = computed(() =>
  twMerge(
    cva(
      'text-left shrink-0 lg:col-span-2 xl:col-span-3 font-extrabold text-base-content text-3xl',
    )({ class: props.titleClasses }),
  ),
)
</script>

<style scoped></style>
