import { ref, Ref } from 'vue'
import { useElementBounding, useScriptTag, watchDebounced } from '@vueuse/core'
import { EAuthenticationProviders } from '@/domain/Authentication/contracts/EAuthenticationProviders'
import { useConfig } from '@/app/services/useConfig'
import { useAuthentication } from '@/domain/Authentication/composables/useAuthentication'
import type { TGoogleSignInSecretPayload } from '@/domain/Authentication/contracts/TGoogleSignInSecretPayload'

const setupGoogleButton = (
  buttonContainer: Ref<HTMLElement | null>,
  referenceButtonWidth: Ref<number>,
) => {
  const googleClientId = useConfig().get().authentication.google.clientId

  if (!googleClientId) {
    // eslint-disable-next-line no-console
    console.error(`Google Client ID isn't specified`)
    return
  }

  if (!buttonContainer.value) {
    throw new Error('Container for google sign in button should exist')
  }

  window.google.accounts.id.initialize({
    client_id: googleClientId,
    ux_mode: 'popup',
    use_fedcm_for_prompt: true,
    callback: async (payload: TGoogleSignInSecretPayload) => {
      await useAuthentication().signInWith(EAuthenticationProviders.GOOGLE, {
        googleSignInPayload: payload,
      })
    },
  })

  window.google.accounts.id.renderButton(buttonContainer.value, {
    theme: 'outline',
    shape: 'rectangular',
    size: 'large',
    logo_alignment: 'left',
    width: referenceButtonWidth.value.toString(),
    locale: 'en-us',
  })
}

export const useGoogleSignButton = () => {
  const _scriptLoaded = ref(false)
  const _referenceButton = ref<HTMLElement | null>(null)
  const _buttonContainer = ref<HTMLElement | null>(null)
  const { width: referenceButtonWidth } = useElementBounding(_referenceButton)

  watchDebounced(
    referenceButtonWidth,
    async (newWidth) => {
      if (newWidth && _scriptLoaded.value) {
        setupGoogleButton(_buttonContainer, referenceButtonWidth)
      }
    },
    // { debounce: 500, maxWait: 1000 },
  )

  useScriptTag('https://accounts.google.com/gsi/client', () => {
    _scriptLoaded.value = true
    setupGoogleButton(_buttonContainer, referenceButtonWidth)
  })

  return {
    referenceButton: _referenceButton,
    buttonContainer: _buttonContainer,
  }
}
