import { computed, onMounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import type { YBugConfig } from '@/app/services/FrontEndConfigTypes'
import type { TActivityTrackerWithCurrentRoutePayload } from '@/domain/FeedbackWidget/contracts/TActivityTrackerWithCurrentRoutePayload'
import { fetchOneEntry } from '@builder.io/sdk-vue'
import { useConfig } from '@/app/services/useConfig'
import { CBuilderModels } from '@/app/contracts/CBuilderModels'
import type { TFeedbackWidgetRemoteConfigData } from '@/domain/FeedbackWidget/contracts/TFeedbackWidgetRemoteConfigData'
import { EFeedbackWidgetActivationSource } from '@/domain/FeedbackWidget/contracts/EFeedbackWidgetActivationSource'

const resolveIsFeedbackButtonEnabled = (
  excludedRouteNames: string[],
  subject?: string,
) => {
  if (!subject) {
    return false
  }

  return !excludedRouteNames.includes(subject)
}

const fetchRemoteConfig = async (): Promise<TFeedbackWidgetRemoteConfigData> => {
  const result = ((
    await fetchOneEntry({
      apiKey: useConfig().get().builderIO.publicApiKey,
      model: CBuilderModels.FeedbackWidget,
      query: {
        id: '7c88894ef3324ec0a6aa95d9bd63853c',
      },
    })
  )?.data ?? null) as TFeedbackWidgetRemoteConfigData

  return result
}

const _excludedRouteNames = ref<string[]>([])
const _showActivateFeedbackWidgetInFooter = ref(false)
const _isInitialized = ref(false)
const _isFeedbackButtonEnabled = ref(false)
const _config = ref<YBugConfig>()
const _trackActivity: { execute: TActivityTrackerWithCurrentRoutePayload | undefined } = {
  execute: undefined,
}
export const useFeedbackWidget = () => {
  const { currentRoute } = useRouter()

  const activateFeedbackWidget = (
    activationSource: EFeedbackWidgetActivationSource = EFeedbackWidgetActivationSource.unknown,
  ) => {
    if (!window.Ybug) {
      /* eslint-disable no-console */
      console.error('yBug not initialized')
      return
    }

    if (_trackActivity.execute) {
      _trackActivity.execute(currentRoute, activationSource)
    }

    window.Ybug.open()
  }

  onMounted(() => {
    if (!isFeedbackWidgetAvailable.value) {
      return
    }
    _isFeedbackButtonEnabled.value = resolveIsFeedbackButtonEnabled(
      _excludedRouteNames.value,
      currentRoute.value?.name as string,
    )
  })

  // also watch for changed _excludedRouteNames
  watch([currentRoute, _excludedRouteNames], ([newRouteNameValue]) => {
    if (!isFeedbackWidgetAvailable.value) {
      return false
    }

    _isFeedbackButtonEnabled.value = resolveIsFeedbackButtonEnabled(
      _excludedRouteNames.value,
      newRouteNameValue?.name as string,
    )
  })

  const isFeedbackWidgetAvailable = computed(() => {
    if (!_isInitialized.value) {
      return false
    }

    if (!_config.value) {
      return false
    }

    return Boolean(_config.value.isEnabled && _config.value.key)
  })

  return {
    init: async (
      config: YBugConfig,
      trackActivity?: TActivityTrackerWithCurrentRoutePayload,
    ) => {
      _config.value = config

      if (trackActivity) {
        _trackActivity.execute = trackActivity
      }

      const remoteConfig = await fetchRemoteConfig()
      _excludedRouteNames.value = (remoteConfig?.excludedRouteNames ?? []).map(
        (item) => item.routeName,
      )
      _showActivateFeedbackWidgetInFooter.value =
        remoteConfig?.showActivateFeedbackWidgetInFooter ?? false

      _isInitialized.value = true
    },
    isFeedbackButtonEnabled: computed(() => _isFeedbackButtonEnabled.value),
    activateFeedbackWidget,
    showActivateFeedbackWidgetInFooter: computed(
      () => isFeedbackWidgetAvailable.value && _showActivateFeedbackWidgetInFooter.value,
    ),
  }
}
