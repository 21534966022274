import { ESignInContentTypes } from '@/domain/Authentication/contracts/ESignInContentTypes'
import type { TSignInContent } from '@/domain/Authentication/contracts/TSignInContent'

export const staticSignInContents: Record<string, TSignInContent> = {
  [ESignInContentTypes.default]: {
    type: ESignInContentTypes.default,
    title: `Welcome to Miljn!`,
    signInTitle: `Create and Access Guides!`,
    showPreview: false,
    items: [
      {
        icon: `WandMagicSparklesOutlineIcon`,
        description: `Instantly create AI-powered guides for free`,
      },
      {
        icon: `EditOutlineIcon`,
        description: `Personalize, track progress and edit as you go`,
      },
      {
        icon: `UsersGroupOutlineIcon`,
        description: `Discover expert guides from the Miljn Community`,
      },
    ],
  },
  [ESignInContentTypes.case]: {
    type: ESignInContentTypes.case,
    title: undefined,
    signInTitle: `Get the Guide Now!`,
    showPreview: true,
    items: [
      {
        icon: `WandMagicSparklesOutlineIcon`,
        description: `Instantly access the expert guide for free`,
      },
      {
        icon: `RocketOutlineIcon`,
        description: `Get expert tips, insights and actionable steps`,
      },
      {
        icon: `EditOutlineIcon`,
        description: `Personalize, track progress and edit as you go`,
      },
    ],
  },
  [ESignInContentTypes.caseFromDirectory]: {
    type: ESignInContentTypes.caseFromDirectory,
    title: undefined,
    signInTitle: `Get the Expert Guide!`,
    showPreview: true,
    items: [
      {
        icon: `WandMagicSparklesOutlineIcon`,
        description: `Instantly access the expert guide for free`,
      },
      {
        icon: `RocketOutlineIcon`,
        description: `Get expert tips, insights and actionable steps`,
      },
      {
        icon: `EditOutlineIcon`,
        description: `Personalize, track progress and edit as you go`,
      },
    ],
  },
  [ESignInContentTypes.promptFromDirectory]: {
    type: ESignInContentTypes.promptFromDirectory,
    title: undefined,
    signInTitle: `Get the Guide Now!`,
    showPreview: true,
    items: [
      {
        icon: `WandMagicSparklesOutlineIcon`,
        description: `Instantly access your AI-powered guide for free`,
      },
      {
        icon: `EditOutlineIcon`,
        description: `Personalize, track progress and edit as you go`,
      },
      {
        icon: `UsersGroupOutlineIcon`,
        description: `Discover expert guides from the Miljn Community`,
      },
    ],
  },
  [ESignInContentTypes.prompt]: {
    type: ESignInContentTypes.prompt,
    title: undefined,
    signInTitle: `Get the Guide Now!`,
    showPreview: true,
    items: [
      {
        icon: `WandMagicSparklesOutlineIcon`,
        description: `Instantly access your AI-powered guide for free`,
      },
      {
        icon: `EditOutlineIcon`,
        description: `Personalize, track progress and edit as you go`,
      },
      {
        icon: `UsersGroupOutlineIcon`,
        description: `Discover expert guides from the Miljn Community`,
      },
    ],
  },
}
