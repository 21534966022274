<template>
  <img
    v-if="!isEmpty(props?.image)"
    :class="avatarClassesComputed"
    :src="props.image"
    :alt="`Avatar image of ${props.name}`"
  />

  <span
    v-if="isEmpty(props?.image) && !isEmpty(initials)"
    :class="avatarClassesComputed"
    v-html="initials"
  />
</template>

<script lang="ts">
export const componentName = 'ProfileAvatarWithFallback'
</script>

<script lang="ts" setup>
import { computed, defineOptions, type HTMLAttributes } from 'vue'
import { isEmpty } from 'lodash-es'
import { getInitials } from '@/domain/users/support/getInitials'
import { cva } from 'class-variance-authority'
import { twMerge } from 'tailwind-merge'

defineOptions({
  name: componentName,
})

const avatarClasses = cva(['flex', 'w-10', 'h-10', 'rounded-full'], {
  variants: {
    image: {
      true: ['object-contain', 'object-center'],
      false: ['shrink-0', 'text-lg', 'font-medium', 'items-center', 'justify-center'],
    },
  },
})

const avatarClassesComputed = computed(() =>
  twMerge(
    avatarClasses({
      image: !isEmpty(props?.image),
      class: props.class,
    }),
  ),
)

const props = withDefaults(
  defineProps<{
    class?: HTMLAttributes['class']
    name?: string
    image?: string
  }>(),
  {
    class: undefined,
    name: undefined,
    image: undefined,
  },
)

const initials = computed(() => {
  if (!props?.name) {
    return undefined
  }

  return getInitials(props.name)
})
</script>

<style scoped></style>
