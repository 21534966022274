import type { RegisteredComponent } from '@builder.io/sdk-vue'
import component from '@/domain/Content/components/ActionableTextHero.vue'

export const ActionableTextHeroComponentRegistration = (
  shouldWrap = true,
): RegisteredComponent => ({
  component: component,
  name: 'ActionableTextHero',
  friendlyName: 'Actionable TextHero',
  noWrap: !shouldWrap,
  canHaveChildren: true,
  childRequirements: {
    message: `Component supports only CallToActionButton as child component`,
    component: 'CallToActionButton',
  },
  inputs: [
    {
      name: 'tag',
      type: 'string',
      helperText: `The Html tag that should be used to encapsulate the component, defaults to 'div'`,
      defaultValue: 'div',
      enum: ['div', 'section'],
    },
    {
      friendlyName: 'Container Classes',
      name: 'containerClasses',
      type: 'string',
    },
    {
      friendlyName: 'Merge Container Classes with defaults',
      name: 'mergeContainerClasses',
      type: 'boolean',
      defaultValue: true,
    },
    {
      friendlyName: 'Title',
      name: 'title',
      type: 'longText',
    },
    {
      friendlyName: 'Title Tag',
      name: 'titleTag',
      type: 'string',
      helperText: `The Html tag that should be used for the title, defaults to 'h1'. Hint: Because Tailwind CSS there is no specific styling applied for the title tag use title classes for these.`,
      defaultValue: 'h1',
      enum: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
    },
    {
      friendlyName: 'Title Classes',
      name: 'titleClasses',
      type: 'string',
    },
    {
      friendlyName: 'Merge Title Classes with defaults',
      name: 'mergeTitleClasses',
      type: 'boolean',
      defaultValue: true,
    },
    {
      friendlyName: 'Subline',
      name: 'subline',
      type: 'longText',
    },
    {
      friendlyName: 'Subline Classes',
      name: 'sublineClasses',
      type: 'string',
    },
    {
      friendlyName: 'Merge Subline Classes with defaults',
      name: 'mergeSublineClasses',
      type: 'boolean',
      defaultValue: true,
    },
  ],
})
