import type { RegisteredComponent } from '@builder.io/sdk-vue'
import component from '@/domain/Authentication/components/SignInWithEmailForm.vue'
import { ESignInWithMagicUriFormUsageContext } from '@/domain/Authentication/contracts/ESignInWithMagicUriFormUsageContext'
import { toKeyValue } from '@/domain/Content/support/toKeyValue'

export const SignInWithMagicUriFormComponentRegistration = (
  shouldWrap = true,
): RegisteredComponent => ({
  hideFromInsertMenu: true,
  component: component,
  friendlyName: 'v2: Email SignIn CTA',
  name: 'SignInWithMagicUriForm',
  noWrap: !shouldWrap,
  canHaveChildren: false,
  inputs: [
    {
      friendlyName: 'Usage Context',
      name: 'usageContext',
      type: 'string',
      enum: toKeyValue(ESignInWithMagicUriFormUsageContext),
      defaultValue: ESignInWithMagicUriFormUsageContext.STAND_ALONE,
    },
    {
      friendlyName: 'Placeholder',
      name: 'inputPlaceholder',
      type: 'string',
      defaultValue: 'Email: you@example.com',
    },
    {
      friendlyName: 'Action Title',
      name: 'actionTitle',
      type: 'string',
      defaultValue: 'Sign in with email',
    },
    {
      friendlyName: 'Action Target',
      name: 'actionTarget',
      type: 'string',
    },
  ],
})
