import { type RegisteredComponent } from '@builder.io/sdk-vue'
import component from '@/domain/Content/components/PricingTableCard.vue'

export const PricingTableCardRegistration = (shouldWrap = true): RegisteredComponent => ({
  component: component,
  name: 'PricingTableCard',
  noWrap: !shouldWrap,
  canHaveChildren: false,
  inputs: [
    {
      name: 'title',
      type: 'longText',
      required: true,
    },
    {
      name: 'shortDescription',
      type: 'longText',
    },
    {
      name: 'pricing',
      type: 'text',
    },
    {
      name: 'pricingInterval',
      type: 'text',
    },
    {
      name: 'benefits',
      type: 'list',
      defaultValue: [],
      subFields: [
        {
          name: 'text',
          type: 'longText',
        },
      ],
    },
    {
      name: 'actionTitle',
      type: 'text',
    },
    {
      name: 'actionTarget',
      type: 'text',
    },
    {
      name: 'actionPriority',
      type: 'text',
      defaultValue: 'secondary',
      enum: ['primary', 'secondary'],
    },
    {
      name: 'posthogEvent',
      type: 'text',
    },
  ],
})
