<template>
  <div v-if="props.source" :class="container({ class: props.containerClasses })">
    <img
      v-if="props.type === EMediaType.image"
      :class="item({ type: EMediaType.image, class: props.itemClasses })"
      :src="props.source"
      :alt="props.alt"
      @click="trackClick"
    />

    <IFrameBasedVideoPlayer
      v-if="props.type === EMediaType.video"
      :iframe-classes="item({ type: EMediaType.video, class: props.itemClasses })"
      :url="props.source"
    />
  </div>
</template>

<script lang="ts">
export const componentName = 'ImageOrVideo'
</script>

<script lang="ts" setup>
import { defineOptions } from 'vue'
import { EMediaType } from '@/domain/Content/contracts/EMediaType'
import IFrameBasedVideoPlayer from '@/domain/Content/components/IFrameBasedVideoPlayer.vue'
import { cva } from 'class-variance-authority'
import { captureWithUser } from '@/app/support/usePosthog'
import { EUserEvents } from '@/app/contracts/EUserEvents'
import { useRouter } from 'vue-router'

defineOptions({
  name: componentName,
})

const container = cva(['w-full', componentName])
const item = cva(['w-full'], {
  variants: {
    type: {
      [EMediaType.image]: 'object-contain object-center',
      [EMediaType.video]: 'aspect-16-9',
    },
  },
})

const props = withDefaults(
  defineProps<{
    type: EMediaType
    source?: string
    alt?: string
    containerClasses?: string
    itemClasses?: string
  }>(),
  {
    source: undefined,
    class: undefined,
    alt: undefined,
    containerClasses: undefined,
    itemClasses: undefined,
  },
)

const { currentRoute } = useRouter()

const trackClick = () => {
  captureWithUser(EUserEvents.clicked, {
    route: (currentRoute.value?.name as string).toLowerCase(),
    route_query: { ...currentRoute.value.query },
    route_params: { ...currentRoute.value.params },
    metadata: {
      mediaType: props.type,
      source: props.source,
      alt: props.alt,
    },
  })
}
</script>

<style scoped></style>
