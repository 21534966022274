import type { RegisteredComponent } from '@builder.io/sdk-vue'
import component from '@/domain/Content/components/CaseMethodologySection.vue'
import { TCaseMethodologyStepIcons } from '@/domain/Content/contracts/TCaseMethodologyStepIcons'
import { toKeyValue } from '@/domain/Content/support/toKeyValue'
import { CBookModel } from '@/domain/Book/contracts/CBookModel'
import { componentName as SignInWithEmailFormComponentName } from '@/domain/Authentication/components/SignInWithEmailForm.vue'

const supportedCtaList = [SignInWithEmailFormComponentName]

export const CaseMethodologySectionComponentRegistration = (
  shouldWrap = true,
): RegisteredComponent => ({
  component: component,
  name: 'CaseMethodologySection',
  friendlyName: 'CaseMethodologySection',
  noWrap: !shouldWrap,
  canHaveChildren: true,
  childRequirements: {
    message: `Use ${supportedCtaList.join(', ')} components`,
    query: {
      'component.name': { $in: supportedCtaList },
    },
  },
  inputs: [
    {
      friendlyName: 'Use page content',
      name: 'usePageContent',
      type: 'boolean',
      helperText:
        'Derive content from enclosing page instead of manually adding. 🚨 When **enabled** `CtaBox.cta.target` is overridden.',
      defaultValue: false,
    },
    {
      friendlyName: 'Define page content model',
      name: 'pageContentModel',
      type: 'string',
      enum: ['page', CBookModel.NAME],
      defaultValue: CBookModel.NAME,
      showIf(options) {
        return options.get('usePageContent') === true
      },
    },
    {
      name: 'title',
      type: 'longText',
    },
    {
      name: 'subline',
      type: 'longText',
    },
    {
      friendlyName: 'Container Classes',
      name: 'containerClasses',
      defaultValue: 'mt-12 mb-6',
      type: 'string',
    },
    {
      name: 'ctaBox',
      type: 'object',
      subFields: [
        {
          name: 'title',
          type: 'longText',
        },
        {
          name: 'description',
          type: 'longText',
        },
        {
          name: 'cta',
          type: 'object',
          subFields: [
            {
              name: 'title',
              type: 'longText',
            },
            {
              name: 'target',
              type: 'string',
            },
          ],
        },
      ],
    },
    {
      friendlyName: 'Steps',
      name: 'steps',
      type: 'list',
      defaultValue: [],
      subFields: [
        {
          name: 'icon',
          type: 'string',
          enum: toKeyValue(TCaseMethodologyStepIcons),
        },
        {
          name: 'title',
          type: 'longText',
        },
        {
          name: 'description',
          type: 'longText',
        },
      ],
    },
  ],
})
